import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import {
  Box,
  Button,
  HStack,
  IconButton,
  VStack,
  Tag,
  Text,
  Input,
  Flex,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  ModalFooter,
  useToast,
  Heading,
  Modal,
  useColorModeValue,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  Tooltip,
} from "@chakra-ui/react";
import { CreatableSelect, components } from "chakra-react-select";
import { StrictModeDroppable } from "../../5 - General/Utils/UtilsStrictModeDroppable";
import { FaTrash, FaTimes } from "react-icons/fa";
import { useBlueprintCreate } from "../../3 - Modal/3 - Blueprints Modals/BlueprintCreateContext";

const BlueprintStepTwo = () => {
  const { wizardData, updateWizardData } = useBlueprintCreate();
  const [dishComponents, setDishComponents] = useState(wizardData.stepTwo?.dishComponents || []);
  const [selectedDishComponent, setSelectedDishComponent] = useState(null);
  const [dishComponentOptions, setDishComponentOptions] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [componentToDelete, setComponentToDelete] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedDishComponents, setSelectedDishComponents] = useState([]);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const cancelRef = React.useRef();
  const toast = useToast();

  const bg = useColorModeValue("gray.50", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const inputBg = useColorModeValue("white", "gray.700");
  const buttonColorScheme = useColorModeValue("primary", "primary");
  const tagBg = useColorModeValue("primary.100", "secondary.800");

  useEffect(() => {
    updateWizardData({ stepTwo: { dishComponents } });
  }, [dishComponents, updateWizardData]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchDishComponents = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_ENDPOINT;
        const response = await fetch(`${apiUrl}/api/v1/blueprint/dish-component`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`Error fetching dish components: ${response.statusText}`);
        }
        const data = await response.json();
        const fetchedOptions = data.map((component) => ({
          value: component._id,
          label: component.name,
        }));
        setDishComponentOptions(fetchedOptions);
      } catch (error) {
        console.error("Error fetching dish components:", error);
      }
    };

    fetchDishComponents();
  }, [token]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_ENDPOINT;
        const response = await fetch(`${apiUrl}/api/v1/blueprint/get-all-dish-component-templates`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch templates");
        }
        const templates = await response.json();
        const options = templates.map((template) => ({
          value: template._id,
          label: template.name,
          dishComponents: template.dishComponents.map((dc) => ({
            _id: dc._id,
            name: dc.name,
          })),
        }));
        setTemplateOptions(options);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };

    fetchTemplates();
  }, [token]);

  const handleTemplateSelect = (selectedOption) => {
    setSelectedTemplate(selectedOption);
    if (!selectedOption) return;

    // Set dishComponents state based on the selected template
    const templateDishComponents = selectedOption.dishComponents.map((dc) => ({
      id: dc._id,
      name: dc.name,
      allowedWeight: null,
      maxFoods: null,
    }));

    setDishComponents(templateDishComponents);
  };

  const handleTemplateCreated = (newTemplate) => {
    // Add the new template to templateOptions
    const newOption = {
      value: newTemplate._id,
      label: newTemplate.name,
      dishComponents: newTemplate.dishComponents.map((dc) => ({
        _id: dc._id,
        name: dc.name,
      })),
    };
    setTemplateOptions((prevOptions) => [...prevOptions, newOption]);
  };

  const handleCreateTemplate = async () => {
    if (!templateName || selectedDishComponents.length === 0) {
      // Handle validation
      toast({
        title: "Erreur",
        description: "Veuillez entrer un nom et sélectionner des composantes.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_ENDPOINT;
      const response = await fetch(`${apiUrl}/api/v1/blueprint/dish-component-template`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: templateName,
          dishComponents: selectedDishComponents.map((dc) => dc.value),
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create template");
      }

      const newTemplate = await response.json();
      handleTemplateCreated(newTemplate);
      setTemplateName("");
      setSelectedDishComponents([]);
      setIsTemplateModalOpen(false);
      toast({
        title: "Modèle créé",
        description: "Le modèle a été créé avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error creating template:", error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la création du modèle.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Filter out already selected components from the options
  const availableDishComponentOptions = dishComponentOptions.filter((option) => !dishComponents.some((component) => component.id === option.value));

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;

    const reordered = Array.from(dishComponents);
    const [removed] = reordered.splice(source.index, 1);
    reordered.splice(destination.index, 0, removed);

    setDishComponents(reordered);
  };

  const handleAddDishComponent = async (newDishComponent) => {
    if (!newDishComponent) return;

    let selectedDishComponentId = newDishComponent.value;

    if (!newDishComponent.__isNew__) {
      const newDC = {
        id: selectedDishComponentId,
        name: newDishComponent.label,
        isWeighted: true,
        allowedWeight: null,
        maxFoods: null,
      };
      setDishComponents([...dishComponents, newDC]);
    } else {
      try {
        const apiUrl = process.env.REACT_APP_API_ENDPOINT;
        const response = await fetch(`${apiUrl}/api/v1/blueprint/dish-component`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: newDishComponent.label }),
        });
        if (!response.ok) {
          throw new Error(`Error creating dish component: ${response.statusText}`);
        }
        const newComponent = await response.json();

        const newDC = {
          id: newComponent._id,
          name: newComponent.name,
          isWeighted: true,
          allowedWeight: null,
          maxFoods: null,
        };
        setDishComponents([...dishComponents, newDC]);

        setDishComponentOptions([
          ...dishComponentOptions,
          {
            value: newComponent._id,
            label: newComponent.name,
          },
        ]);
      } catch (error) {
        console.error("Error creating new dish component:", error);
      }
    }

    setSelectedDishComponent(null);
  };

  const handleRemoveDishComponent = (id) => {
    setDishComponents(dishComponents.filter((dc) => dc.id !== id));
  };

  const handleWeightChange = (id, weight) => {
    const updatedComponents = dishComponents.map((dc) => (dc.id === id ? { ...dc, allowedWeight: weight } : dc));
    setDishComponents(updatedComponents);
  };

  const handleMaxFoodsChange = (id, maxFoods) => {
    const updatedComponents = dishComponents.map((dc) => (dc.id === id ? { ...dc, maxFoods: maxFoods } : dc));
    setDishComponents(updatedComponents);
  };

  const handleIsWeightedChange = (id, isWeighted) => {
    const updatedComponents = dishComponents.map((dc) => (dc.id === id ? { ...dc, isWeighted: isWeighted } : dc));
    setDishComponents(updatedComponents);
  };


  const calculateRemainingWeight = () => {
    const totalWeight = dishComponents.reduce((sum, component) => sum + (component.allowedWeight || 0), 0);
    return Math.max(0, 100 - totalWeight);
  };

  const remainingWeight = calculateRemainingWeight();

  useEffect(() => {
    if (dishComponents.length > 1) {
      const totalWeightExcludingLast = dishComponents.slice(0, -1).reduce((sum, component) => sum + (component.allowedWeight || 0), 0);
      const newAllowedWeightForLast = Math.max(0, 100 - totalWeightExcludingLast);
      const lastComponent = dishComponents[dishComponents.length - 1];

      if (lastComponent.allowedWeight !== newAllowedWeightForLast) {
        const updatedComponents = [...dishComponents];
        updatedComponents[updatedComponents.length - 1] = {
          ...lastComponent,
          allowedWeight: newAllowedWeightForLast,
        };
        setDishComponents(updatedComponents);
      }
    }
  }, [dishComponents]);

  const handleDeleteOption = async () => {
    if (!componentToDelete) return;

    try {
      const apiUrl = process.env.REACT_APP_API_ENDPOINT;
      const response = await fetch(`${apiUrl}/api/v1/blueprint/dish-component/${componentToDelete}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to delete dish component");
      }

      setDishComponentOptions(dishComponentOptions.filter((opt) => opt.value !== componentToDelete));
      setDishComponents(dishComponents.filter((dc) => dc.id !== componentToDelete));
      setIsAlertOpen(false);
      toast({
        title: "Composante supprimée",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting dish component:", error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la suppression de la composante.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const openDeleteAlert = (id) => {
    setComponentToDelete(id);
    setIsAlertOpen(true);
  };

  const OptionWithDelete = (props) => {
    return (
      <components.Option {...props}>
        <HStack justifyContent="space-between">
          <Text>{props.data.label}</Text>
          <IconButton
            icon={<FaTimes />}
            size="xs"
            variant="ghost"
            aria-label="Delete Option"
            onClick={(e) => {
              e.stopPropagation();
              openDeleteAlert(props.data.value);
            }}
          />
        </HStack>
      </components.Option>
    );
  };

  return (
    <Box p={4} maxW="600px" margin="auto" bg={bg} borderRadius="md" borderWidth="1px" borderColor={borderColor}>
      <Text fontSize="2xl" fontWeight="bold" mb={4}>
        Étape 2: Choisir les composantes de base du blueprint
      </Text>

      {/* Template Selection and Create Template Button */}
      <HStack mb={4}>
        <CreatableSelect
          placeholder="Sélectionner un modèle"
          options={templateOptions}
          value={selectedTemplate}
          onChange={handleTemplateSelect}
          isClearable
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
        />
        <Button onClick={() => setIsTemplateModalOpen(true)} colorScheme={buttonColorScheme}>
          Créer un modèle
        </Button>
      </HStack>

      {/* New Dish Component Input with Autocomplete */}
      <HStack mb={4}>
        <CreatableSelect
          placeholder="Ajouter une nouvelle composante"
          options={availableDishComponentOptions}
          value={selectedDishComponent}
          onChange={(option) => setSelectedDishComponent(option)}
          isClearable
          components={{ Option: OptionWithDelete }}
          onCreateOption={(newDishComponentLabel) =>
            handleAddDishComponent({
              label: newDishComponentLabel,
              __isNew__: true,
            })
          }
        />
        <Button onClick={() => handleAddDishComponent(selectedDishComponent)} colorScheme={buttonColorScheme} disabled={!selectedDishComponent}>
          Ajouter la composante
        </Button>
      </HStack>

      {/* Dish Components List with Drag and Drop */}
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="dishComponents-droppable">
          {(provided) => (
            <VStack {...provided.droppableProps} ref={provided.innerRef} spacing={3} align="stretch">
              {dishComponents.map((dishComponent, index) => (
                <Draggable key={dishComponent.id} draggableId={dishComponent.id} index={index}>
                  {(provided) => (
                    <HStack
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      bg={inputBg}
                      p={2}
                      borderRadius="md"
                      justifyContent="space-between"
                    >
                      <Tag size="lg" bg={tagBg} color="white" flex="1">
                        {dishComponent.name}
                      </Tag>
                      <Flex align="center">
                        <Input
                          placeholder="Poids (%)"
                          type="number"
                          min={0}
                          max={remainingWeight}
                          value={dishComponent.allowedWeight !== null ? dishComponent.allowedWeight : ""}
                          onChange={(e) => handleWeightChange(dishComponent.id, Number(e.target.value))}
                        />
                        <Input
                          placeholder="Nb d'options"
                          type="number"
                          min={1}
                          value={dishComponent.maxFoods !== null ? dishComponent.maxFoods : ""}
                          onChange={(e) => handleMaxFoodsChange(dishComponent.id, Number(e.target.value))}
                          ml={2}
                          maxWidth="200px"
                        />
                        <Tooltip label="Décocher si compté par unités et non par poids lors de la création de recette, mettre une valeur aux autre champs quand même." aria-label="Weighted Component Tooltip">
                        <Checkbox
                          isChecked={dishComponent.isWeighted}
                          onChange={(e) => handleIsWeightedChange(dishComponent.id, e.target.checked)}
                          colorScheme={buttonColorScheme}
                          size="sm"
                          ml={2}
                        />
                        </Tooltip>
                        <IconButton
                          icon={<FaTrash />}
                          onClick={() => handleRemoveDishComponent(dishComponent.id)}
                          colorScheme="red"
                          variant="outline"
                          size="sm"
                          aria-label="Remove Dish Component"
                          ml={2}
                        />
                      </Flex>
                    </HStack>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </VStack>
          )}
        </StrictModeDroppable>
      </DragDropContext>

      {/* Modal for Creating Template */}
      <Modal isOpen={isTemplateModalOpen} onClose={() => setIsTemplateModalOpen(false)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="left">
            <Heading fontSize="22px" fontWeight="500" mb={2}>
              Créer un modèle de composantes
            </Heading>
            <Text fontSize="md" fontWeight="500" textAlign="left">
              Créez un modèle de composantes pour réutiliser des combinaisons de composantes dans vos blueprints.
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={6} alignItems="flex-start" width="100%">
              <Input
                placeholder="Nom du modèle"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                width="100%" // Ensures the input takes full width
              />
              <CreatableSelect
                isMulti
                placeholder="Sélectionner les composantes du modèle"
                options={dishComponentOptions}
                value={selectedDishComponents}
                onChange={(components) => setSelectedDishComponents(components)}
                closeMenuOnSelect={false} // Prevents closing the dropdown on select
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCreateTemplate} colorScheme="primary" alignSelf="flex-start">
              Enregistrer le modèle
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Alert Dialog for Deletion Confirmation */}
      <AlertDialog isOpen={isAlertOpen} leastDestructiveRef={cancelRef} onClose={() => setIsAlertOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Confirmer la suppression</AlertDialogHeader>
            <AlertDialogBody>Voulez-vous vraiment supprimer cette composante ?</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={handleDeleteOption} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default BlueprintStepTwo;
