import React from "react";
import { Box, Text, Heading, VStack, HStack, Image, Divider, Tag, Wrap, Card, CardHeader, CardBody, UnorderedList, ListItem } from "@chakra-ui/react";
import { useBlueprintCreate } from "../../3 - Modal/3 - Blueprints Modals/BlueprintCreateContext";

const BlueprintStepFive = () => {
  const { wizardData } = useBlueprintCreate();

  const { stepOne, stepTwo, stepThree, stepFour } = wizardData;

  return (
    <Box p={4} maxW="900px" margin="auto">
      <Heading as="h2" size="xl" mb={6}>
        Étape 5: Résumé du Blueprint
      </Heading>
      <Box mb={8}>
        <Heading as="h3" size="lg" mb={4}>
          Informations Générales
        </Heading>
        <Divider mb={8} />
        <HStack justify="space-between" align="flex-start">
          <VStack align="start" spacing={4}>
            <HStack>
              <Text fontWeight="bold">Nom du plat:</Text>
              <Text>{stepOne?.name}</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold">Description:</Text>
              <Text>{stepOne?.description}</Text>
            </HStack>
            <HStack>
              <Tag colorScheme="gray">Poids autorisé: {stepOne?.totalAllowedWeight} g</Tag>
            </HStack>
            <VStack alignItems="flex-start">
              <Text fontWeight="bold">Types de cuisine:</Text>
              <Wrap>
                {stepOne?.cuisineType?.length > 0 ? (
                  stepOne.cuisineType.map((type) => (
                    <Tag key={type.value} colorScheme="blue">
                      {type.label}
                    </Tag>
                  ))
                ) : (
                  <Tag colorScheme="red">Aucun type de cuisine</Tag>
                )}
              </Wrap>
            </VStack>
          </VStack>
          {stepOne?.imagePreview && (
            <Box>
              <Image src={stepOne.imagePreview} alt="Image du plat" maxW="400px" borderRadius="md" border="1px solid #ccc" />
            </Box>
          )}
        </HStack>
      </Box>

      {/* Combined Steps 2 to 4 Summary - Components in Cards */}
      <Box mb={8}>
        <Heading as="h3" size="lg" mb={4}>
          Détails des Composantes du Plat
        </Heading>
        <Divider mb={8} />
        <VStack align="stretch" spacing={6}>
          {stepTwo?.dishComponents?.map((component, index) => {
            const componentId = component.id.toString();

            const selectedCategories = stepThree?.selectedCategories?.[componentId] || [];
            const selectedSubcategories = stepThree?.selectedSubcategories?.[componentId] || [];

            const subcategoryAliments = selectedSubcategories.map((subcategory) => {
              const subcategoryId = subcategory.value;
              const mode = stepFour?.[componentId]?.accordionModes?.[subcategoryId] || "add";
              const selectedAliments = stepFour?.[componentId]?.selectedAliments?.[subcategoryId] || [];

              // Separate aliments into basic and advanced
              const basicAliments = selectedAliments.filter((aliment) => aliment.level !== "advanced");
              const advancedAliments = selectedAliments.filter((aliment) => aliment.level === "advanced");

              return {
                subcategory,
                mode,
                basicAliments,
                advancedAliments,
              };
            });

            return (
              <Card key={component.id} borderWidth="1px" borderRadius="md">
                <CardHeader>
                  <HStack justify="space-between">
                    <Heading size="md">
                      {index + 1}. {component.name}
                    </Heading>
                    <HStack>
                      <Tag colorScheme="gray">Max: {component.maxFoods} aliments</Tag>
                      <Tag colorScheme="gray">Poids: {component.allowedWeight}%</Tag>
                    </HStack>
                  </HStack>
                  <Divider mt={4} />
                </CardHeader>
                <CardBody>
                  {/* Categories */}
                  <Box mb={4}>
                    <Text fontWeight="bold" mb={2}>
                      Catégories:
                    </Text>
                    <Wrap>
                      {selectedCategories.map((category) => (
                        <Tag key={category.value} colorScheme="green">
                          {category.label}
                        </Tag>
                      ))}
                    </Wrap>
                  </Box>

                  {/* Subcategories and Aliments */}
                  {subcategoryAliments.map((item) => (
                    <Box key={item.subcategory.value} mb={8}>
                      <Text fontWeight="bold" fontSize="xl" mb={2}>
                        Sous-catégorie: {item.subcategory.label}
                      </Text>
                      {/* Basic Aliments */}
                      {item.basicAliments.length > 0 && (
                        <>
                          <Text fontWeight="bold" mb={1}>
                            Aliments de base:
                          </Text>
                          <Wrap mb={2}>
                            {item.basicAliments.map((aliment) => (
                              <Tag key={aliment.value} colorScheme="teal">
                                {aliment.label}
                              </Tag>
                            ))}
                          </Wrap>
                        </>
                      )}
                      {/* Advanced Aliments */}
                      {item.advancedAliments.length > 0 && (
                        <>
                          <Text fontWeight="bold" mb={1}>
                            Aliments avancés:
                          </Text>
                          <Wrap>
                            {item.advancedAliments.map((aliment) => (
                              <Tag key={aliment.value} colorScheme="purple">
                                {aliment.label}
                              </Tag>
                            ))}
                          </Wrap>
                        </>
                      )}
                    </Box>
                  ))}
                </CardBody>
              </Card>
            );
          })}
        </VStack>
      </Box>

      {/* Final Instructions or Notes */}
      <Box mb={8}>
        <Text fontSize="lg" fontWeight="600">
          Veuillez vérifier les informations ci-dessus avant de publier le blueprint.
        </Text>
        <Text fontSize="md" fontWeight="400">
          Sauvegarder le blueprint le publiera automatiquement à tous les utilisateurs.
        </Text>
        <Text fontSize="md" fontWeight="400">
          Assurez-vous qu'il n'y a pas d'erreurs ou de fautes de frappe.
        </Text>
        <Text fontSize="md" fontWeight="100">
          {" "}
          -{" "}
        </Text>
        <Text fontSize="lg" fontWeight="600">
          Liste avant la publication :
        </Text>
        <UnorderedList>
          <ListItem fontSize="md" fontWeight="400">
            Toutes les informations sont correctes.
          </ListItem>
          <ListItem fontSize="md" fontWeight="400">
            Absence d'erreurs et de fautes de frappe.
          </ListItem>
          <ListItem fontSize="md" fontWeight="400">
            Le blueprint est prêt pour la publication à tous les utilisateurs.
          </ListItem>
        </UnorderedList>
      </Box>
    </Box>
  );
};

export default BlueprintStepFive;
