// CommunityRecipeGridItem.js
import React, { useRef } from "react";
import { motion } from "framer-motion";
import {
    Box,
    Image,
    Text,
    IconButton,
    Button,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    GridItem,
    AlertDialogFooter,
    useColorModeValue,
    Tag,
    HStack,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { GoArrowRight } from "react-icons/go";
import useCustomToast from "../5 - General/Utils/UtilsNotification";
import Placeholder from "../../img/Placeholder1.jpg";

const CommunityRecipeGridItem = ({ recipe, isSelected, onClick, onClose, isAdmin, onDelete }) => {
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const imageUrl = recipe.recipePicture ? `${apiUrl}/${recipe.recipePicture}` : Placeholder;
    const { isOpen: isDeleteDialogOpen, onOpen: onDeleteDialogOpen, onClose: onDeleteDialogClose } = useDisclosure();
    const cancelRef = useRef();
    const customToast = useCustomToast();

    // Define ColorMode values
    const bgColor = useColorModeValue("white", "gray.800");
    const borderColor = useColorModeValue("gray.200", "gray.700");
    const textColor = useColorModeValue("gray.800", "white");
    const overlayBgColor = "rgba(0, 0, 0, 0.6)";
    const buttonColorScheme = useColorModeValue("primary", "secondary");
    const borderSelectedColor = useColorModeValue("primary.700", "primary.400");

    const handleDeleteRecipe = async () => {
        try {
            await fetch(`${apiUrl}/api/v1/recipe/recipe/${recipe._id}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                },
            });
            customToast({
                status: "success",
                title: "Succès",
                description: "La recette a été supprimée avec succès.",
            });
            onDeleteDialogClose();
            onClose(); // Close the expanded view
            if (onDelete) {
                onDelete(recipe._id); // Remove the recipe from the list
            }
        } catch (error) {
            console.error("Erreur lors de la suppression de la recette:", error);
            customToast({
                status: "error",
                title: "Erreur",
                description: "Une erreur est survenue. Veuillez réessayer plus tard.",
            });
        }
    };

    return (
        <GridItem
            as={motion.div}
            layout
            onClick={onClick}
            colSpan={isSelected ? 2 : 1}
            rowSpan={isSelected ? 1 : 1}
            position="relative"
            cursor="pointer"
            overflow="hidden"
            w="200px"
            h="200px"
        >
            <Box
                w="100%"
                h="100%"
                borderWidth="1px"
                borderRadius="20px"
                overflow="hidden"
                bg={bgColor}
                borderColor={isSelected ? borderSelectedColor : borderColor}
                transition="all 0.2s ease-in-out"
                position="relative"
            >
                {/* Image */}
                <Box position="relative" w="100%" h="100%">
                    <Image src={imageUrl} alt={recipe.name || "Recipe Image"} objectFit="cover" width="100%" height="100%" />

                    {/* Overlay with Title and Number of Times Used */}
                    <Box
                        as={motion.div}
                        layout
                        position="absolute"
                        top="auto"
                        left="0"
                        right="0"
                        bottom="0"
                        bg={overlayBgColor}
                        color="white"
                        px={2}
                        py={2}
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <HStack display="flex" w="100%" alignItems="space-between">
                            {/* Title */}
                            <Text fontWeight="bold" isTruncated>
                                {recipe.name}
                            </Text>

                            {/* Dish Type as a tag */}
                            {recipe.dishType && recipe.dishType.length > 0 && (
                                <Tag size="sm" mt={1} colorScheme="green">
                                    {recipe.dishType[0].name}
                                </Tag>
                            )}
                        </HStack>
                        {/* Description and Button when expanded */}
                        {isSelected && (
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                transition={{ duration: 0.2 }}
                                style={{ width: "100%" }}
                            >
                                <Text fontSize="sm" mt={2} noOfLines={3}>
                                    {recipe.description}
                                </Text>
                                <Button
                                    colorScheme={buttonColorScheme}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDeleteDialogOpen();
                                        // Handle recipe selection or navigation
                                    }}
                                    rightIcon={<GoArrowRight />}
                                    borderRadius="full"
                                    mt={4}
                                >
                                    Voir la recette
                                </Button>
                            </motion.div>
                        )}
                    </Box>

                    {/* Close, Edit, and Delete Buttons when expanded */}
                    {isSelected && (
                            <IconButton
                                icon={<CloseIcon fontSize="16px" />}
                                size="sm"
                                borderRadius="full"
                                position="absolute"
                                top="5px"
                                right="5px"
                                zIndex="1"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onClose();
                                }}
                                aria-label="Fermer"
                                colorScheme="blackAlpha"
                            />
                    )}
                </Box>
            </Box>

            {/* Delete Confirmation Dialog */}
            <AlertDialog isOpen={isDeleteDialogOpen} leastDestructiveRef={cancelRef} onClose={onDeleteDialogClose} isCentered>
                <AlertDialogOverlay>
                    <AlertDialogContent bg={bgColor}>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold" color={textColor}>
                            Supprimer la recette
                        </AlertDialogHeader>

                        <AlertDialogBody color={textColor}>Êtes-vous sûr de vouloir supprimer cette recette ? Cette action est irréversible.</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onDeleteDialogClose}>
                                Annuler
                            </Button>
                            <Button colorScheme="red" onClick={handleDeleteRecipe} ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </GridItem>
    );
};

export default CommunityRecipeGridItem;
