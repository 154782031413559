import React from "react";
import { toast, Slide } from "react-toastify";
import { Box, Text, Button, Flex, Avatar, useColorModeValue, useColorMode, useTheme, useBreakpointValue } from "@chakra-ui/react";
import { TbInfoSquareRoundedFilled, TbSquareRoundedCheckFilled, TbAlertSquareRoundedFilled } from "react-icons/tb";
import "react-toastify/dist/ReactToastify.css";
import "@fontsource-variable/inter";

const useCustomToast = () => {
  const theme = useTheme();

  const iconColorSuccess = useColorModeValue(theme.colors.green[500], theme.colors.green[300]);
  const iconColorError = useColorModeValue(theme.colors.red[500], theme.colors.red[300]);
  const iconColorWarning = useColorModeValue(theme.colors.yellow[500], theme.colors.yellow[300]);
  const iconColorInfo = useColorModeValue(theme.colors.blue[500], theme.colors.blue[300]);
  const textColorSuccess = useColorModeValue(theme.colors.green[900], theme.colors.green[300]);
  const textColorError = useColorModeValue(theme.colors.red[900], theme.colors.red[300]);
  const textColorWarning = useColorModeValue(theme.colors.yellow[900], theme.colors.yellow[300]);
  const textColorInfo = useColorModeValue(theme.colors.blue[900], theme.colors.blue[300]);
  const textColor = useColorModeValue(theme.colors.black, theme.colors.white);
  const iconBgColor = useColorModeValue(theme.colors.white, theme.colors.black);

  const { colorMode } = useColorMode();
  const toastTheme = colorMode === "dark" ? "dark" : "light";

  const isMobile = useBreakpointValue({ base: true, md: false });

  const customToast = React.useCallback(
    ({ status, title, description, buttonTitle = "", buttonLink = "", avatarUrl = "", senderFullName = "" }) => {
      const getStatusIcon = () => {
        const iconProps = { size: isMobile ? "50px" : "30px", style: { marginRight: "16px" } };

        switch (status) {
          case "success":
            return (
              <Box filter="drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1))">
                <TbSquareRoundedCheckFilled {...iconProps} color={iconColorSuccess} />
              </Box>
            );

          case "error":
            return (
              <Box filter="drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1))">
                <TbAlertSquareRoundedFilled {...iconProps} color={iconColorError} />
              </Box>
            );

          case "warning":
            return (
              <Box filter="drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1))">
                <TbAlertSquareRoundedFilled {...iconProps} color={iconColorWarning} />
              </Box>
            );

          case "info":
          default:
            return (
              <Box filter="drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1))">
                <TbInfoSquareRoundedFilled {...iconProps} color={iconColorInfo} />
              </Box>
            );
        }
      };

      const getStatusColorForText = () => {
        switch (status) {
          case "success":
            return textColorSuccess;
          case "error":
            return textColorError;
          case "warning":
            return textColorWarning;
          case "info":
          default:
            return textColorInfo;
        }
      };

      const toastContent = (
        <Box p={4} color={textColor} pointerEvents="auto" position="relative" textAlign="left" onClick={() => toast.dismiss()}>
          <Flex
            direction={isMobile ? "row" : "row"}
            alignItems={isMobile ? "flex-start" : "center"}
            justifyContent={isMobile ? "flex-start" : "space-between"}
          >
            {/* Icon on the top left for mobile */}
            <Box display="flex" alignItems="center" justifyContent="flex-start" mb={isMobile ? 2 : 0} bg={iconBgColor}>
              {status === "message" ? <Avatar size="md" src={avatarUrl} name={senderFullName} mr={isMobile ? 0 : 2} /> : getStatusIcon()}
            </Box>

            {/* Content (Title and Description) */}
            <Box flex="1" mx={isMobile ? 0 : 4} mt={isMobile ? 0 : 0}>
              {/* Title on the top right in mobile view */}
              <Flex justifyContent="space-between" alignItems="center" width="100%">
                <Text fontWeight="bold" fontSize="18px" mb={isMobile ? 0 : 1} color={isMobile ? getStatusColorForText() : undefined}>
                  {title}
                </Text>
              </Flex>

              {/* Description under the title */}
              <Text fontWeight="normal" fontSize="14px" mt={isMobile ? 2 : 0} color={isMobile ? getStatusColorForText() : undefined}>
                {description}
              </Text>

              {/* Optional Button */}
              {buttonTitle && buttonLink && (
                <Button
                  size="sm"
                  colorScheme="blue"
                  mt={isMobile ? 2 : 0}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(buttonLink, "_blank");
                  }}
                >
                  {buttonTitle}
                </Button>
              )}
            </Box>
          </Flex>

          {/* Custom CSS for Toastify */}
          <style>
            {`
              .Toastify__toast-container {
                z-index: 9999;
                pointer-events: none;
                display: flex;
                flex-direction: column;
                align-items: ${isMobile ? "center" : "flex-end"};
                justify-content: flex-end;
                width: 100% !important;
              }
              .Toastify__toast {
                pointer-events: auto;
              }
              :root {
                --toastify-font-family: inherit !important;
              }
            `}
          </style>
        </Box>
      );

      const toastOptions = {
        type: status === "message" ? "info" : status,
        position: isMobile ? "top-center" : "bottom-right",
        autoClose: 3500,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: toastTheme,
        icon: false,
        transition: Slide,
        closeButton: true,
        hideProgressBar: false,
        style: isMobile
          ? {
              maxWidth: "90%",
              marginTop: "10px",
              borderRadius: "15px",
            }
          : {},
      };

      toast(toastContent, toastOptions);
    },
    [
      iconColorSuccess,
      iconColorError,
      iconColorWarning,
      iconColorInfo,
      textColor,
      toastTheme,
      isMobile,
      iconBgColor,
      textColorSuccess,
      textColorError,
      textColorWarning,
      textColorInfo,
    ]
  );

  return customToast;
};

export default useCustomToast;
