import React, { useState, useEffect, useMemo } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Box,
  IconButton,
  Checkbox,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Button,
} from "@chakra-ui/react";
import { FaRegTrashCan } from "react-icons/fa6";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";

const ModularTable = ({ columns, data, onEdit, onDelete, allowColumnVisibility = true, allowSearch = true, allowSorting = true }) => {
  const initialVisibleColumns = columns.reduce((acc, col) => {
    acc[col.accessor] = col.defaultVisible !== false;
    return acc;
  }, {});

  const [visibleColumns, setVisibleColumns] = useState(initialVisibleColumns);
  const [search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "none" });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // State to manage which columns to search in
  const [searchColumns, setSearchColumns] = useState(columns.map((col) => col.accessor));

  const handleColumnChange = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  // Memoize filtered data
  const filteredData = useMemo(() => {
    if (!Array.isArray(data)) return [];
    if (!search) return data;

    const lowercasedSearch = search.toLowerCase();
    const columnsToSearch = searchColumns.length > 0 ? searchColumns : columns.map((col) => col.accessor);

    return data.filter((row) => {
      return columnsToSearch.some((accessor) => {
        const cellValue = row[accessor];
        if (cellValue === undefined || cellValue === null) return false;

        // Handle different data types
        if (typeof cellValue === "string" || typeof cellValue === "number") {
          return String(cellValue).toLowerCase().includes(lowercasedSearch);
        } else if (Array.isArray(cellValue)) {
          return cellValue.some((item) => {
            if (typeof item === "string" || typeof item === "number") {
              return String(item).toLowerCase().includes(lowercasedSearch);
            } else if (typeof item === "object" && item !== null) {
              return Object.values(item).some((val) => String(val).toLowerCase().includes(lowercasedSearch));
            }
            return false;
          });
        } else if (typeof cellValue === "object") {
          return Object.values(cellValue).some((val) => String(val).toLowerCase().includes(lowercasedSearch));
        }
        return false;
      });
    });
  }, [data, search, searchColumns, columns]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    } else if (sortConfig.key === key && sortConfig.direction === "descending") {
      direction = "none";
    }
    setSortConfig({ key, direction });
  };

  // Memoize sorted data
  const sortedData = useMemo(() => {
    if (sortConfig.direction === "none") return filteredData;

    return [...filteredData].sort((a, b) => {
      if (sortConfig.key) {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (aValue === null || aValue === undefined) return 1;
        if (bValue === null || bValue === undefined) return -1;

        if (typeof aValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        } else {
          return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
        }
      }
      return 0;
    });
  }, [filteredData, sortConfig]);

  // Ensure currentPage is reset when sortedData changes
  useEffect(() => {
    setCurrentPage(1);
  }, [search, sortConfig]);

  // Pagination calculations
  const totalPages = sortedData.length > 0 ? Math.ceil(sortedData.length / itemsPerPage) : 1;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Memoize current page data
  const currentData = useMemo(() => {
    return sortedData.slice(indexOfFirstItem, indexOfLastItem);
  }, [sortedData, indexOfFirstItem, indexOfLastItem]);

  // Adjust currentPage if it exceeds totalPages
  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  const handleCheckboxChange = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAllChange = () => {
    if (isSelectedAll) {
      setSelectedRows(selectedRows.filter((id) => !currentData.some((row) => row._id === id)));
    } else {
      setSelectedRows([...selectedRows, ...currentData.filter((row) => !selectedRows.includes(row._id)).map((row) => row._id)]);
    }
  };

  const isSelectedAll = currentData.length > 0 && currentData.every((row) => selectedRows.includes(row._id));
  const isIndeterminate = currentData.some((row) => selectedRows.includes(row._id)) && !isSelectedAll;

  const getSortIcon = (column) => {
    if (sortConfig.key !== column) return <IoFilter />;
    return sortConfig.direction === "ascending" ? <FaChevronUp /> : <FaChevronDown />;
  };

  // Function to handle deleting selected rows
  const handleDeleteSelected = () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer les éléments sélectionnés ?")) {
      // Call onDelete for each selected row
      selectedRows.forEach((id) => {
        onDelete(id);
      });
      // Clear the selection
      setSelectedRows([]);
    }
  };

  return (
    <Box
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      p={4}
      bg="white"
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <HStack justify="space-between" mb={4}>
        {allowSearch && (
          <HStack>
            <Input placeholder="Rechercher..." value={search} onChange={(e) => setSearch(e.target.value)} width="250px" />
            <Menu closeOnSelect={false}>
              <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                Colonnes de recherche
              </MenuButton>
              <MenuList minWidth="240px">
                <MenuOptionGroup
                  title="Sélectionnez les colonnes à rechercher"
                  type="checkbox"
                  defaultValue={searchColumns}
                  onChange={(values) => setSearchColumns(values)}
                >
                  {columns.map((col) => (
                    <MenuItemOption key={col.accessor} value={col.accessor}>
                      {col.label}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </HStack>
        )}
        <HStack>
          {selectedRows.length >= 2 && (
            <Button colorScheme="red" variant="outline" onClick={handleDeleteSelected} leftIcon={<FaRegTrashCan />}>
              Supprimer les sélections
            </Button>
          )}
          {allowColumnVisibility && (
            <Menu closeOnSelect={false}>
              <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                Colonnes
              </MenuButton>
              <MenuList minWidth="240px">
                <MenuOptionGroup title="Masquer des colonnes" type="checkbox">
                  {columns.map((col) => (
                    <MenuItemOption
                      key={col.accessor}
                      value={col.accessor}
                      isChecked={visibleColumns[col.accessor]}
                      onClick={() => handleColumnChange(col.accessor)}
                    >
                      {col.label}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          )}
        </HStack>
      </HStack>
      <Box flex="1" overflowY="auto">
        <Table variant="simple" width="100%" size="sm">
          <Thead position="sticky" top={0} bg="white" zIndex={1}>
            <Tr>
              <Th width="50px">
                <Checkbox isChecked={isSelectedAll} isIndeterminate={isIndeterminate} onChange={handleSelectAllChange} borderColor="gray.400" />
              </Th>
              {columns.map(
                (col) =>
                  visibleColumns[col.accessor] && (
                    <Th
                      key={col.accessor}
                      onClick={allowSorting ? () => handleSort(col.accessor) : undefined}
                      cursor={allowSorting ? "pointer" : "default"}
                      _hover={allowSorting ? { bg: "gray.100" } : undefined}
                    >
                      <HStack justify="space-between">
                        <Text>{col.label}</Text>
                        {allowSorting && getSortIcon(col.accessor)}
                      </HStack>
                    </Th>
                  )
              )}
              <Th textAlign="right">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentData.map((row) => (
              <Tr key={row._id} _hover={{ bg: "gray.50" }} cursor="pointer">
                <Td width="50px">
                  <Checkbox
                    isChecked={selectedRows.includes(row._id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleCheckboxChange(row._id);
                    }}
                    borderColor="gray.600"
                  />
                </Td>
                {columns.map(
                  (col) =>
                    visibleColumns[col.accessor] && <Td key={col.accessor}>{col.render ? col.render(row[col.accessor], row) : row[col.accessor]}</Td>
                )}
                <Td textAlign="right">
                  <HStack spacing={2} justifyContent="flex-end">
                    <IconButton
                      icon={<FaRegTrashCan />}
                      variant="outline"
                      colorScheme="gray"
                      _hover={{ bg: "red.100", borderColor: "red.300", color: "red.700" }}
                      aria-label="Delete"
                      onClick={() => onDelete(row._id)}
                      size="sm"
                    />
                    <IconButton icon={<FiEdit />} colorScheme="primary" aria-label="Edit" onClick={() => onEdit(row)} size="sm" />
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Box display="flex" justifyContent="space-between" mt={4} color="gray.600" alignItems="center">
        <Text>{sortedData.length} éléments</Text>
        <Box display="flex" alignItems="center" gap={2}>
          <Button variant="ghost" onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
            Précédent
          </Button>
          <Text>
            Page {currentPage} sur {totalPages}
          </Text>
          <Button variant="ghost" onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
            Suivant
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ModularTable;
