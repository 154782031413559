import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Spinner,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Divider,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  HStack,
  Heading,
  useBreakpointValue,
  useColorModeValue,
  useColorMode,
  useToast,
  Text,
  SkeletonCircle,
  SkeletonText,
  Slide,
} from "@chakra-ui/react";
import { MdKey, MdDownload, MdExitToApp, MdCheck, MdClose } from "react-icons/md";
import { FaPersonCircleMinus, FaPersonCirclePlus  } from "react-icons/fa6";
import { saveAs } from "file-saver";
import useCustomToast from "../5 - General/Utils/UtilsNotification";
import RadioCardGroup from "../2 - Components/Reusable/CustomRadioCard"; // Import RadioCardGroup

function SettingsPage() {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT || "https://api.penseetcuisine.ca";
  const [loading, setLoading] = useState(true);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [userData, setUserData] = useState({});
  const [initialUserData, setInitialUserData] = useState({});
  const { isOpen: isPasswordOpen, onOpen: onPasswordOpen, onClose: onPasswordClose } = useDisclosure();
  const { isOpen: isPolicyOpen, onOpen: onPolicyOpen, onClose: onPolicyClose } = useDisclosure();
  const toast = useToast();
  const { colorMode, setColorMode } = useColorMode();
  const [selectedColorMode, setSelectedColorMode] = useState(colorMode);
  const [initialColorMode, setInitialColorMode] = useState(colorMode);
  const [isModified, setIsModified] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const inputStyle = { fontSize: isMobile ? "16px" : "14px" };

  const customToast = useCustomToast();
  const textColor = useColorModeValue("gray.800", "gray.200");
  const bgColor = useColorModeValue("white", "gray.800");
  const bgDarkMode = useColorModeValue("gray.700", "gray.700");
  const bgCardDarkMode = useColorModeValue("gray.800", "gray.800");
  const bgLightMode = useColorModeValue("white", "white");
  const bgCardLightMode = useColorModeValue("gray.100", "gray.100");

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl]);

  useEffect(() => {
    setIsModified(selectedColorMode !== initialColorMode || JSON.stringify(userData) !== JSON.stringify(initialUserData));
  }, [selectedColorMode, initialColorMode, userData, initialUserData]);

  // Warn user of unsaved changes when navigating away
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isModified) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isModified]);

  const handleDataDownload = () => {
    const data = JSON.stringify(userData, null, 2);
    const blob = new Blob([data], { type: "application/json" });
    const userId = localStorage.getItem("userId");
    saveAs(blob, `${userId}-user_data.json`);
  };

  const handlePasswordReset = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("L'utilisateur n'est pas authentifié.");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/v1/auth/forgot-password`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: userData.email }),
      });

      if (response.ok) {
        toast({
          title: "Mot de passe oublié",
          description: "Lien de réinitialisation envoyé à votre courriel",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
        onPasswordClose();
      } else {
        toast({
          title: "Erreur",
          description: "Impossible d'envoyer le lien de réinitialisation",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la requête de réinitialisation du mot de passe:", error);
      toast({
        title: "Erreur",
        description: "Erreur de serveur, merci de réessayer plus tard.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleLogout = () => {
    if (isModified) {
      const confirmLeave = window.confirm("Vous avez des modifications non sauvegardées. Êtes-vous sûr de vouloir vous déconnecter ?");
      if (!confirmLeave) {
        return;
      }
    }
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  const handleSaveSettings = () => {
    // Save preference to server
    const token = localStorage.getItem("token");

    if (token) {
      // Save the color mode preference and name changes to the user's profile
      fetch(`${apiUrl}/api/v1/auth/update-profile`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          colorMode: selectedColorMode,
          firstName: userData.firstName,
          lastName: userData.lastName,
          level: userData.level,
        }),
      })
        .then((response) => {
          if (response.ok) {
            customToast({
              status: "success",
              title: "Paramètres sauvegardés",
              description: "Vos paramètres ont été mis à jour.",
            });
            setIsModified(false);
            setInitialColorMode(selectedColorMode);
            setInitialUserData(userData);
            if (selectedColorMode !== colorMode) {
              setColorMode(selectedColorMode);
              localStorage.setItem("theme", selectedColorMode);
            }
          } else {
            customToast({
              status: "error",
              title: "Erreur",
              description: "Impossible de sauvegarder les paramètres.",
            });
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la sauvegarde des paramètres:", error);
          customToast({
            status: "error",
            title: "Erreur",
            description: "Erreur de serveur, merci de réessayer plus tard.",
          });
        });
    }
  };

  const handleCancelSettings = () => {
    setSelectedColorMode(initialColorMode);
    setUserData(initialUserData);
    setIsModified(false);
  };

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("L'utilisateur est déconnecté");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/v1/user/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.log(errorData.message);
        setLoading(false);
        return;
      }

      const profile = await response.json();
      setUserData(profile);
      setInitialUserData(profile);

      // Check if profile is complete and email is verified
      setIsProfileComplete(!!profile.firstName && !!profile.lastName);
      setIsEmailVerified(profile.isVerified);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      console.log("Erreur du serveur, veuillez réessayer plus tard");
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex justify="center" align="center" p={4} flexDirection="column" mb={isMobile ? "90px" : "10px"}>
      <Box position="fixed" top="0" left="0" right="0" zIndex="1000">
        <Slide direction="top" in={isModified} style={{ zIndex: 10 }}>
          <Box bg={bgColor} boxShadow="md" p={4}>
            <HStack spacing={4} justify="space-between">
              <Button onClick={handleCancelSettings} leftIcon={<MdClose />}>
                Annuler
              </Button>
              <Button colorScheme="primary" onClick={handleSaveSettings} leftIcon={<MdCheck />}>
                Mettre à jour
              </Button>
            </HStack>
          </Box>
        </Slide>
      </Box>
      <Box width="100%" maxW="800px" bg={bgColor} p={6}>
        <VStack spacing={6} align="stretch" width="100%">
          <Heading size="md" color={textColor}>
            Paramètres du Compte
          </Heading>

          {/* Profile Completion & Email Verified Tags */}
          <HStack spacing={4} justify="start">
            <Tag size="lg" colorScheme={isProfileComplete ? "green" : "yellow"}>
              {isProfileComplete ? "Profil Complet" : "Complétez votre Profil"}
            </Tag>
            <Tag size="lg" colorScheme={isEmailVerified ? "green" : "yellow"}>
              {isEmailVerified ? "Email Vérifié" : "Vérifiez votre Email"}
            </Tag>
          </HStack>

          {/* Profile Form */}
          <FormControl>
            <FormLabel color={textColor}>Prénom</FormLabel>
            <Input
              id="first-name"
              value={userData.firstName || ""}
              onChange={(e) => {
                setUserData({ ...userData, firstName: e.target.value });
                setIsModified(true);
              }}
              style={inputStyle}
            />
          </FormControl>
          <FormControl>
            <FormLabel color={textColor}>Nom</FormLabel>
            <Input
              id="last-name"
              value={userData.lastName || ""}
              onChange={(e) => {
                setUserData({ ...userData, lastName: e.target.value });
                setIsModified(true);
              }}
              style={inputStyle}
            />
          </FormControl>

          <FormControl>
            <FormLabel color={textColor}>Email</FormLabel>
            <Input style={inputStyle} name="email" type="email" value={userData.email} isDisabled />
          </FormControl>

          <Divider />

          {/* User Level Selection */}
          <FormControl>
            <FormLabel color={textColor}>Niveau d'utilisateur</FormLabel>
            <RadioCardGroup
              options={[
                { label: "Débutant", value: "basic", icon: FaPersonCircleMinus },
                { label: "Avancé", value: "advanced", icon: FaPersonCirclePlus },
              ]}
              alignment="horizontal"
              value={userData.level}
              onChange={(value) => {
                setUserData({ ...userData, level: value });
                setIsModified(true);
              }}
            />
          </FormControl>

          <Divider />

          {/* Color Mode Selection */}
          <Heading size="md" color={textColor}>
            Choisissez le thème
          </Heading>
          <Flex mt={4} flexDirection={{ base: "row", md: "row" }}>
            {/* Light Mode */}
            <Box
              onClick={() => {
                setSelectedColorMode("light");
                setIsModified(true);
              }}
              borderWidth={selectedColorMode === "light" ? "2px" : "1px"}
              borderColor={selectedColorMode === "light" ? "primary.500" : "gray.300"}
              borderRadius="xl"
              cursor="pointer"
              p={4}
              m={2}
              width={{ base: "100%", md: "50%" }}
              bg={bgLightMode}
            >
              <Box padding="6" boxShadow="lg" borderRadius="md" bg={bgCardLightMode}>
                <SkeletonCircle color="white" size={isMobile ? 10 : 12} />
                <SkeletonText mt="4" noOfLines={isMobile ? 3 : 5} spacing="4" skeletonHeight="2" />
              </Box>
              <Text color='black' fontSize='md' fontWeight='600' textAlign="center" mt={2}>
                Mode clair
              </Text>
            </Box>

            {/* Dark Mode */}
            <Box
              onClick={() => {
                setSelectedColorMode("dark");
                setIsModified(true);
              }}
              borderWidth={selectedColorMode === "dark" ? "2px" : "1px"}
              borderColor={selectedColorMode === "dark" ? "primary.500" : "gray.300"}
              borderRadius="xl"
              cursor="pointer"
              p={4}
              m={2}
              width={{ base: "100%", md: "50%" }}
              bg={bgDarkMode}
              color="white"
            >
              <Box padding="6" boxShadow="lg" borderRadius="md" bg={bgCardDarkMode}>
                <SkeletonCircle size={isMobile ? 10 : 12} />
                <SkeletonText mt="4" noOfLines={isMobile ? 3 : 5} spacing="4" skeletonHeight="2" />
              </Box>
              <Text color='white' fontSize='md' fontWeight='600' textAlign="center" mt={2}>
                Mode sombre
              </Text>
            </Box>
          </Flex>

          <Divider />

          {/* Buttons Section */}
          <VStack spacing={4}>
            <Button leftIcon={<MdKey />} onClick={onPasswordOpen} colorScheme="gray" width="100%">
              Changer le mot de passe
            </Button>

            <Button leftIcon={<MdDownload />} onClick={handleDataDownload} colorScheme="gray" width="100%">
              Télécharger les données du compte
            </Button>

            <Button colorScheme="gray" onClick={onPolicyOpen} width="100%">
              Politique et confidentialité
            </Button>

            <Button leftIcon={<MdExitToApp />} onClick={handleLogout} colorScheme="primary" width="100%">
              Déconnexion
            </Button>
          </VStack>

          {/* Modal for Password Reset */}
          <Modal isOpen={isPasswordOpen} onClose={onPasswordClose} isCentered>
            <ModalOverlay />
            <ModalContent maxW="90%">
              <ModalHeader>Changer le mot de passe</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" value={userData.email} isDisabled />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button mt={4} colorScheme="primary" onClick={handlePasswordReset}>
                  Envoyer le lien de réinitialisation
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Modal for Policy and Privacy */}
          <Modal isOpen={isPolicyOpen} onClose={onPolicyClose} isCentered>
            <ModalOverlay />
            <ModalContent maxW="90%">
              <ModalHeader>Politique et Confidentialité</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>La page de Politique et Confidentialité est en cours de développement. Voulez-vous continuer à la page en construction ?</Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="primary" mr={3} onClick={() => (window.location.href = "/policy")}>
                  Aller à la Page
                </Button>
                <Button onClick={onPolicyClose}>Annuler</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </VStack>
      </Box>
    </Flex>
  );
}

export default SettingsPage;
