import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";
import CommunityRecipeAnnouncementCardImage from "../../img/CRAnnCard.png";

const CommunityRecipeAnnouncementCard = () => {
    const imageUrl = CommunityRecipeAnnouncementCardImage;

    return (
        <Box position="relative" borderRadius="25px" overflow="hidden" mx={4}>
            <Box bgImage={`url(${imageUrl})`} bgSize="cover" bgPosition="center" position="absolute" top="0" left="0" right="0" bottom="0" zIndex="1" />
            <Box bg="black" opacity="0.6" position="absolute" top="0" left="0" right="0" bottom="0" zIndex="2" />
            <Box position="relative" zIndex="3" p={4} color="white">
                <Text fontSize="lg" fontWeight="bold" mb={1}>
                    Nouvelle fonctionnalité!
                </Text>
                <Text fontSize='md' width="75%" mr="auto">
                    Nous sommes ravis d'annoncer une nouvelle fonctionnalité ajoutée. Essayez-la vous-même!
                </Text>
                <Box position="absolute" bottom="4" right="4">
                    <Button colorScheme="primary" variant="solid">
                        Détails
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default CommunityRecipeAnnouncementCard;
