import React from "react";
import { Box, Button, Text, VStack, Image, Heading } from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import Logo from "../../../img/LogoSquare.png";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary: ", error, errorInfo);
  }

  handleRefresh = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Box
          minH="100vh"
          bgGradient="linear(to-r, teal.500, green.500)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="white"
          textAlign="center"
          px={4}
        >
          <VStack spacing={6}>
        <Image src={Logo} alt="Logo" boxSize="150px" objectFit="contain" />
        <Heading as="h1" size="2xl">
          Oups! Quelque chose s'est mal passé.
        </Heading>
        <Text fontSize="lg">Une erreur inattendue s'est produite. Veuillez essayer de rafraîchir la page.</Text>
        <Button leftIcon={<RepeatIcon />} colorScheme="white" variant="solid" size="lg" onClick={this.handleRefresh}>
          Rafraîchir la page
        </Button>
          </VStack>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
