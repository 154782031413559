// BlueprintEditDrawer.js
import React, { useState, useEffect, useRef } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Text,
  HStack,
  NumberInput,
  NumberInputField,
  Select as ChakraSelect,
  Box,
  IconButton,
  Divider,
  Image,
  AlertDialog,
  AlertDialogBody,
  Center,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  VStack,
  Card,
  Tag,
  useBreakpointValue,
  Wrap,
  Spinner,
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon, ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import { Select } from "chakra-react-select";
import { fetchCuisineTypes, fetchDishComponents } from "../../5 - General/Utils/UtilsOptionsDefinitions";
import DishComponentEditModal from "./DishComponentEditModal";

export const BlueprintEditDrawer = ({ isOpen, onClose, blueprintId, onSave }) => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT || "https://api.penseetcuisine.ca";
  const [name, setName] = useState("");
  const [imageDimensions, setImageDimensions] = useState(null);
  const [description, setDescription] = useState("");
  const [estimatedPrepTime, setEstimatedPrepTime] = useState({
    amount: 5,
    unit: "minutes",
  });
  const [estimatedCookTime, setEstimatedCookTime] = useState({
    amount: 5,
    unit: "minutes",
  });
  const [cuisineType, setCuisineType] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [totalAllowedWeight, setTotalAllowedWeight] = useState(100);
  const [cuisineTypeOptions, setCuisineTypeOptions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [dishComponentOptions, setDishComponentOptions] = useState([]);
  const customToast = useCustomToast();
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const cancelRef = useRef();
  const [dishComponents, setDishComponents] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const inputStyle = { fontSize: isMobile ? "16px" : "14px" };

  // Function to open the confirmation dialog
  const openConfirmationDialog = () => {
    setIsAlertOpen(true);
  };

  // Function to close the confirmation dialog
  const closeConfirmationDialog = () => {
    setIsAlertOpen(false);
  };

  // Ref to prevent multiple fetches if needed
  const hasFetchedRef = useRef(false);

  useEffect(() => {
    const fetchBlueprintDetails = async () => {
      console.log(`Fetching blueprint ${blueprintId} details...`);
      if (!blueprintId) return;
      setIsFetching(true);
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/api/v1/blueprint/blueprint/${blueprintId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching blueprint: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Blueprint data:", data);

        // Initialize state variables with fetched data
        setName(data.name || "");
        setDescription(data.description || "");
        setEstimatedPrepTime(data.estimatedPrepTime || { amount: 5, unit: "minutes" });
        setEstimatedCookTime(data.estimatedCookTime || { amount: 5, unit: "minutes" });
        setCuisineType(data.cuisineType.map((ct) => ct._id || ct) || []);
        setTotalAllowedWeight(data.totalAllowedWeight || 100);

        // Update dishComponents with new fields
        setDishComponents(
          (data.dishComponents || []).map((dc) => ({
            dishComponent: dc.dishComponent?._id || "",
            name: dc.dishComponent?.name || "",
            isWeighted: dc.isWeighted ?? true,
            percentage: dc.percentage || 0,
            maxFoods: dc.maxFoods || 0,
            subCategories: (dc.subCategories || []).map((sc) => ({
              subCategory: sc.subCategory?._id || sc.subCategory || "",
              subCategoryName: sc.subCategory?.name || sc.subCategoryName || "",
              food: (sc.food || []).map((f) => ({
                food: f.food?._id || f.food || f._id || f || "",
                foodName: f.food?.name || f.foodName || f.name || "",
                level: f.level || "basic",
              })),
            })),
          }))
        );

        console.log("Data Dish Components:", data.dishComponents);

        setImagePreview(data.blueprintPicture ? `${apiUrl}/${data.blueprintPicture}` : null);
        hasFetchedRef.current = true;
        setFetchError(null);
      } catch (error) {
        console.error("Error fetching blueprint details:", error);
        setFetchError(error.message);
        customToast({
          status: "error",
          title: "Erreur",
          description: "Il y a eu un problème lors du chargement des détails du blueprint.",
        });
      } finally {
        setIsFetching(false);
      }
    };

    const fetchOptions = async () => {
      try {
        const cuisineTypes = await fetchCuisineTypes();
        setCuisineTypeOptions(cuisineTypes);
        console.log("Cuisine Types:", cuisineTypes);

        const dishComponents = await fetchDishComponents();
        setDishComponentOptions(dishComponents);
      } catch (error) {
        console.error("Error fetching options:", error);
        customToast({
          status: "error",
          title: "Erreur",
          description: "Il y a eu un problème lors du chargement des options.",
        });
      }
    };

    if (isOpen) {
      if (!hasFetchedRef.current) {
        fetchBlueprintDetails();
        fetchOptions();
      }
    }

    // Optionally, reset the fetch flag when the drawer is closed
    if (!isOpen) {
      hasFetchedRef.current = false;
      // Reset state if necessary
      setDishComponents([]);
      setName("");
      setDescription("");
      setCuisineType([]);
      setTotalAllowedWeight(100);
      setImagePreview(null);
      setImageFile(null);
    }
  }, [isOpen, blueprintId, apiUrl, customToast]);

  const handleSave = async () => {
    try {
      const updatedBlueprint = {
        name,
        description,
        estimatedPrepTime,
        estimatedCookTime,
        cuisineType,
        totalAllowedWeight,
        dishComponents,
      };

      console.log('Updated Blueprint:', updatedBlueprint);

      const token = localStorage.getItem("token");
      const response = await fetch(`${apiUrl}/api/v1/blueprint/blueprint/${blueprintId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedBlueprint),
      });

      if (!response.ok) {
        throw new Error("Failed to save blueprint");
      }

      const data = await response.json();

      // Handle image upload if a new image file is selected
      if (imageFile) {
        const formData = new FormData();
        formData.append("blueprintImage", imageFile);

        const imageResponse = await fetch(`${apiUrl}/api/v1/blueprint/upload-blueprint-picture/${blueprintId}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        if (!imageResponse.ok) {
          throw new Error("Failed to upload image");
        }

        const imageData = await imageResponse.json();

        // Update the blueprintPicture in the blueprint data
        data.blueprintPicture = imageData.blueprintPicture;
      }

      if (onSave) {
        onSave(data);
      }

      customToast({
        status: "success",
        title: "Blueprint enregistré.",
        description: "Le blueprint a été mis à jour avec succès.",
      });

      onClose();
    } catch (error) {
      console.error("Erreur lors de la mise à jour du blueprint:", error);

      customToast({
        status: "error",
        title: "Erreur",
        description: "Il y a eu un problème lors de l'enregistrement du blueprint.",
      });
    }
  };

  const handleAddDishComponent = () => {
    setDishComponents([
      ...dishComponents,
      {
        dishComponent: null,
        name: "",
        isWeighted: true,
        percentage: 0,
        maxFoods: 0,
        subCategories: [],
      },
    ]);
  };

  // For deletion confirmation
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const deleteCancelRef = useRef();

  const openDeleteConfirmation = (index) => {
    setDeleteIndex(index);
    setIsDeleteAlertOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteIndex(null);
    setIsDeleteAlertOpen(false);
  };

  const handleRemoveDishComponent = () => {
    const updatedComponents = [...dishComponents];
    updatedComponents.splice(deleteIndex, 1);
    setDishComponents(updatedComponents);
    closeDeleteConfirmation();
  };

  const [editingDishComponentIndex, setEditingDishComponentIndex] = useState(null);
  const [isDishComponentModalOpen, setIsDishComponentModalOpen] = useState(false);

  const handleEditDishComponent = (index) => {
    setEditingDishComponentIndex(index);
    setIsDishComponentModalOpen(true);
  };

  const handleUpdateDishComponent = (index, updatedComponent) => {
    const updatedComponents = [...dishComponents];
    updatedComponents[index] = updatedComponent;
    setDishComponents(updatedComponents);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const moveDishComponentUp = (index) => {
    if (index === 0) return;
    const newComponents = [...dishComponents];
    const temp = newComponents[index - 1];
    newComponents[index - 1] = newComponents[index];
    newComponents[index] = temp;
    setDishComponents(newComponents);
  };

  const moveDishComponentDown = (index) => {
    if (index === dishComponents.length - 1) return;
    const newComponents = [...dishComponents];
    const temp = newComponents[index + 1];
    newComponents[index + 1] = newComponents[index];
    newComponents[index] = temp;
    setDishComponents(newComponents);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl" closeOnOverlayClick={false} closeOnEsc={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottom="1px solid" borderColor="gray.200">
          <HStack align="center" justifyContent="space-between">
            <Text>Modifier le blueprint : {name || "Nouveau blueprint"}</Text>
            <Button colorScheme="primary" onClick={openConfirmationDialog} mr={4}>
              Enregistrer
            </Button>
            <DrawerCloseButton />
          </HStack>
        </DrawerHeader>
        <DrawerBody>
          {isFetching ? (
            <Center h="100%">
              <Spinner size="xl" colorScheme="primary" />
            </Center>
          ) : fetchError ? (
            <Center h="100%">
              <Text color="red.500">{fetchError}</Text>
            </Center>
          ) : (
            <>
              <HStack alignItems="flex-start" spacing={8} mb={4}>
                {/* Image Section */}
                <Box border="1px solid" borderColor="gray.200" borderRadius="md" p={4}>
                  <FormControl>
                    <FormLabel w={{ base: "150px", md: "300px" }}>Photo</FormLabel>
                    {imagePreview && (
                      <>
                        <Image
                          src={imagePreview.startsWith("http") ? imagePreview : `${apiUrl}/${imagePreview}`}
                          alt="Blueprint Image"
                          boxSize={{ base: "150px", md: "300px" }}
                          objectFit="cover"
                          mb={2}
                          onLoad={(e) => {
                            setImageDimensions({
                              width: e.target.naturalWidth,
                              height: e.target.naturalHeight,
                            });
                          }}
                        />
                        {imageDimensions && (
                          <Text fontSize="sm" color="gray.500" w={{ base: "150px", md: "300px" }}>
                            Dimensions actuelles: {imageDimensions.width}px par {imageDimensions.height}px
                          </Text>
                        )}
                      </>
                    )}
                    <Button as="label" cursor="pointer" colorScheme="primary" mt={4}>
                      Importer une image
                      <Input type="file" style={inputStyle} hidden onChange={handleImageUpload} accept="image/*" />
                    </Button>
                  </FormControl>
                </Box>

                {/* Form Section */}
                <Box flex="1">
                  <FormControl id="name" mb={4}>
                    <FormLabel>Nom</FormLabel>
                    <Input value={name} style={inputStyle} onChange={(e) => setName(e.target.value)} placeholder="Nom du blueprint" />
                  </FormControl>

                  <FormControl id="description" mb={4}>
                    <FormLabel>Description</FormLabel>
                    <Textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description du blueprint" />
                  </FormControl>

                  <FormControl id="total-allowed-weight" mb={4}>
                    <FormLabel>Poids recommandé total (g)</FormLabel>
                    <NumberInput
                      value={totalAllowedWeight}
                      min={0}
                      style={inputStyle}
                      onChange={(valueString) => setTotalAllowedWeight(parseInt(valueString))}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </FormControl>

                  <FormControl id="cuisineType" mb={4}>
                    <FormLabel>Type de cuisine</FormLabel>
                    <Select
                      options={cuisineTypeOptions.map((ct) => ({
                        value: ct._id || ct.value,
                        label: ct.name || ct.label,
                      }))}
                      value={cuisineTypeOptions
                        .filter((option) => cuisineType.includes(option.value))
                        .map((option) => ({
                          value: option.value,
                          label: option.label,
                        }))}
                      onChange={(selectedOptions) => setCuisineType(selectedOptions ? selectedOptions.map((option) => option.value) : [])}
                      isMulti
                    />
                  </FormControl>
                </Box>
              </HStack>

              <FormControl id="estimated-prep-time" mb={4}>
                <FormLabel>Temps de préparation estimé</FormLabel>
                <HStack>
                  <NumberInput
                    value={estimatedPrepTime.amount}
                    min={0}
                    style={inputStyle}
                    onChange={(valueString) =>
                      setEstimatedPrepTime({
                        ...estimatedPrepTime,
                        amount: parseInt(valueString),
                      })
                    }
                  >
                    <NumberInputField />
                  </NumberInput>
                  <ChakraSelect
                    value={estimatedPrepTime.unit}
                    onChange={(e) =>
                      setEstimatedPrepTime({
                        ...estimatedPrepTime,
                        unit: e.target.value,
                      })
                    }
                  >
                    <option value="minutes">Minutes</option>
                    <option value="heures">Heures</option>
                  </ChakraSelect>
                </HStack>
              </FormControl>

              <FormControl id="estimated-cook-time" mb={4}>
                <FormLabel>Temps de cuisson estimé</FormLabel>
                <HStack>
                  <NumberInput
                    value={estimatedCookTime.amount}
                    min={0}
                    style={inputStyle}
                    onChange={(valueString) =>
                      setEstimatedCookTime({
                        ...estimatedCookTime,
                        amount: parseInt(valueString),
                      })
                    }
                  >
                    <NumberInputField />
                  </NumberInput>
                  <ChakraSelect
                    value={estimatedCookTime.unit}
                    onChange={(e) =>
                      setEstimatedCookTime({
                        ...estimatedCookTime,
                        unit: e.target.value,
                      })
                    }
                  >
                    <option value="minutes">Minutes</option>
                    <option value="heures">Heures</option>
                  </ChakraSelect>
                </HStack>
              </FormControl>
              <Divider my={6} />

              <Box mb={4}>
                <HStack justifyContent="space-between" alignItems="center" mb={4}>
                  <FormLabel fontSize="2xl">Composants du Plat</FormLabel>
                  <IconButton icon={<AddIcon />} colorScheme="primary" onClick={handleAddDishComponent} size="sm" aria-label="Add Dish Component" />
                </HStack>
                <VStack align="stretch" spacing={6}>
                  {dishComponents.map((component, index) => (
                    <Card key={index} borderWidth="1px" borderRadius="md" p={4}>
                      <HStack justifyContent="space-between" alignItems="center" mb={4}>
                        <Text fontWeight="bold" fontSize="2xl">
                          {index + 1}. {component.name || "Composant sans nom"}
                        </Text>
                        <HStack>
                          <IconButton
                            icon={<ChevronUpIcon fontSize={24} />}
                            onClick={() => moveDishComponentUp(index)}
                            size="sm"
                            aria-label="Move Up"
                            isDisabled={index === 0}
                          />
                          <IconButton
                            icon={<ChevronDownIcon fontSize={24} />}
                            onClick={() => moveDishComponentDown(index)}
                            size="sm"
                            aria-label="Move Down"
                            isDisabled={index === dishComponents.length - 1}
                          />
                          <IconButton icon={<EditIcon />} onClick={() => handleEditDishComponent(index)} size="sm" aria-label="Edit Dish Component" />
                          <IconButton
                            icon={<DeleteIcon />}
                            onClick={() => openDeleteConfirmation(index)}
                            size="sm"
                            aria-label="Remove Dish Component"
                          />
                        </HStack>
                      </HStack>

                      <HStack spacing={4} mb={4}>
                        <Tag colorScheme="purple">Pourcentage du poids total: environ {component.percentage}%</Tag>
                        <Tag colorScheme="orange">Nombre d'aliments recommandé: {component.maxFoods}</Tag>
                        <Tag colorScheme={component.isWeighted ? "green" : "red"}>{component.isWeighted ? "Pondéré" : "Non pondéré"}</Tag>
                      </HStack>

                      {/* Subcategories and Aliments */}
                      {component.subCategories.map((subcat, idx) => {
                        // Separate aliments into basic and advanced
                        const basicAliments = subcat.food.filter((aliment) => aliment.level !== "advanced");
                        const advancedAliments = subcat.food.filter((aliment) => aliment.level === "advanced");

                        return (
                          <Box key={idx} mb={8}>
                            <Text fontWeight="bold" fontSize="xl" mb={2}>
                              Sous-catégorie: {subcat.subCategoryName}
                            </Text>
                            {/* Basic Aliments */}
                            {basicAliments.length > 0 && (
                              <>
                                <Text fontWeight="bold" mb={1}>
                                  Aliments de base:
                                </Text>
                                <Wrap mb={2}>
                                  {basicAliments.map((aliment, idx) => (
                                    <Tag key={idx} colorScheme="teal">
                                      {aliment.foodName}
                                    </Tag>
                                  ))}
                                </Wrap>
                              </>
                            )}
                            {/* Advanced Aliments */}
                            {advancedAliments.length > 0 && (
                              <>
                                <Text fontWeight="bold" mb={1}>
                                  Aliments avancés:
                                </Text>
                                <Wrap>
                                  {advancedAliments.map((aliment, idx) => (
                                    <Tag key={idx} colorScheme="purple">
                                      {aliment.foodName}
                                    </Tag>
                                  ))}
                                </Wrap>
                              </>
                            )}
                            <Divider my={2} />
                          </Box>
                        );
                      })}
                    </Card>
                  ))}
                </VStack>
              </Box>
            </>
          )}
        </DrawerBody>

        {/* Save Confirmation AlertDialog */}
        <AlertDialog isOpen={isAlertOpen} leastDestructiveRef={cancelRef} onClose={closeConfirmationDialog} isCentered>
          <AlertDialogOverlay>
            <AlertDialogContent maxW={{ base: "90%", md: "40%" }}>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirmer l'enregistrement
              </AlertDialogHeader>

              <AlertDialogBody>Êtes-vous sûr de vouloir enregistrer les modifications ?</AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={closeConfirmationDialog}>
                  Annuler
                </Button>
                <Button colorScheme="green" onClick={handleSave} ml={3}>
                  Enregistrer
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        {/* Delete Confirmation AlertDialog */}
        <AlertDialog isOpen={isDeleteAlertOpen} leastDestructiveRef={deleteCancelRef} onClose={closeDeleteConfirmation} isCentered>
          <AlertDialogOverlay>
            <AlertDialogContent maxW={{ base: "90%", md: "40%" }}>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Supprimer le composant
              </AlertDialogHeader>

              <AlertDialogBody>Êtes-vous sûr de vouloir supprimer ce composant ? Cette action ne peut pas être annulée.</AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={deleteCancelRef} onClick={closeDeleteConfirmation}>
                  Annuler
                </Button>
                <Button colorScheme="red" onClick={handleRemoveDishComponent} ml={3}>
                  Supprimer
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        {/* Dish Component Edit Modal */}
        {editingDishComponentIndex !== null && (
          <DishComponentEditModal
            isOpen={isDishComponentModalOpen}
            onClose={() => {
              setIsDishComponentModalOpen(false);
              setEditingDishComponentIndex(null);
            }}
            dishComponent={dishComponents[editingDishComponentIndex]}
            onSave={(updatedComponent) => {
              handleUpdateDishComponent(editingDishComponentIndex, updatedComponent);
              setIsDishComponentModalOpen(false);
              setEditingDishComponentIndex(null);
            }}
          />
        )}
      </DrawerContent>
    </Drawer>
  );
};
