import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
  VStack,
  Spinner,
  useColorModeValue,
  Image,
  Icon,
  Badge,
  Stack,
  Tooltip,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
} from "@chakra-ui/react";
import { InfoIcon, AtSignIcon, StarIcon, StarIcon as FilledStarIcon } from "@chakra-ui/icons"; // Example icons
import { motion } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeholder from "../../../img/Placeholder.jpg";
import { FaShareAlt, FaTimes } from "react-icons/fa";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";

const MotionBox = motion(Box);

const FoodProfileModal = ({ isOpen, onClose, foodName }) => {
  const [food, setFood] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false); // State for favorite
  const customToast = useCustomToast();

  // Fetch food data by name
  useEffect(() => {
    if (!foodName) {
      console.error("No foodName provided, aborting fetch.");
      setLoading(false);
      setError("Aucun nom d'aliment fourni.");
      return;
    }

    const fetchFoodData = async () => {
      try {
        setLoading(true);
        setError(null); // Reset error state before fetching
        const token = localStorage.getItem("token");
        if (token) {
          const apiUrl = process.env.REACT_APP_API_ENDPOINT;

          const response = await fetch(`${apiUrl}/api/v1/user/food/${encodeURIComponent(foodName)}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error(`Échec de la récupération des données. Code d'état: ${response.status}`);
          }

          const data = await response.json();
          console.log("Fetched data:", data); // Log the data
          setFood(data.food); // Set food to data.food
        } else {
          console.error("No token found, cannot authenticate");
          setError("Erreur d'authentification. Veuillez vous connecter.");
        }
      } catch (err) {
        console.error("Error fetching food data:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFoodData();
  }, [foodName]);

  const bg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "whiprimarypha.900");
  const bgFooter = useColorModeValue("gray.50", "gray.700");
  const shadow = useColorModeValue("lg", "dark-lg");

  // Handler for Favorite
  const handleFavorite = () => {
    setIsFavorite(!isFavorite);
    customToast({
      title: isFavorite ? "Retiré des favoris" : "Ajouté aux favoris",
      description: isFavorite ? "Cet aliment a été retiré de vos favoris " : "Cet aliment a été ajouté à vos favoris",
      status: isFavorite ? "info" : "success",
    });
  };

  // Handler for Share
  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      customToast({
        title: "Lien copié!",
        description: "Le lien de la page a été copié dans le presse-papiers.",
        status: "success",
      });
    });
  };

  // Settings for react-slick carousel
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  // Loading State
  if (loading) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="md" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent
          bg={bg}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          py={10}
          px={5}
          as={MotionBox}
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.3 }}
          maxH="80vh"
        >
          <Spinner size="xl" thickness="4px" color="primary.500" />
          <Text mt={4} fontSize="lg" color={textColor}>
            Chargement...
          </Text>
        </ModalContent>
      </Modal>
    );
  }

  // Error State
  if (error) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="md" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent
          bg={bg}
          shadow={shadow}
          as={motion.div}
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
          maxH="80vh"
        >
          <ModalHeader textAlign="center">Erreur</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Icon as={InfoIcon} w={6} h={6} color="red.500" />
              <Text color="red.500" textAlign="center">
                {error}
              </Text>
              <Button
                leftIcon={<FaTimes />}
                colorScheme="primary"
                onClick={() => {
                  setLoading(true);
                  setError(null);
                  setFood(null);
                }}
              >
                Réessayer
              </Button>
            </VStack>
          </ModalBody>
          <ModalFooter bg={bgFooter}>
            <Button colorScheme="primary" onClick={onClose} width="full" _hover={{ bg: "primary.600" }}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  // Main Content
  if (!food) {
    // In case food is still null after loading
    return null;
  }

  // Define default values and handle missing fields
  const foodGroup = food.foodGroup || [];
  const category = food.category || [];
  const subCategory = food.subCategory || [];
  const department = food.department || [];

  // Replace these with actual fields from your data
  const usedIn = food.usedIn || "Informations non disponibles";
  const usageTips = food.usageTips || "Informations non disponibles";

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered scrollBehavior="inside" motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent
        bg={bg}
        borderRadius="md"
        overflow="hidden"
        shadow={shadow}
        as={MotionBox}
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3 }}
        maxH="90vh"
        maxW='90%'
      >
        {/* Modal Header */}
        <ModalHeader p={0}>
          {/* Hero Image Carousel with Overlay */}
          <Box position="relative" height="250px" width="100%">
            {food.images && food.images.length > 0 ? (
              <Slider {...sliderSettings}>
                {food.images.map((img, index) => (
                  <Box key={index} position="relative" height="250px" width="100%">
                    <Image
                      src={img || Placeholder}
                      alt={`${food.name} image ${index + 1}`}
                      objectFit="cover"
                      width="100%"
                      height="100%"
                      loading="lazy"
                      fallback={<Image src={Placeholder} alt="Placeholder" />}
                    />
                    <Box position="absolute" top="0" left="0" width="100%" height="100%" bgGradient="linear(to-t, rgba(0,0,0,0.6), transparent)" />
                  </Box>
                ))}
              </Slider>
            ) : (
              <Image
                src={food.imageUrl || Placeholder}
                alt={food.name || "Image Alimentaire"}
                objectFit="cover"
                width="100%"
                height="100%"
                loading="lazy"
                fallback={<Image src={Placeholder} alt="Placeholder" />}
              />
            )}
            <Box position="absolute" bottom="4" left="4" color="white">
              <Badge colorScheme="primary" borderRadius="full" px={3} py={1}>
                {category.length > 0 ? category.map((cat) => cat.name).join(", ") : "Catégorie Non Disponible"}
              </Badge>
            </Box>
            {/* Favorite and Share Buttons */}
            <Box position="absolute" top="4" right="4" display="flex" gap={2}>
              <Tooltip label={isFavorite ? "Retirer des favoris" : "Ajouter aux favoris"} aria-label="Favorite Tooltip">
                <IconButton
                  icon={isFavorite ? <FilledStarIcon fontSize='20px' /> : <StarIcon fontSize='20px' />}
                  colorScheme={isFavorite ? 'yellow' : 'gray'}
                  variant="ghost"
                  onClick={handleFavorite}
                  aria-label="Favorite"
                  transition="transform 0.2s"
                  _hover={{ transform: "scale(1.2)" }}
                />
              </Tooltip>
              <Tooltip label="Partager" aria-label="Share Tooltip">
                <IconButton
                  icon={<FaShareAlt fontSize='20px' />}
                  colorScheme="blue"
                  variant="ghost"
                  onClick={handleShare}
                  aria-label="Share"
                  transition="transform 0.2s"
                  _hover={{ transform: "scale(1.2)" }}
                />
              </Tooltip>
            </Box>
          </Box>
        </ModalHeader>

        {/* Modal Body */}
        <ModalBody>
          <VStack spacing={6} mt={4} align="start">
            {/* Food Name */}
            <Text fontSize="3xl" fontWeight="bold" color={textColor}>
              {food.name || "Nom de l'aliment"}
            </Text>

            {/* Description */}
            <Box>
              <Text fontSize="lg" fontWeight="semibold" mb={1}>
                Description :
              </Text>
              <Text fontSize='md' color={textColor}>{food.description || "Description non disponible."}</Text>
            </Box>

            {/* Utilisé dans */}
            <Box>
              <Text fontSize="lg" fontWeight="semibold" mb={1}>
                Couramment utilisé dans :
              </Text>
              <Text fontSize='md' color={textColor}>{usedIn}</Text>
            </Box>

            {/* Conseils d'utilisation */}
            <Box>
              <Text fontSize="lg" fontWeight="semibold" mb={1}>
                Conseils d'utilisation :
              </Text>
              <Text fontSize='md' color={textColor}>{usageTips}</Text>
            </Box>

            {/* Details Accordion */}
            <Box width="100%">
              <Text fontSize="lg" fontWeight="semibold" mb={3}>
                Détails :
              </Text>
              <Accordion allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left" fontWeight="medium">
                        Informations Générales
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Stack spacing={3}>
                      <HStack>
                        <Icon as={StarIcon} color="primary.500" />
                        <Text fontWeight="medium">Groupe Alimentaire:</Text>
                        <Text>{foodGroup.length > 0 ? foodGroup.map((group) => group.name).join(", ") : "Non disponible"}</Text>
                      </HStack>
                      <HStack>
                        <Icon as={AtSignIcon} color="primary.500" />
                        <Text fontWeight="medium">Catégorie:</Text>
                        <Text>{category.length > 0 ? category.map((cat) => cat.name).join(", ") : "Non disponible"}</Text>
                      </HStack>
                      <HStack>
                        <Icon as={InfoIcon} color="primary.500" />
                        <Text fontWeight="medium">Département:</Text>
                        <Text>{department.length > 0 ? department.map((dept) => dept.name).join(", ") : "Non disponible"}</Text>
                      </HStack>
                      <HStack>
                        <Icon as={InfoIcon} color="primary.500" />
                        <Text fontWeight="medium">Sous-Catégorie:</Text>
                        <Text>{subCategory.length > 0 ? subCategory.map((subCat) => subCat.name).join(", ") : "Non disponible"}</Text>
                      </HStack>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </VStack>
          <Text fontSize="sm" color="gray.400" mt={4} align="center">
            © 2024 Pense et cuisine. Tous droits réservés.
          </Text>
        </ModalBody>

        {/* Footer */}
        <ModalFooter bg={bgFooter}>
          <Button
            colorScheme="primary"
            onClick={onClose}
            width="full"
            leftIcon={<FaTimes />}
            _hover={{ bg: "primary.600", transform: "scale(1.02)" }}
            transition="all 0.2s"
          >
            Fermer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FoodProfileModal;
