import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";

const CustomAlert = ({ variant = "info", title, description, onClose, onConfirm, isOpen }) => {
  const bg = useColorModeValue("white", "gray.800");
  const iconSize = variant === "success" ? "24px" : "20px";

  // Map variant to valid status and button colors
  const validStatus = variant === "destructive" || variant === "error" ? "error" : variant;
  const confirmButtonColorScheme = variant === "destructive" ? "red" : "secondary";
  const iconColor = variant === "destructive" ? "red.500" : "secondary.400";
  const closeButtonColorScheme = "gray";

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay opacity={0.3} />
      <ModalContent bg={bg} maxW="80%">
        <ModalBody p={0}>
          <Alert status={validStatus} borderRadius="md" boxShadow="md">
            <Box flex="1">
              <Flex align="center">
                {title && (
                  <AlertTitle fontSize="lg" fontWeight="bold" mr={2} display="flex" alignItems="center">
                    <AlertIcon boxSize={iconSize} m={1} mr={2} color={iconColor} />
                    {title}
                  </AlertTitle>
                )}
              </Flex>
              {description && (
                <AlertDescription fontSize="md" mt={title ? 1 : 0}>
                  {description}
                </AlertDescription>
              )}
              <Flex mt={4} justifyContent="end">
                <Button colorScheme={closeButtonColorScheme} onClick={onClose} mx={2}>
                  Fermer
                </Button>
                {onConfirm && (
                  <Button colorScheme={confirmButtonColorScheme} onClick={onConfirm}>
                    Confirmer
                  </Button>
                )}
              </Flex>
            </Box>
          </Alert>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomAlert;
