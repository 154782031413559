import React from 'react';
import { Flex, Box, Text, VStack, IconButton } from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';
import { formatFileSize } from './UtilsFormatData';
import { renderFileIcon } from './UtilsFileIcon';

const FilePreview = ({ file, handleRemoveFile }) => {
  console.log(file); // Check if the file object is correctly passed

  // Check if the file object is valid
  if (!file || (!file.name && !file.path) || !file.preview) {
    return null; // Return nothing if file doesn't have necessary properties
  }

  return (
    <Flex align="center" mb={2} p={6} pr={6} borderRadius="md" bg="gray.50">
      <Box>{renderFileIcon(file.name)}</Box>

      <VStack align="start" spacing={0} ml={3} flex="1" color="gray.600">
        <Text fontWeight="bold" fontSize="md">Fichier sélectionné:</Text>
        <Text fontSize="md" isTruncated>
          {(file.name && file.name.length > 20) ? `${file.name.substring(0, 20)}...` : (file.name || file.path)} - {file.size ? `${formatFileSize(file.size)}` : 'Taille inconnue'}
        </Text>
      </VStack>

      <IconButton
        icon={<FaTimes />}
        aria-label="Remove file"
        colorScheme='red'
        variant='outline'
        onClick={() => handleRemoveFile(file)}
        size="sm"
      />
    </Flex>
  );
};

export default FilePreview;
