import React, { useState } from "react";
import {
  Button,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useDisclosure,
  Textarea,
  useBreakpointValue,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { LuChefHat } from "react-icons/lu";

const FeedbackDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [comment, setComment] = useState("");
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const inputStyle = { fontSize: isMobile ? "16px" : "14px" };

  // Define colors based on light/dark mode
  const drawerBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const inputBg = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.400", "gray.600");
  const placeholderColor = useColorModeValue("gray.500", "gray.400");
  const buttonBg = useColorModeValue("primary.500", "primary.600");

  const handleFeedbackSubmit = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ userId, comment }),
      });

      if (!response.ok) {
        throw new Error("Failed to submit feedback");
      }

      toast({
        title: "Feedback Submitted",
        description: "Your feedback has been sent successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setComment(""); // Clear the comment field
      onClose(); // Close the drawer
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast({
        title: "Error",
        description: "There was an error submitting your feedback.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <IconButton
        aria-label="Feedback"
        onClick={onOpen}
        variant="solid"
        colorScheme="gray"
        borderRadius="full"
        border="1px solid"
        borderColor="gray.400"
        size="sm"
        icon={<LuChefHat fontSize="20px" />}
      />

      <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent maxW="90%" bg={drawerBg} color={textColor}>
          <DrawerHeader>Vous avez des idées d'améliorations?</DrawerHeader>
          <DrawerBody>
            <Textarea
              placeholder="Écrivez les ici (commentaires, fonctions, votre avis, éléments manquants, etc..)"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              size="md"
              bg={inputBg}
              color={textColor}
              borderColor={borderColor}
              _placeholder={{ color: placeholderColor }}
              style={inputStyle}
            />
          </DrawerBody>
          <DrawerFooter>
            <Button variant="ghost" ml={3} onClick={onClose} colorScheme="gray">
              Fermer
            </Button>
            <Button colorScheme="primary" bg={buttonBg} onClick={handleFeedbackSubmit} isDisabled={!comment} color="white">
              Soumettre
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default FeedbackDrawer;
