import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import {
  Box,
  Text,
  VStack,
  Heading,
  Spinner,
  Flex,
  Button,
  Avatar,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  HStack,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useColorModeValue,
  useDisclosure,
  useBreakpointValue,
  Input,
} from "@chakra-ui/react";

// Custom Hooks
import { isColorLight } from "../5 - General/Utils/UtilsTextContrast";
import { pSBC } from "../5 - General/Utils/UtilsColorPSBC";
// Libraries
import { motion, AnimatePresence } from "framer-motion";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
// Icons
import { LuListX } from "react-icons/lu";
import { FaChevronDown, FaInfo } from "react-icons/fa";
import { BsFiletypePdf } from "react-icons/bs";
import { TbShoppingCartPlus } from "react-icons/tb";
import { PiTrash } from "react-icons/pi";
// Components
import FeedbackModal from "../3 - Modal/5 - Feedback Modals/FeedbackModal";
import FoodProfileModal from "../3 - Modal/9 - Reusable Modals/FoodProfileModal";
import useCustomToast from "../5 - General/Utils/UtilsNotification";
import SearchDrawer from "../3 - Modal/6 - Liste Modals/ListeSearchDrawer";
import { convertToStandardMeasurement } from "../5 - General/Utils/UtilsMeasurementConverter";

const colorLightModeAmount = 0; // Lighten for Light Mode
const colorDarkModeAmount = -0.7; // Darken for Dark Mode

const Liste = () => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [groceryList, setGroceryList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFoodName, setSelectedFoodName] = useState(null);
  const [loading, setLoading] = useState(true);
  const customToast = useCustomToast();
  const [isClearAlertOpen, setIsClearAlertOpen] = useState(false);
  const cancelRef = React.useRef();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [searchQuery, setSearchQuery] = useState(""); // State for search bar
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();

  // Use color mode value to determine whether to lighten or darken the color
  const colorAdjustmentAmount = useColorModeValue(colorLightModeAmount, colorDarkModeAmount);
  const defaultColor = useColorModeValue("#E2E8F0", "#2D3748");
  const textColor = useColorModeValue("gray.800", "white");
  const headerColor = useColorModeValue("black", "white");
  const dividerColor = useColorModeValue("gray.200", "gray.600");
  const bgColor = useColorModeValue("white", "gray.800");
  const inputStyle = { fontSize: isMobile ? "16px" : "14px" };
  const placeholderTextColor = useColorModeValue("gray.500", "gray.300");
  const borderColor = useColorModeValue("gray.300", "gray.700");
  const checkedBgColor = useColorModeValue("green.50", "green.800");

  // Add Aliment Button color scheme
  const addAlimentButtonColorScheme = useColorModeValue("gray", "gray");

  const fetchGroceryList = useCallback(async () => {
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      const response = await fetch(`${apiUrl}/api/v1/user/grocery-list/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch grocery list");
      }

      const data = await response.json();
      const initialList = processGroceryList(data);
      setGroceryList(initialList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching grocery list:", error);
      customToast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la récupération de la liste d'épicerie.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [apiUrl, customToast]);

  useEffect(() => {
    fetchGroceryList();
  }, [customToast, apiUrl, fetchGroceryList]);

  // Process the grocery list data
  const processedGroceryList = useMemo(() => {
    const itemMap = {};

    groceryList.forEach((item) => {
      item.food.forEach((foodItem) => {
        const key = foodItem.name;

        if (!itemMap[key]) {
          itemMap[key] = {
            ...foodItem,
            foodName: foodItem.name,
            foodGroupName: item.foodGroup?.name || "Autre",
            weight: foodItem.weight || 0,
            units: foodItem.units || 0,
            checked: foodItem.checked || false,
            itemIds: [foodItem._id],
          };
        } else {
          itemMap[key].weight += foodItem.weight || 0;
          itemMap[key].units += foodItem.units || 0;
          itemMap[key].itemIds.push(foodItem._id);
          itemMap[key].checked = itemMap[key].checked || foodItem.checked;
        }
      });
    });

    const allItems = Object.values(itemMap);

    const uncheckedItems = allItems.filter((item) => !item.checked);

    const checkedItems = allItems.filter((item) => item.checked);

    const foodGroups = {};
    const foodGroupColors = {};

    uncheckedItems.forEach((item) => {
      const groupName = item.foodGroupName || "Autre";
      if (!foodGroups[groupName]) {
        foodGroups[groupName] = [];
        let color = item?.color || defaultColor;
        foodGroupColors[groupName] = pSBC(colorAdjustmentAmount, color); // Adjust color using pSBC
      }
      foodGroups[groupName].push(item);
    });

    return { foodGroups, checkedItems, foodGroupColors };
  }, [groceryList, colorAdjustmentAmount, defaultColor]);

  // eslint-disable-next-line no-unused-vars
  const filteredGroceryList = useMemo(() => {
    if (searchQuery.trim() === "") return groceryList;
    return groceryList.filter((item) => item.food.some((food) => food.name.toLowerCase().includes(searchQuery.toLowerCase())));
  }, [groceryList, searchQuery]);

  const handleCheckboxChange = (foodName) => {
    // Find the item to get its current checked status
    const item = groceryList.flatMap((group) => group.food).find((food) => food.name === foodName);

    if (!item) return;

    const newCheckedStatus = !item.checked;
    const newStatus = newCheckedStatus ? "owned" : "to_buy";

    // Update the backend
    handleUpdateItemStatus(foodName, newStatus);
  };

  // Delete selected items (checked items)
  const handleDeleteSelected = async () => {
    try {
      const selectedFoodIds = groceryList.flatMap((item) => item.food.filter((food) => food.checked)).map((food) => food._id);

      if (selectedFoodIds.length === 0) {
        customToast({
          title: "Aucun aliment sélectionné",
          description: "Veuillez sélectionner des aliments à supprimer.",
          status: "warning",
        });
        return;
      }

      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      const response = await fetch(`${apiUrl}/api/v1/user/grocery-list/delete-selected/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ selectedFoodIds }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete selected items");
      }

      const data = await response.json();
      setGroceryList(data.groceryList);
      customToast({
        title: "Suppression réussie",
        description: "Les aliments sélectionnés ont été supprimés.",
        status: "success",
      });
    } catch (error) {
      console.error("Error deleting selected items:", error);
      customToast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la suppression des aliments sélectionnés.",
        status: "error",
      });
    }
  };

  const handleDelete = async (foodName) => {
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      const itemIdsToDelete = [];
      groceryList.forEach((item) => {
        item.food.forEach((food) => {
          if (food.name === foodName) {
            itemIdsToDelete.push(food._id);
          }
        });
      });

      const response = await fetch(`${apiUrl}/api/v1/user/grocery-list/delete-selected/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ selectedFoodIds: itemIdsToDelete }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete item");
      }

      const data = await response.json();
      setGroceryList(data.groceryList);

      customToast({
        title: "Suppression réussie",
        description: "L'aliment a été supprimé.",
        status: "success",
      });
    } catch (error) {
      console.error("Error deleting item:", error);
      customToast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la suppression de l'aliment.",
        status: "error",
      });
    }
  };

  const handleUpdateItemStatus = async (foodName, newStatus) => {
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      // Find the IDs of the items to update
      const itemIdsToUpdate = [];
      groceryList.forEach((item) => {
        item.food.forEach((food) => {
          if (food.name === foodName) {
            itemIdsToUpdate.push(food._id);
          }
        });
      });

      const response = await fetch(`${apiUrl}/api/v1/user/grocery-list/update-status/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ foodIds: itemIdsToUpdate, newStatus }),
      });

      if (!response.ok) {
        throw new Error("Failed to update item status");
      }

      const data = await response.json();

      // Process the updated grocery list
      const updatedList = processGroceryList(data);
      setGroceryList(updatedList);
    } catch (error) {
      console.error("Error updating item status:", error);
      customToast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la mise à jour du statut de l'aliment.",
        status: "error",
      });
    }
  };

  const processGroceryList = (data) => {
    return data.groceryList.map((item) => ({
      ...item,
      food: item.food.map((food) => ({
        ...food,
        checked: food.status === "owned",
      })),
    }));
  };

  const handleClearList = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      const response = await fetch(`${apiUrl}/api/v1/user/grocery-list/clear/${userId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to clear grocery list");
      }

      const data = await response.json();

      setGroceryList(data.groceryList);
      setIsClearAlertOpen(false);

      customToast({
        title: "Liste effacée",
        description: "La liste d'épicerie a été effacée avec succès.",
        status: "success",
      });
    } catch (error) {
      console.error("Error clearing grocery list:", error);
      customToast({
        title: "Erreur",
        description: "Une erreur est survenue lors de l'effacement de la liste d'épicerie.",
        status: "error",
      });
    }
  };

  const handleSaveToPDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const currentDate = new Date().toLocaleDateString();
    const companyName = "Pense et cuisine - Liste d'épicerie - penseetcuisine.ca";

    // Add company name and date title (centered)
    doc.setFontSize(14);
    doc.text(companyName, pageWidth / 2, 20, { align: "center" });
    doc.setFontSize(10);
    doc.text(`Créée le : ${currentDate}`, pageWidth / 2, 30, { align: "center" });

    let startY = 40; // Starting Y position for the first table

    // Process the grocery list to group items by foodGroup and separate checked and unchecked items
    const { foodGroups, checkedItems } = processedGroceryList;

    // Iterate over each food group
    Object.keys(foodGroups).forEach((groupName, index) => {
      const groupItems = foodGroups[groupName];

      // Prepare table data for this group
      const tableData = groupItems.map((item) => ({
        checked: item.checked,
        name: item.foodName,
        weight: item.weight ? `${item.weight.toFixed(0)} g` : "",
        units: item.units ? `${item.units} unités` : "",
      }));

      // Add group name as a header
      doc.setFontSize(12);
      doc.text(groupName, 16, startY + 6); // Adjust X and Y positions as needed

      // Define columns for this group's table
      const tableColumns = [
        { header: "", dataKey: "checked" },
        { header: "Nom de l'article", dataKey: "name" },
        { header: "Poids", dataKey: "weight" },
        { header: "Unités", dataKey: "units" },
      ];

      // Add the table for this group
      doc.autoTable({
        startY: startY + 10, // Start below the group name
        columns: tableColumns,
        body: tableData,
        styles: {
          fontSize: 10,
          cellPadding: 2,
          lineHeight: 1.5, // Reduce cell height by 1.5 times
        },
        headStyles: {
          fillColor: [0, 99, 61], // Primary color
          textColor: 255,
        },
        columnStyles: {
          checked: { cellWidth: 15 },
        },
        willDrawCell: function (data) {
          if (data.column.dataKey === "checked" && data.cell.section === "body") {
            data.cell.text = ""; // Prevent default text rendering
          }
        },
        didDrawCell: function (data) {
          if (data.column.dataKey === "checked" && data.cell.section === "body") {
            const doc = data.doc;
            const cell = data.cell;
            const ischecked = data.cell.raw;
            const x = cell.x;
            const y = cell.y;
            const width = cell.width;
            const height = cell.height;

            // Calculate checkbox size and position
            const checkboxSize = height * 0.6; // 60% of cell height
            const checkboxX = x + (width - checkboxSize) / 2;
            const checkboxY = y + (height - checkboxSize) / 2;

            if (ischecked) {
              // Checked: Draw a green-filled rectangle
              doc.setDrawColor(0);
              doc.setFillColor(0, 99, 61);
              doc.rect(checkboxX, checkboxY, checkboxSize, checkboxSize, "FD");
            } else {
              // Unchecked: Draw an empty rectangle
              doc.setDrawColor(0);
              doc.rect(checkboxX, checkboxY, checkboxSize, checkboxSize);
            }
          }
        },
        margin: { left: 14, right: 14 },
        theme: "grid",
      });

      // Update startY for the next table
      startY = doc.lastAutoTable.finalY + 10;

      // Add a page break if needed
      if (startY > doc.internal.pageSize.getHeight() - 20) {
        doc.addPage();
        startY = 15;
      }
    });

    // Handle checked items that are not part of any group (if any)
    if (checkedItems.length > 0) {
      // Prepare table data for checked items
      const tableData = checkedItems.map((item) => ({
        checked: item.checked,
        name: item.foodName,
        weight: item.weight ? `${item.weight.toFixed(0)} g` : "",
        units: item.units ? `${item.units} unités` : "",
      }));

      // Add a header for checked items
      doc.setFontSize(12);
      doc.text("Dans le panier", 14, startY + 6);

      // Define columns for checked items table
      const tableColumns = [
        { header: "", dataKey: "checked" },
        { header: "Nom de l'article", dataKey: "name" },
        { header: "Poids", dataKey: "weight" },
        { header: "Unités", dataKey: "units" },
      ];

      // Add the table for checked items
      doc.autoTable({
        startY: startY + 10,
        columns: tableColumns,
        body: tableData,
        styles: {
          fontSize: 10,
          cellPadding: 2,
          lineHeight: 1.5, // Reduce cell height by 1.5 times
        },
        headStyles: {
          fillColor: [0, 99, 61],
          textColor: 255,
        },
        columnStyles: {
          checked: { cellWidth: 10 },
        },
        willDrawCell: function (data) {
          if (data.column.dataKey === "checked" && data.cell.section === "body") {
            data.cell.text = "";
          }
        },
        didDrawCell: function (data) {
          if (data.column.dataKey === "checked" && data.cell.section === "body") {
            const doc = data.doc;
            const cell = data.cell;
            const ischecked = data.cell.raw;
            const x = cell.x;
            const y = cell.y;
            const width = cell.width;
            const height = cell.height;

            const checkboxSize = height * 0.6;
            const checkboxX = x + (width - checkboxSize) / 2;
            const checkboxY = y + (height - checkboxSize) / 2;

            if (ischecked) {
              doc.setDrawColor(0);
              doc.setFillColor(0, 99, 61);
              doc.rect(checkboxX, checkboxY, checkboxSize, checkboxSize, "FD");
            } else {
              doc.setDrawColor(0);
              doc.rect(checkboxX, checkboxY, checkboxSize, checkboxSize);
            }
          }
        },
        margin: { left: 14, right: 14 },
        theme: "grid",
      });
    }

    // Save the PDF with the current date and file name
    doc.save(`${currentDate}-liste-epicerie-penseetcuisine.pdf`);
  };

  const openModalWithFood = (foodName) => {
    setSelectedFoodName(foodName);
    onOpen();
  };

  if (loading) {
    return (
      <Center minHeight="100vh">
        <Spinner size="xl" colorScheme="primary" />
      </Center>
    );
  }

  return (
    <Box overflowY="auto" maxHeight="100vh" pb="100px">
      {/* Sticky Topbar */}
      <Flex p={4} alignItems="center" justifyContent="space-between" mb={4} position="sticky" top="0" zIndex="1" boxShadow="md" bg={bgColor}>
        <VStack alignItems="flex-start" w="100%">
          <HStack w="100%" justifyContent="space-between" mb={2} align="center">
            <Heading color={headerColor}>Liste d'épicerie</Heading>
            <FeedbackModal />
          </HStack>

          {/* Search Bar */}
          <Input
            placeholder="Rechercher dans la liste..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={inputStyle}
            borderColor={borderColor}
            color={textColor}
            _placeholder={{ color: placeholderTextColor }}
          />

          <HStack spacing={4} w="100%" mt={2}>
            {/* Add Aliment Button */}
            <Button
              leftIcon={<TbShoppingCartPlus fontSize="20px" />}
              colorScheme={addAlimentButtonColorScheme}
              border="1px solid"
              borderColor={dividerColor}
              onClick={onDrawerOpen} // Open the drawer
              borderRadius="full"
              size="md"
              h={10}
              px={4}
              w="100%"
            >
              Ajouter des aliments
            </Button>
            {/* Menu for options */}
            <Menu>
              <MenuButton
                as={Button}
                colorScheme="primary"
                rightIcon={<FaChevronDown fontSize="18px" />}
                px={4}
                py={2}
                borderRadius="full"
                size="md"
                aria-label="Options Menu"
                _hover={{ bg: "primary.600" }}
                w="100%"
              >
                Actions rapides
              </MenuButton>
              <MenuList>
                <MenuItem icon={<BsFiletypePdf fontSize="18px" />} onClick={handleSaveToPDF}>
                  Générer un PDF imprimable
                </MenuItem>
                <MenuItem icon={<LuListX fontSize="18px" />} onClick={handleDeleteSelected}>
                  Supprimer les aliments sélectionnés
                </MenuItem>
                <MenuItem icon={<PiTrash fontSize="18px" />} onClick={() => setIsClearAlertOpen(true)}>
                  Effacer toute la liste
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </VStack>
      </Flex>

      {/* Alert Dialog for Clearing the List */}
      <AlertDialog isOpen={isClearAlertOpen} leastDestructiveRef={cancelRef} onClose={() => setIsClearAlertOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirmation d'effacement
            </AlertDialogHeader>
            <AlertDialogBody>Êtes-vous sûr de vouloir effacer toute la liste d'épicerie ?</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsClearAlertOpen(false)} colorScheme="gray" variant="outline" mr={3}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={handleClearList}>
                Effacer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Grocery List Sections */}
      <AnimatePresence initial={false}>
        {Object.keys(processedGroceryList.foodGroups).map((groupName) => (
          <Box key={groupName} m={6}>
            <Heading size="md" mb={4} color={textColor}>
              {groupName}
            </Heading>
            <VStack align="stretch" spacing={4}>
              {processedGroceryList.foodGroups[groupName].map((foodItem) => (
                <FoodItem
                  key={foodItem.foodName}
                  foodItem={foodItem}
                  handleCheckboxChange={handleCheckboxChange}
                  handleUpdateItemStatus={handleUpdateItemStatus}
                  handleDelete={handleDelete}
                  bgColor={processedGroceryList.foodGroupColors[groupName]}
                  openModalWithFood={openModalWithFood}
                  setSelectedFoodName={setSelectedFoodName}
                />
              ))}
            </VStack>
          </Box>
        ))}

        {processedGroceryList.checkedItems.length > 0 && (
          <Box m={6}>
            <Heading size="md" mb={4} color={textColor}>
              Dans le panier
            </Heading>
            <VStack align="stretch" spacing={4}>
              {processedGroceryList.checkedItems.map((foodItem) => (
                <FoodItem
                  key={foodItem.foodName}
                  foodItem={foodItem}
                  handleCheckboxChange={handleCheckboxChange}
                  handleUpdateItemStatus={handleUpdateItemStatus}
                  handleDelete={handleDelete}
                  bgColor={checkedBgColor}
                  openModalWithFood={openModalWithFood}
                  setSelectedFoodName={setSelectedFoodName}
                />
              ))}
            </VStack>
          </Box>
        )}
      </AnimatePresence>
      {/* Search Drawer */}
      <SearchDrawer isOpen={isDrawerOpen} onClose={onDrawerClose} onGroceryListUpdated={fetchGroceryList} />
      {/* Food Profile Modal */}
      {selectedFoodName && <FoodProfileModal isOpen={isOpen} onClose={onClose} foodName={selectedFoodName} />}
    </Box>
  );
};

const FoodItem = ({
  foodItem,
  handleCheckboxChange,
  handleUpdateItemStatus,
  handleDelete,
  bgColor,
  openModalWithFood,
  setSelectedFoodName,
}) => {
  const { foodName, checked, weight, units } = foodItem;
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const deleteCancelRef = useRef();

  const itemBgHoverColor = useColorModeValue("secondary.100", "gray.600");

  // Ensure that bgColor and itemBgColor are valid hex colors
  const validBgColor = /^#[0-9A-F]{6}$/i.test(bgColor) ? bgColor : "#FFFFFF"; // Default to white if invalid

  // Determine text colors based on lightness of the background color
  const textColor = isColorLight(validBgColor) ? "black" : "white";
  const foodNameTextColor = useColorModeValue("gray.700", "gray.100");
  const weightTextColor = useColorModeValue("gray.500", "gray.300");
  const checkedTextColor = useColorModeValue("gray.500", "gray.500");
  const avatarBgColorChecked = useColorModeValue("secondary.50", "gray.100");
  const avatarBorderColor = useColorModeValue("gray.200", "gray.600");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  // Use the conversion utility for weight
  const convertedWeight = weight
    ? convertToStandardMeasurement(weight, "g", foodName)
    : "";

  return (
    <>
      <motion.div
        layout
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.3 }}
      >
        <Flex
          align="center"
          py={2}
          px={4}
          borderRadius="2xl"
          border="1px solid"
          borderColor={borderColor}
          boxShadow="md"
          transition="background-color 0.3s, box-shadow 0.3s"
          _hover={{ boxShadow: "md", bg: itemBgHoverColor }}
          onClick={() => handleCheckboxChange(foodName)}
          cursor="pointer"
          role="group"
        >
          {/* Avatar */}
            <Avatar
              bg={checked ? avatarBgColorChecked : validBgColor} // Use the valid background color here
              color={checked ? "black" : textColor} // Use the determined text color here
              textDecoration={checked ? "line-through" : "none"}
              borderWidth="2px" // Set the border width explicitly
              borderColor={avatarBorderColor} // Ensure the border color is applied correctly
              name={foodName}
              size="md"
            />
          {/* Text Content */}
          <Box flex="1" ml={4}>
            <Text
              fontWeight="bold"
              fontSize="lg"
              color={checked ? checkedTextColor : foodNameTextColor}
            >
              {foodName}
            </Text>
            <Text
              fontWeight="medium"
              fontSize="sm"
              color={checked ? checkedTextColor : weightTextColor}
            >
              {convertedWeight ? `Environ ${convertedWeight}` : ""}
              {units ? ` | Environ ${units} unités` : ""}
            </Text>
          </Box>

          {/* Action Buttons */}
          <Flex align="center">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedFoodName(foodName);
                  openModalWithFood(foodName);
                }}
                size="sm"
                icon={<FaInfo />}
                colorScheme="gray"
                mr={2}
                borderRadius="full"
              />
            <IconButton
              icon={<PiTrash fontSize="20px" />}
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering the parent click event
                setIsDeleteAlertOpen(true); // Open the confirmation dialog
              }}
              size="sm"
              colorScheme="gray"
              mr={2}
              borderRadius="full"
            />
          </Flex>
        </Flex>
      </motion.div>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        isCentered
        leastDestructiveRef={deleteCancelRef}
        onClose={() => setIsDeleteAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer l'aliment
            </AlertDialogHeader>

            <AlertDialogBody>
              Êtes-vous sûr de vouloir supprimer cet aliment de votre liste
              d'épicerie?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={deleteCancelRef}
                onClick={() => setIsDeleteAlertOpen(false)}
              >
                Annuler
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  handleDelete(foodName);
                  setIsDeleteAlertOpen(false);
                }}
                ml={3}
              >
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Liste;
