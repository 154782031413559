import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Box,
  Input,
} from "@chakra-ui/react";

import { Select } from "chakra-react-select";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import { SketchPicker } from "react-color";

const GeneralGestionFormModal = ({
  isOpen,
  onClose,
  onSave,
  initialData,
  entityName,
  apiEndpoint,
  formFields,
  modalTitle,
  successMessage,
  failureMessage,
  ...props
}) => {
  const [formData, setFormData] = useState({});
  const customToast = useCustomToast();

  useEffect(() => {
    if (initialData) {
      const initialFormState = {};
      formFields.forEach((field) => {
        if (field.type === "select" && field.isMulti) {
          initialFormState[field.name] = initialData[field.name]?.map((item) => item._id) || [];
        } else if (field.type === "select") {
          initialFormState[field.name] = initialData[field.name]?._id || null;
        } else {
          initialFormState[field.name] = initialData[field.name] || "";
        }
      });
      setFormData(initialFormState);
    } else {
      // Initialize form data with empty strings or default values
      const initialFormState = {};
      formFields.forEach((field) => {
        if (field.type === "select" && field.isMulti) {
          initialFormState[field.name] = field.initialValue || field.defaultValue || [];
        } else if (field.type === "select") {
          initialFormState[field.name] = field.initialValue || field.defaultValue || null;
        } else {
          initialFormState[field.name] = field.defaultValue || "";
        }
      });
      setFormData(initialFormState);
    }
  }, [initialData, formFields]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (fieldName, selectedOption, isMulti) => {
    if (isMulti) {
      const values = selectedOption ? selectedOption.map((option) => option.value) : [];
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: values,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption ? selectedOption.value : null,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      let response;
      if (initialData && initialData._id) {
        // Update existing entity
        response = await fetch(`${apiEndpoint}/${initialData._id}`, {
          method: "PUT",
          headers,
          body: JSON.stringify(formData),
        });
      } else {
        // Create new entity
        response = await fetch(apiEndpoint, {
          method: "POST",
          headers,
          body: JSON.stringify(formData),
        });
      }

      if (!response.ok) {
        throw new Error(`Failed to save ${entityName}`);
      }

      customToast({
        status: "success",
        title: "Succès",
        description: typeof successMessage === "function" ? successMessage(initialData) : successMessage,
      });

      onSave(); // Callback to refresh data
      onClose();
    } catch (error) {
      console.error(`Error saving ${entityName}:`, error);
      customToast({
        status: "error",
        title: "Erreur",
        description: typeof failureMessage === "function" ? failureMessage(error) : failureMessage,
      });
    }
  };

  const getModalTitle = () => {
    if (typeof modalTitle === "function") {
      return modalTitle(initialData);
    }
    return modalTitle;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{getModalTitle()}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {formFields.map((field) => (
            <FormControl id={`${entityName}-${field.name}`} isRequired={field.isRequired} key={field.name} mt={4}>
              <FormLabel>{field.label}</FormLabel>

              {field.type === "text" && (
                <Input placeholder={field.placeholder} name={field.name} value={formData[field.name]} onChange={handleChange} type="text" />
              )}

              {field.type === "color" && (
                <Box>
                  <SketchPicker
                    color={formData[field.name]} // Set the current color
                    onChangeComplete={(color) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        [field.name]: color.hex, // Save the selected color
                      }))
                    }
                  />
                </Box>
              )}

              {field.type === "select" && (
                <Select
                  name={field.name}
                  options={field.options}
                  isMulti={field.isMulti || false}
                  value={
                    field.isMulti
                      ? field.options.filter((option) => (formData[field.name] || []).includes(option.value))
                      : field.options.find((option) => option.value === formData[field.name])
                  }
                  onChange={(selectedOption) => handleSelectChange(field.name, selectedOption, field.isMulti)}
                  placeholder={field.placeholder}
                />
              )}
            </FormControl>
          ))}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Annuler
          </Button>
          <Button colorScheme="primary" onClick={handleSubmit}>
            {initialData ? "Modifier" : "Créer"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GeneralGestionFormModal;
