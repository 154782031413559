import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Button, Image, VStack, useColorModeValue, Container, useBreakpointValue, ScaleFade } from '@chakra-ui/react';
import Logo from '../../img/LogoSquare.png';

function VerificationSuccessPage() {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  // Define color mode values
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const bgModalColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'gray.300');
  const buttonColorScheme = useColorModeValue('blue', 'blue');
  const headingSize = useBreakpointValue({ base: 'md', md: 'lg' });

  return (
    <Box
      bg={bgColor}
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={4}
    >
      <ScaleFade initialScale={0.9} in={true}>
        <Container
          maxW="md"
          centerContent
          py={8}
          borderRadius="md"
          boxShadow="2xl"
          bg={bgModalColor}
          textAlign="center"
        >
          <VStack spacing={6} align="center">
            {/* Logo at the top */}
            <Image src={Logo} alt="Logo" boxSize="100px" mb={4} />

            {/* Heading for success message */}
            <Heading as="h2" size={headingSize} color={textColor}>
              Votre compte est activé!
            </Heading>

            {/* Text for additional info */}
            <Text fontSize="md" color={textColor}>
              Votre adresse courriel a été vérifiée avec succès. Vous pouvez maintenant vous connecter.
            </Text>

            {/* Button to redirect to login page */}
            <Button colorScheme={buttonColorScheme} onClick={handleLoginRedirect}>
              Aller à la page de connexion
            </Button>
          </VStack>
        </Container>
      </ScaleFade>
    </Box>
  );
}

export default VerificationSuccessPage;
