import React from 'react';
import ModularTable from './ModularTable';
import { Wrap, WrapItem, Tag } from '@chakra-ui/react';

const FoodTable = ({ data = [], onEdit, onDelete }) => {
  const columns = [
    {
      accessor: 'name',
      label: 'Nom',
      render: (value) => <span>{value}</span>,
      defaultVisible: true,
    },
    {
      accessor: 'foodGroup',  // Make sure the accessor name matches your model
      label: 'Groupes Alimentaires',
      render: (value) => (
        <Wrap>
          {value.map((cat) => (
            <WrapItem key={cat._id}>
              <Tag
                p={1}
                pr={3}
                pl={3}
                borderRadius="full"
                colorScheme="gray"
                fontWeight="500"
                fontSize="14px"
                color="gray.600"
              >{cat.name}</Tag>
            </WrapItem>
          ))}
        </Wrap>
      ),
      defaultVisible: true,
    },
    {
      accessor: 'department',  // Updated to match your model
      label: 'Rayons Épicerie',
      render: (value) => (
        <Wrap>
          {value.map((dept) => (
            <WrapItem key={dept._id}>
              <Tag
                p={1}
                pr={3}
                pl={3}
                borderRadius="full"
                colorScheme="gray"
                fontWeight="500"
                fontSize="14px"
                color="gray.600"
              >{dept.name}</Tag>
            </WrapItem>
          ))}
        </Wrap>
      ),
      defaultVisible: true,
    },
    {
      accessor: 'category',
      label: 'Département',
      render: (value) => (
        <Wrap>
          {value.map((cat) => (
            <WrapItem key={cat._id}>
              <Tag
                p={1}
                pr={3}
                pl={3}
                borderRadius="full"
                colorScheme="gray"
                fontWeight="500"
                fontSize="14px"
                color="gray.600"
              >{cat.name}</Tag>
            </WrapItem>
          ))}
        </Wrap>
      ),
      defaultVisible: true,
    },
    {
      accessor: 'subCategory',
      label: 'Sous Catégories',
      render: (value) => (
        <Wrap>
          {value.map((sc) => (
            <WrapItem key={sc._id}>
              <Tag
                p={1}
                pr={3}
                pl={3}
                borderRadius="full"
                colorScheme="gray"
                fontWeight="500"
                fontSize="14px"
                color="gray.600"
              >{sc.name}</Tag>
            </WrapItem>
          ))}
        </Wrap>
      ),
      defaultVisible: true,
    },
    {
      accessor: 'createdAt',
      label: 'Création',
      render: (value) => (
        <Tag
          p={1}
          pr={3}
          pl={3}
          borderRadius="full"
          colorScheme="gray"
          fontWeight="500"
          fontSize="14px"
          color="gray.600"
        >{new Date(value).toLocaleDateString()}</Tag>
      ),
      defaultVisible: true,
    },
    {
      accessor: 'updatedAt',
      label: 'Révision',
      render: (value) => (
        <Tag
          p={1}
          pr={3}
          pl={3}
          borderRadius="full"
          colorScheme="gray"
          fontWeight="500"
          fontSize="14px"
          color="gray.600"
        >{new Date(value).toLocaleDateString()}</Tag>
      ),
      defaultVisible: true,
    },
  ];

  return (
    <ModularTable columns={columns} data={data} onEdit={onEdit} onDelete={onDelete} />
  );
};

export default FoodTable;
