import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Image,
  Text,
  Flex,
  Link,
  Alert,
  AlertIcon,
  VStack,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Box,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../img/LogoSquare.png';
import RegisterImage from '../../img/Register.jpg';

function RegisterPage() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
  });
  // eslint-disable-next-line no-unused-vars
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // Define color mode values
  const bgColor = useColorModeValue('gray.200', 'gray.800');
  const formBgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'white');

  // Check if the view is mobile or desktop
  const isMobile = useBreakpointValue({ base: true, md: false });
  const inputStyle = { fontSize: isMobile ? '16px' : '14px' };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNext = () => {
    let valid = true;
    if (step === 1) {
      if (!formData.email || !formData.password || !formData.confirmPassword) {
        setGeneralError('Veuillez remplir tous les champs');
        valid = false;
      }
      if (formData.password !== formData.confirmPassword) {
        setConfirmPasswordError('Les mots de passe ne correspondent pas');
        valid = false;
      }
    }
    if (valid) {
      setStep(step + 1);
      setGeneralError('');
      setPasswordError('');
      setConfirmPasswordError('');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let valid = true;
    if (
      !formData.firstName ||
      !formData.lastName
    ) {
      setGeneralError('Veuillez remplir tous les champs');
      valid = false;
    }
    if (valid) {
      try {
        const response = await fetch('http://localhost:5000/api/v1/auth/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        const data = await response.json();
        if (response.ok) {
          localStorage.setItem('token', data.token);
          setShowModal(true);
        } else {
          setGeneralError(data.message);
        }
      } catch (error) {
        setGeneralError('Erreur serveur, réessayez plus tard');
      }
    }
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/login');
  };

  return (
    <Flex align="center" justify="center" minH="100vh" bg={bgColor}>
      {isMobile ? (
        // Mobile Layout
        <VStack position="relative" w="100%" h="100vh">
          {/* Background Image */}
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            h="100vh"
            w="100%"
            css={{
              background: `url(${RegisterImage}) no-repeat center center fixed; background-size: cover;`,
            }}
          />
          {/* Foreground Content */}
          <Flex
            direction="column"
            justify="center"
            align="center"
            h="100vh"
            w="100%"
            p={4}
          >
            <Stack
              spacing={0}
              mx="auto"
              maxW="100%"
              width="100%"
              direction="column"
              bg={formBgColor}
              boxShadow="lg"
              rounded="lg"
              overflow="hidden"
              zIndex={1}
              p={6}
              py={12}
            >
              <VStack spacing={4} align="center" py={2}>
                <Image src={Logo} alt="Logo" h="80px" w="80px" />
                <Text fontSize="xl" fontWeight="bold" color={textColor}>
                  Créez votre compte
                </Text>
              </VStack>
              <Stack spacing={4} mt={6}>
                {generalError && (
                  <Alert status="warning">
                    <AlertIcon />
                    {generalError}
                  </Alert>
                )}
                <form onSubmit={handleSubmit}>
                  <Stack spacing={4}>
                    {step === 1 && (
                      <>
                        <FormControl id="email" isInvalid={emailError} mb={4}>
                          <FormLabel mb={0} fontSize="sm">
                            Courriel
                          </FormLabel>
                          <Input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Entrez votre courriel"
                            style={inputStyle}
                          />
                          {emailError && (
                            <Text color="red.500" fontSize="sm">
                              {emailError}
                            </Text>
                          )}
                        </FormControl>
                        <FormControl id="password" isInvalid={passwordError} mb={4}>
                          <FormLabel mb={0} fontSize="sm">
                            Mot de passe
                          </FormLabel>
                          <Input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Entrez votre mot de passe"
                            style={inputStyle}
                          />
                          {passwordError && (
                            <Text color="red.500" fontSize="sm">
                              {passwordError}
                            </Text>
                          )}
                        </FormControl>
                        <FormControl
                          id="confirmPassword"
                          isInvalid={confirmPasswordError}
                          mb={8}
                        >
                          <FormLabel mb={0} fontSize="sm">
                            Confirmez votre mot de passe
                          </FormLabel>
                          <Input
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirmez votre mot de passe"
                            style={inputStyle}
                          />
                          {confirmPasswordError && (
                            <Text color="red.500" fontSize="sm">
                              {confirmPasswordError}
                            </Text>
                          )}
                        </FormControl>
                        <Button
                          type="button"
                          onClick={handleNext}
                          colorScheme="primary"
                          width="full"
                          h={10}
                        >
                          Suivant
                        </Button>
                      </>
                    )}
                    {step === 2 && (
                      <>
                        <FormControl id="firstName" mb={4}>
                          <FormLabel mb={0} fontSize="sm">
                            Prénom
                          </FormLabel>
                          <Input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder="Entrez votre prénom"
                            style={inputStyle}
                          />
                        </FormControl>
                        <FormControl id="lastName" mb={4}>
                          <FormLabel mb={0} fontSize="sm">
                            Nom
                          </FormLabel>
                          <Input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            placeholder="Entrez votre nom"
                            style={inputStyle}
                          />
                        </FormControl>
                        <Button
                          type="submit"
                          isLoading={isLoading}
                          loadingText="Inscription en cours"
                          colorScheme="primary"
                          width="full"
                          h={10}
                        >
                          S'inscrire
                        </Button>
                      </>
                    )}
                  </Stack>
                </form>
              </Stack>
              <VStack spacing={4} mt={6} py={2}>
                <Text textAlign="center" fontSize="md">
                  Vous avez déjà un compte?{' '}
                  <Link href="/login" color="primary.500">
                    Se connecter
                  </Link>
                </Text>
              </VStack>
            </Stack>
          </Flex>
        </VStack>
      ) : (
        // Desktop Layout
        <VStack>
          <Stack
            spacing={0}
            mx="auto"
            maxW={{ md: '80%', lg: '60%' }}
            width="100%"
            direction="row"
            bg={formBgColor}
            boxShadow="lg"
            rounded="3xl"
            overflow="hidden"
          >
            <Flex
              flex={1}
              direction="column"
              justify="space-around"
              p={12}
              pt={8}
              bg={formBgColor}
              h="70vh"
            >
              <VStack spacing={4} align="center">
                <Image src={Logo} alt="Logo" h="120px" w="120px" />
                <Text fontSize="2xl" fontWeight="bold" color={textColor}>
                  Créez votre compte
                </Text>
              </VStack>
              <Stack spacing={4} mt={2}>
                {generalError && (
                  <Alert status="warning">
                    <AlertIcon />
                    {generalError}
                  </Alert>
                )}
                <form onSubmit={handleSubmit}>
                  <Stack spacing={4}>
                    {step === 1 && (
                      <>
                        <FormControl id="email" isInvalid={emailError} mb={4}>
                          <FormLabel mb={1}>Courriel</FormLabel>
                          <Input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Entrez votre courriel"
                            style={inputStyle}
                          />
                          {emailError && (
                            <Text color="red.500" fontSize="sm">
                              {emailError}
                            </Text>
                          )}
                        </FormControl>
                        <FormControl id="password" isInvalid={passwordError} mb={4}>
                          <FormLabel mb={1}>Mot de passe</FormLabel>
                          <Input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Entrez votre mot de passe"
                            style={inputStyle}
                          />
                          {passwordError && (
                            <Text color="red.500" fontSize="sm">
                              {passwordError}
                            </Text>
                          )}
                        </FormControl>
                        <FormControl
                          id="confirmPassword"
                          isInvalid={confirmPasswordError}
                          mb={8}
                        >
                          <FormLabel mb={1} >Confirmez votre mot de passe</FormLabel>
                          <Input
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirmez votre mot de passe"
                            style={inputStyle}
                          />
                          {confirmPasswordError && (
                            <Text color="red.500" fontSize="sm">
                              {confirmPasswordError}
                            </Text>
                          )}
                        </FormControl>
                        <Button
                          type="button"
                          onClick={handleNext}
                          colorScheme="primary"
                          width="full"
                          h={12}
                          fontSize="14px"
                          fontWeight="500"
                          borderRadius="xl"
                        >
                          Suivant
                        </Button>
                      </>
                    )}
                    {step === 2 && (
                      <>
                        <FormControl id="firstName" mb={4} isRequired>
                          <FormLabel mb={1}>Prénom</FormLabel>
                          <Input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder="Entrez votre prénom"
                            style={inputStyle}
                          />
                        </FormControl>
                        <FormControl id="lastName" mb={4} isRequired>
                          <FormLabel mb={1}>Nom</FormLabel>
                          <Input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            placeholder="Entrez votre nom"
                            style={inputStyle}
                          />
                        </FormControl>
                        <Button
                          type="submit"
                          isLoading={isLoading}
                          loadingText="Inscription en cours"
                          colorScheme="primary"
                          width="full"
                          h={12}
                          fontSize="14px"
                          fontWeight="500"
                          borderRadius="xl"
                        >
                          S'inscrire
                        </Button>
                      </>
                    )}
                  </Stack>
                </form>
              </Stack>
              <VStack spacing={4} mt={6}>
                <Text textAlign="center" fontSize="md">
                  Vous avez déjà un compte?{' '}
                  <Link href="/login" color="primary.500">
                    Se connecter
                  </Link>
                </Text>
              </VStack>
            </Flex>
            {/* Side Image */}
            <Flex flex={1} display={{ base: 'none', md: 'flex' }}>
              <Image
                alt="Register Image"
                objectFit="cover"
                src={RegisterImage}
                height="100%"
                width="100%"
              />
            </Flex>
          </Stack>
          <Text fontSize="sm" color="gray.500" mt={10}>
            © 2024 Pense et cuisine. Tous droits réservés.
          </Text>
        </VStack>
      )}
      {/* Registration Success Modal */}
      <Modal isOpen={showModal} onClose={handleCloseModal} isCentered>
        <ModalOverlay />
        <ModalContent bg={formBgColor}>
          <ModalHeader>Succès</ModalHeader>
          <ModalBody>
            <Text color={textColor}>
              Inscription réussie! Un lien de vérification a été envoyé à votre adresse courriel. Le lien expire dans 15 minutes.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCloseModal} colorScheme="primary">
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default RegisterPage;
