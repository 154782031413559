// TableConfig.js
import { Tag, Wrap, WrapItem } from "@chakra-ui/react";

export const commonColumns = [
  {
    accessor: "name",
    label: "Nom",
    render: (value) => <span>{value}</span>,
    defaultVisible: true,
  },
  {
    accessor: "createdAt",
    label: "Date de création",
    render: (value) => (
      <Tag p={1} pr={3} pl={3} borderRadius="full" colorScheme="gray" fontWeight="500" fontSize="14px" color="gray.600">
        {new Date(value).toLocaleDateString()}
      </Tag>
    ),
    defaultVisible: true,
  },
  {
    accessor: "updatedAt",
    label: "Date de mise à jour",
    render: (value) => (
      <Tag p={1} pr={3} pl={3} borderRadius="full" colorScheme="gray" fontWeight="500" fontSize="14px" color="gray.600">
        {new Date(value).toLocaleDateString()}
      </Tag>
    ),
    defaultVisible: true,
  },
];

const foodTableColumns = [
  ...commonColumns,
  {
    accessor: "foodGroup",
    label: "Groupes Alimentaires",
    render: (value) => (
      <Wrap>
        {value.map((cat) => (
          <WrapItem key={cat._id}>
            <Tag p={1} pr={3} pl={3} borderRadius="full" colorScheme="gray" fontWeight="500" fontSize="14px" color="gray.600">
              {cat.name}
            </Tag>
          </WrapItem>
        ))}
      </Wrap>
    ),
    defaultVisible: true,
  },
  {
    accessor: "department",
    label: "Départements",
    render: (value) => (
      <Wrap>
        {value.map((dept) => (
          <WrapItem key={dept._id}>
            <Tag p={1} pr={3} pl={3} borderRadius="full" colorScheme="gray" fontWeight="500" fontSize="14px" color="gray.600">
              {dept.name}
            </Tag>
          </WrapItem>
        ))}
      </Wrap>
    ),
    defaultVisible: true,
  },
  {
    accessor: "category",
    label: "Catégories",
    render: (value) => (
      <Wrap>
        {value.map((sc) => (
          <WrapItem key={sc._id}>
            <Tag p={1} pr={3} pl={3} borderRadius="full" colorScheme="gray" fontWeight="500" fontSize="14px" color="gray.600">
              {sc.name}
            </Tag>
          </WrapItem>
        ))}
      </Wrap>
    ),
    defaultVisible: true,
  },
  {
    accessor: "subCategory",
    label: "Sous Catégories",
    render: (value) => (
      <Wrap>
        {value.map((sc) => (
          <WrapItem key={sc._id}>
            <Tag p={1} pr={3} pl={3} borderRadius="full" colorScheme="gray" fontWeight="500" fontSize="14px" color="gray.600">
              {sc.name}
            </Tag>
          </WrapItem>
        ))}
      </Wrap>
    ),
    defaultVisible: true,
  },
];

export const foodGroupTableColumns = [
    ...commonColumns,
    {
        accessor: "color",
        label: "Couleur",
        render: (value) => (
            <Wrap>
                {Array.isArray(value) ? (
                    value.map((fg) => (
                        <WrapItem key={fg._id}>
                            <Tag p={1} pr={3} pl={3} borderRadius="full" fontWeight="500" fontSize="14px" bg={fg.color || 'gray.300'} color='white'>
                                {fg.color || "N/A"} 
                            </Tag>
                        </WrapItem>
                    ))
                ) : (
                    <WrapItem>
                        <Tag p={1} pr={3} pl={3} borderRadius="full" fontWeight="500" fontSize="14px" bg={value || "gray"} color='black'>
                            {value || "N/A"}
                        </Tag>
                    </WrapItem>
                )}
            </Wrap>
        ),
        defaultVisible: true,
    },
];

export const tableConfigs = {
  CultureTable: {
    columns: commonColumns,
  },
  FoodTable: {
    columns: foodTableColumns,
  },
  CuisineTypeTable: {
    columns: commonColumns,
  },
  CategoryTable: {
    columns: commonColumns.map((col) => ({
      ...col,
      label: col.accessor === "name" ? "Nom de la catégorie" : col.label,
    })),
  },
  IsleTable: {
    columns: commonColumns,
  },
  SousCategorieTable: {
    columns: commonColumns,
  },
  FoodGroupTable: {
    columns: foodGroupTableColumns,
  },
};
