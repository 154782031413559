import React from 'react';
import GeneralGestionFormModal from './GeneralGestionFormModal';

const SubCategoryFormModal = ({ isOpen, onClose, onSave, initialData }) => {
    const entityName = 'Sous-catégorie';
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const apiEndpoint = `${apiUrl}/api/v1/blueprint/sub-category`;

    const formFields = [
        {
            name: 'name',
            label: 'Nom de la sous-catégorie',
            placeholder: 'Nom de la sous-catégorie...',
            isRequired: true,
            type: 'text',
        },
    ];

    const modalTitle = (data) => (data ? 'Modifier la sous-catégorie' : 'Ajouter une nouvelle sous-catégorie');

    const successMessage = (data) =>
        data
            ? 'La sous-catégorie a été mise à jour avec succès.'
            : 'La sous-catégorie a été créée avec succès.';

    const failureMessage = 'Une erreur est survenue. Veuillez réessayer plus tard.';

    return (
        <GeneralGestionFormModal
            isOpen={isOpen}
            onClose={onClose}
            onSave={onSave}
            initialData={initialData}
            entityName={entityName}
            apiEndpoint={apiEndpoint}
            formFields={formFields}
            modalTitle={modalTitle}
            successMessage={successMessage}
            failureMessage={failureMessage}
        />
    );
};

export default SubCategoryFormModal;
