import React from 'react';
import ModularTable from './ModularTable';
import { commonColumns } from './TableConfig';

const DepartmentTable = ({ data, onEdit, onDelete }) => {
  const columns = [
    ...commonColumns,
  ];

  return (
    <ModularTable columns={columns} data={data} onEdit={onEdit} onDelete={onDelete} />
  );
};

export default DepartmentTable;