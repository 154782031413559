// CommunityRecipe.js
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Flex,
  Box,
  Text,
  HStack,
  Spinner,
  useBreakpointValue,
  useColorModeValue,
  Center,
  Input,
  InputGroup,
  InputLeftElement,
  Circle,
} from "@chakra-ui/react";
import CommunityRecipeGridItem from "./CommunityRecipeGridItem";
import { FiSearch } from "react-icons/fi";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import useCustomToast from "../5 - General/Utils/UtilsNotification";
import RecipeDetailDrawer from "../3 - Modal/7 - Community Recipe Modals/RecipeDetailDrawer";
import CommunityRecipeAnnouncementCard from "./CommunityRecipeAnnouncementCard";

const CommunityRecipe = () => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [recipes, setCommunityRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const searchInputRef = useRef(null);
  const [dishTypes, setDishTypes] = useState([]);
  const [selectedDishType, setSelectedDishType] = useState({ value: "all", label: "Voir tout" });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const customToast = useCustomToast();
  const inputStyle = { fontSize: isMobile ? "16px" : "14px" };

  // Define theme-dependent values at the top
  const containerBg = useColorModeValue("white", "gray.800");
  const bgBottomCardColor = useColorModeValue("gray.100", "gray.700");
  const bgSearchBarColor = useColorModeValue("white", "gray.700");
  const textColorActive = useColorModeValue("black", "white");
  const textColorInactive = useColorModeValue("gray.700", "gray.300");
  const primary500 = useColorModeValue("primary.500", "primary.200");
  const topBarBgColor = useColorModeValue("primary.800", "gray.800");
  const topBarTextColor = useColorModeValue("white", "white");

  useEffect(() => {
    const fetchCommunityRecipes = async () => {
      const role = localStorage.getItem("role");
      const token = localStorage.getItem("token");
      // Check if user is an admin
      if (role === "Admin") {
        setIsAdmin(true);
      }

      try {
        const response = await fetch(`${apiUrl}/api/v1/recipe/recipe`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching recipes: ${response.statusText}`);
        }

        const data = await response.json();
        setCommunityRecipes(data);
      } catch (error) {
        console.error("Error fetching recipes:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCuisineTypes = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/v1/blueprint/dish-type`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`Error fetching dish-types: ${response.statusText}`);
        }
        const data = await response.json();
        const formattedData = data.map((cuisine) => ({
          value: cuisine._id,
          label: cuisine.name,
        }));
        setDishTypes([{ value: "all", label: "Voir tout" }, ...formattedData]);
      } catch (error) {
        console.error("Error fetching dish-types:", error);
        setDishTypes([{ value: "all", label: "Voir tout" }]);
      }
    };

    fetchCommunityRecipes();
    fetchCuisineTypes();
  }, [apiUrl]);

  // Handle selecting a dish type
  const handleSelectDishType = (dishType) => {
    setSelectedDishType(dishType);
  };

  if (loading) {
    return (
      <Center minHeight="100vh">
        <Spinner size="xl" colorScheme="primary" />
      </Center>
    );
  }

  // Filter recipes based on selected dish type
  const filteredCommunityRecipes = recipes.filter((recipe) => {
    const matchesDishType = selectedDishType.value === "all" || recipe.dishType.some((dt) => dt._id === selectedDishType.value);
    return matchesDishType;
  });

  const handleRecipeClick = (recipe) => {
    setSelectedRecipe(recipe);
    setIsDetailOpen(true);
  };

  const handleDetailClose = () => {
    setIsDetailOpen(false);
    setSelectedRecipe(null);
  };

  return (
    <Flex direction="column" h="100vh" w="100%" bg={topBarBgColor}>
      {/* Topbar */}
      <Flex
        w="100%"
        align="center"
        justify="space-between"
        flexDir="column"
        pt={6}
        pb={2}
        px={4}
        bg={topBarBgColor}
        position="sticky"
        top="0"
        zIndex="1000"
      >
        <HStack spacing={3} display="flex" w="100%" justifyContent="center">
          <Text fontSize="2xl" fontWeight="bold" textAlign="center" color={topBarTextColor}>
            Explorez des recettes <br />
            conçues par la communautée!
          </Text>
        </HStack>
        <Box w="100%" mt={4}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <FiSearch color="gray.300" />
            </InputLeftElement>
            <Input
              style={inputStyle}
              ref={searchInputRef}
              placeholder="Rechercher une recette..."
              bg={bgSearchBarColor}
              aria-label="Search Recipes"
              onChange={(e) => console.log("Search:", e.target.value)}
            />
          </InputGroup>
        </Box>
      </Flex>
      <Box maxW="1200px" w="100%" mt={4} pt={4} bg={containerBg} borderRadius="40px 40px 0 0" h="100%">
        {/* Annoucement for recipes card */}
        <CommunityRecipeAnnouncementCard isAdmin={isAdmin} isMobile={isMobile} />
        {/* Dish Types Section */}
        <Box w="100%" mt={6} mb={6} pl={6}>
          <ScrollMenu>
            <HStack spacing={6} pb={2}>
              {dishTypes.map((dishType) => (
                <Box
                  key={dishType.value}
                  onClick={() => handleSelectDishType(dishType)}
                  cursor="pointer"
                  position="relative"
                  whiteSpace="nowrap"
                  maxWidth="40ch"
                >
                  <Text
                    fontSize="md"
                    color={selectedDishType?.value === dishType.value ? textColorActive : textColorInactive}
                    fontWeight={selectedDishType?.value === dishType.value ? "bold" : "normal"}
                    isTruncated
                    title={dishType.label}
                    pb={1}
                  >
                    {dishType.label}
                  </Text>
                  {selectedDishType?.value === dishType.value && (
                    <Circle size="8px" bg={primary500} position="absolute" bottom="-6px" left="50%" transform="translateX(-50%)" />
                  )}
                </Box>
              ))}
            </HStack>
          </ScrollMenu>
        </Box>

        {/* Recipes Section */}
        <Box w="100%" px={6}>
          <ScrollMenu>
            <HStack spacing={4}>
              {filteredCommunityRecipes.map((recipe) => (
                <Box key={recipe._id} onClick={() => handleRecipeClick(recipe)}>
                  <CommunityRecipeGridItem recipe={recipe} />
                </Box>
              ))}
            </HStack>
          </ScrollMenu>
        </Box>

        {/* "Don't see what you're looking for?" Prompt */}
        <Box w="100%" py={2} pb="150px" mt={4} px={6}>
          <Box borderWidth="1px" borderRadius="20px" p={6} bg={bgBottomCardColor} boxShadow="lg" textAlign="center">
            <Text fontSize="lg" fontWeight="bold" mb={3}>
              Vous ne trouvez pas ce que vous recherchez?
            </Text>
            <Text mb={4}>Si vous ne trouvez pas la recette que vous recherchez, vous pouvez la créer vous-même!</Text>
            <Button colorScheme="primary" size="lg" borderRadius="full" onClick={() => (window.location.href = "/blueprints")}>
              Créer une recette
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Recipe Detail Drawer */}
      {selectedRecipe && (
        <RecipeDetailDrawer
          isOpen={isDetailOpen}
          onClose={handleDetailClose}
          recipe={selectedRecipe}
          onAdd={() => customToast({ status: "success", title: "Cette fonction n'est pas encore implémentée" })}
        />
      )}
    </Flex>
  );
};

export default CommunityRecipe;
