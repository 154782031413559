import React, { useEffect, useState, useRef, Suspense, lazy } from "react";
import {
  Button,
  Flex,
  Box,
  Text,
  HStack,
  Spinner,
  useBreakpointValue,
  useColorModeValue,
  Center,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { LayoutGroup, motion } from "framer-motion";
import BlueprintGridItem from "./BlueprintGridItem";
import { BlueprintCreateProvider, useBlueprintCreate } from "../3 - Modal/3 - Blueprints Modals/BlueprintCreateContext";
import steps from "../3 - Modal/3 - Blueprints Modals/BlueprintCreateSteps";
import { FiSearch, FiMapPin } from "react-icons/fi";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { Grid } from "@chakra-ui/react";
import { pSBC } from "../5 - General/Utils/UtilsColorPSBC";
import { AiFillStar } from "react-icons/ai";
import { isColorLight } from "../5 - General/Utils/UtilsTextContrast";
import { FaPlus } from "react-icons/fa";

const BlueprintCreateModal = lazy(() => import("../3 - Modal/3 - Blueprints Modals/BlueprintCreateModal"));
const FeedbackModal = lazy(() => import("../3 - Modal/5 - Feedback Modals/FeedbackModal"));
const SearchDrawer = lazy(() => import("../3 - Modal/3 - Blueprints Modals/BlueprintSearchDrawer"));

const Blueprints = () => (
  <BlueprintCreateProvider steps={steps}>
    <BlueprintsContent />
  </BlueprintCreateProvider>
);

const BlueprintsContent = () => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const { openWizard } = useBlueprintCreate();
  const [blueprints, setBlueprints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const searchInputRef = useRef(null);
  const [dishTypes, setDishTypes] = useState([]);
  const [selectedDishType, setSelectedDishType] = useState(null);
  const scrollMenuRef = useRef(null);
  const colorMapRef = useRef({});
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Define theme-dependent values at the top
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.800", "white");
  const containerBg = useColorModeValue("white", "gray.800");
  const bgBottomCardColor = useColorModeValue("gray.100", "gray.700");
  const bgSearchBarColor = useColorModeValue("white", "gray.700");
  const blueprintSectionBgColor = useColorModeValue("gray.50", "gray.800");

  useEffect(() => {
    const fetchBlueprints = async () => {
      const role = localStorage.getItem("role");
      const token = localStorage.getItem("token");
      // Check if user is an admin
      if (role === "Admin") {
        setIsAdmin(true);
      }

      try {
        const response = await fetch(`${apiUrl}/api/v1/blueprint/blueprint`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching blueprints: ${response.statusText}`);
        }

        const data = await response.json();
        setBlueprints(data);
      } catch (error) {
        console.error("Error fetching blueprints:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCuisineTypes = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/v1/blueprint/dish-type`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`Error fetching dish-types: ${response.statusText}`);
        }
        const data = await response.json();
        const formattedData = data.map((cuisine) => ({
          value: cuisine.id,
          label: cuisine.name,
        }));
        setDishTypes(formattedData);
      } catch (error) {
        console.error("Error fetching dish-types:", error);
        setDishTypes([]);
      }
    };

    fetchBlueprints();
    fetchCuisineTypes();
  }, [apiUrl]);

  const removeBlueprint = (id) => {
    setBlueprints((prevBlueprints) => prevBlueprints.filter((blueprint) => blueprint._id !== id));
  };

  const handleClose = () => {
    onClose();
    if (searchInputRef.current) {
      searchInputRef.current.blur();
    }
  };

  // Scroll smoothly when dishType is selected
  const handleSelectDishType = (dishType) => {
    setSelectedDishType(dishType);
    if (scrollMenuRef.current) {
      scrollMenuRef.current.scrollTo(0, 0, { behavior: "smooth" });
    }
  };

  const getRandomColorAndApplyFilter = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Generate color once and store it
  const getColorForDishType = (dishTypeValue, index) => {
    const uniqueKey = `${dishTypeValue}-${index}`;
    if (!colorMapRef.current[uniqueKey]) {
      const randomColor = getRandomColorAndApplyFilter();
      colorMapRef.current[uniqueKey] = randomColor;
    }
    return colorMapRef.current[uniqueKey];
  };

  if (loading) {
    return (
      <Center minHeight="100vh">
        <Spinner size="xl" colorScheme="primary" />
      </Center>
    );
  }

  const filteredBlueprints = blueprints.filter((blueprint) => {
    const matchesSearch = blueprint.name.toLowerCase().includes(selectedDishType?.label.toLowerCase() || "");
    const matchesDishType = selectedDishType ? blueprint.dishType === selectedDishType.value : true;
    return matchesSearch && matchesDishType;
  });

  const firstRowItems = dishTypes.slice(Math.ceil(dishTypes.length / 2));
  const secondRowItems = dishTypes.slice(0, Math.ceil(dishTypes.length / 2));

  return (
    <Flex direction="column" h="100vh" w="100%" bg={bgColor}>
      <Box maxW="1200px" w="100%" bg={containerBg}>
        {/* Topbar */}
        <Topbar isAdmin={isAdmin} isMobile={isMobile} openWizard={openWizard} />
        {/* Search Bar */}
        <Box w="100%" mt={4} px={6}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <FiSearch color="gray.300" />
            </InputLeftElement>
            <Input
              ref={searchInputRef}
              placeholder="Rechercher un modèle..."
              bg={bgSearchBarColor}
              onClick={onOpen}
              readOnly
              aria-label="Search Blueprints"
            />
          </InputGroup>
        </Box>

        {/* Search Drawer */}
        <Suspense fallback={<Spinner />}>
          <SearchDrawer isOpen={isOpen} onClose={handleClose} blueprints={blueprints} />
        </Suspense>

        {/* Dish Types Section */}
        <Box w="100%" mt={6} pl={6}>
          <Text fontWeight="bold" fontSize="2xl" mb={4} color={textColor}>
            Filtrez par catégorie :
          </Text>
          <ScrollMenu ref={scrollMenuRef}>
            <Box display="flex" flexDirection="column" w="100%">
              {/* First row */}
              <Box display="flex" mb={1}>
                {firstRowItems.map((dishType, index) => (
                  <DishTypeButton
                    key={dishType.value}
                    dishType={dishType}
                    index={index}
                    onSelect={handleSelectDishType}
                    isSelected={selectedDishType?.value === dishType.value}
                    getColorForDishType={getColorForDishType}
                  />
                ))}
              </Box>

              {/* Second row */}
              <Box display="flex">
                {secondRowItems.map((dishType, index) => (
                  <DishTypeButton
                    key={dishType.value}
                    dishType={dishType}
                    index={index + 100}
                    onSelect={handleSelectDishType}
                    isSelected={selectedDishType?.value === dishType.value}
                    getColorForDishType={getColorForDishType}
                  />
                ))}
              </Box>
            </Box>
          </ScrollMenu>
        </Box>

        <Box w="100%" my={4} py={6} borderRadius='50px 50px 0 0' px={6} bg={blueprintSectionBgColor}>
         {/* <Box w="100%">
            <Text fontWeight="bold" fontSize="2xl" color={textColor}>
              {selectedDishType ? `Blueprints: ${selectedDishType.label}` : "Tout les modèles :"}
            </Text>
          </Box>*/}

          {/* Blueprint Grid */}
          <LayoutGroup>
            <Box width="100%" flex="1" overflowY="auto">
              <motion.div layout>
                <Grid
                  templateColumns={{
                    base: "repeat(2, 1fr)",
                    sm: "repeat(3, 1fr)",
                    md: "repeat(4, 1fr)",
                    lg: "repeat(5, 1fr)",
                  }}
                  gap={2}
                  position="relative"
                  autoFlow="dense"
                >
                  {filteredBlueprints.map((blueprint) => (
                    <BlueprintGridItem
                      key={blueprint._id}
                      blueprint={blueprint}
                      isSelected={selectedId === blueprint._id}
                      onClick={() => setSelectedId(selectedId === blueprint._id ? null : blueprint._id)}
                      onClose={() => setSelectedId(null)}
                      onDelete={removeBlueprint}
                      isAdmin={isAdmin}
                    />
                  ))}
                </Grid>
                <Box w="100%" py={2} pb="100px">
                  <Box borderWidth="1px" borderRadius="20px" p={6} bg={bgBottomCardColor} boxShadow="lg" textAlign="center">
                    <Text fontSize="lg" fontWeight="bold" mb={3}>
                      Vous ne trouvez pas ce que vous recherchez?
                    </Text>
                    <Text mb={4}>Si vous ne trouvez pas le modèle que vous recherchez, vous pouvez nous le suggérer.</Text>
                    <Suspense fallback={<Spinner />}>
                      <FeedbackModal />
                    </Suspense>
                  </Box>
                </Box>
              </motion.div>
            </Box>
          </LayoutGroup>
        </Box>
      </Box>

      {/* Blueprint Create Modal */}
      <Suspense fallback={<Spinner />}>
        <BlueprintCreateModal />
      </Suspense>
    </Flex>
  );
};

const DishTypeButton = ({ dishType, index, onSelect, isSelected, getColorForDishType }) => {
  // Define theme-dependent values at the top
  const backgroundColor = getColorForDishType(dishType.value, index);
  const textColor = isColorLight(backgroundColor) ? "black" : "white";
  const backgroundHoverColor = pSBC(-0.3, backgroundColor);

  return (
    <Box mr={isSelected ? 2 : 2} mb={2}>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => onSelect(dishType)}
      >
        <Button
          size="md"
          fontSize="sm"
          fontWeight="medium"
          borderRadius="full"
          leftIcon={isSelected ? <AiFillStar /> : <FiMapPin />}
          bg={backgroundColor}
          color={textColor}
          variant="solid"
          _hover={{ bg: backgroundHoverColor }}
          transition="all 0.2s"
        >
          {dishType.label}
        </Button>
      </motion.button>
    </Box>
  );
};

const Topbar = ({ isAdmin, isMobile, openWizard }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const hoverBg = pSBC(-0.1, "primary.500");

  return (
    <Flex
      w="100%"
      align="center"
      justify="space-between"
      pt={6}
      pb={2}
      px={6}
      bg={bgColor}
      position="sticky"
      top="0"
      zIndex="1000"
      borderRadius="0 0 20px 20px"
    >
      <HStack spacing={3} display="flex" w="100%" justifyContent="center">
        <Text fontSize="2xl" fontWeight="bold" color={textColor}>
          Créez votre propre recette!
        </Text>
      </HStack>
      <HStack spacing={2}>
        {isAdmin && !isMobile && (
          <IconButton
            colorScheme="primary"
            icon={<FaPlus />}
            onClick={openWizard}
            aria-label="Add Blueprint"
            size="lg"
            variant="solid"
            borderRadius="full"
            _hover={{ bg: hoverBg }}
            transition="background-color 0.2s"
          />
        )}
      </HStack>
    </Flex>
  );
};

export default Blueprints;
