import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Text,
    Box,
    Select,
    useDisclosure,
    UnorderedList,
    ListItem,
    Code,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import FilePreview from '../../5 - General/Utils/UtilsFilePreview';
import useCustomToast from '../../5 - General/Utils/UtilsNotification';

const CsvUploadModal = ({ onSuccess }) => {
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [csvData, setCsvData] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const customToast = useCustomToast();

    const categories = [
        { _id: 'food', name: 'Aliments' },
        { _id: 'food-group', name: 'Groupes Alimentaires' },
        { _id: 'department', name: 'Départements' },
        { _id: 'category', name: 'Catégories' },
        { _id: 'sub-category', name: 'Sous-catégories' },
        { _id: 'dish-type', name: 'Types de plats' },
        { _id: 'dish-component', name: 'Composants de plats' },
    ];

    // CSV format definitions
    const csvFormats = {
        food: {
            instructions: 'Le fichier doit contenir les colonnes suivantes :',
            columns: [
                'Nom',
                'Groupe Alimentaire',
                'Département',
                'Sous-catégories',
                'Catégories',
            ],
        },
    };

    // Mapping between CSV column names and backend field names
    const fieldMapping = {
        'Nom': 'Name',
        'Groupe Alimentaire': 'food-group',
        'Département': 'department',
        'Catégories': 'category',
        'Sous-catégories': 'sub-category',
    };

    const handleDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];

            setSelectedFile({
                name: file.path || file.name,
                size: file.size || 0,
                preview: URL.createObjectURL(file),
            });

            // Parse the CSV file
            Papa.parse(file, {
                header: true, // Use the first row as headers
                skipEmptyLines: true,
                complete: (result) => {
                    // Map the field names to match backend expectations
                    const mappedData = result.data.map((row) => {
                        const mappedRow = {};
                        for (const [key, value] of Object.entries(row)) {
                            const newKey = fieldMapping[key] || key;
                            mappedRow[newKey] = value;
                        }
                        return mappedRow;
                    });
                    setCsvData(mappedData);
                },
                error: (error) => {
                    console.error('Error parsing CSV:', error);
                    customToast('error', 'Erreur', 'Problème lors de la lecture du fichier CSV.');
                },
            });
        }
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
        setCsvData(null);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleDrop,
        accept: {
            'text/csv': ['.csv'],
        },
        maxFiles: 1,
    });

    useEffect(() => {
        return () => {
            if (selectedFile?.preview) {
                URL.revokeObjectURL(selectedFile.preview);
            }
        };
    }, [selectedFile]);

    const handleUpload = async () => {
        if (!selectedCategory) {
            customToast('error', 'Erreur', 'Veuillez sélectionner une catégorie.');
            return;
        }

        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiUrl}/api/v1/blueprint/import-csv`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ categoryName: selectedCategory, csvData }),
            });

            const result = await response.json();

            if (response.ok) {
                const { successfulCount, failedCount } = result;
                let description = `${successfulCount} ligne(s) importée(s) avec succès.`;
                if (failedCount > 0) {
                    description += ` ${failedCount} ligne(s) échouée(s).`;
                }

                customToast(
                    failedCount > 0 ? 'warning' : 'success',
                    'Import terminé',
                    description
                );

                onSuccess();
                handleModalClose();
            } else {
                customToast('error', 'Erreur', result.message || 'Échec de l\'importation.');
            }
        } catch (error) {
            customToast('error', 'Erreur', 'Problème de communication avec le serveur.');
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setSelectedFile(null);
        setSelectedCategory('');
        setCsvData(null);
    };

    const handleModalClose = () => {
        resetForm();
        onClose();
    };

    return (
        <>
            <Button variant="outline" colorScheme="gray" onClick={onOpen}>
                Importer CSV
            </Button>

            <Modal isOpen={isOpen} onClose={handleModalClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Importer un fichier CSV</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb={4}>Veuillez importer un fichier CSV avec le format suivant :</Text>

                        {selectedCategory && csvFormats[selectedCategory] && (
                            <Box bg="gray.100" p={4} mb={4} borderRadius="md">
                                <Text fontWeight="bold" mb={2}>
                                    {csvFormats[selectedCategory]?.instructions}
                                </Text>
                                <UnorderedList mb={4}>
                                    {csvFormats[selectedCategory]?.columns.map((col, index) => (
                                        <ListItem key={index}>
                                            <Code>{col}</Code>
                                        </ListItem>
                                    ))}
                                </UnorderedList>
                            </Box>
                        )}
                        <Select
                            placeholder="Sélectionnez une catégorie"
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            mb={4}
                            value={selectedCategory}
                        >
                            {categories.map((category) => (
                                <option key={category._id} value={category._id}>
                                    {category.name}
                                </option>
                            ))}
                        </Select>

                        {selectedFile ? (
                            <FilePreview file={selectedFile} handleRemoveFile={handleRemoveFile} />
                        ) : (
                            <Box
                                {...getRootProps()}
                                p={8}
                                border="2px dashed gray"
                                borderRadius="md"
                                textAlign="center"
                                cursor="pointer"
                                bg="gray.50"
                            >
                                <input {...getInputProps()} />
                                <Text>
                                    Glissez-déposez un fichier ici, ou cliquez pour sélectionner un fichier
                                </Text>
                            </Box>
                        )}
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={handleUpload}
                            isLoading={loading}
                            isDisabled={!csvData || !selectedCategory}
                            colorScheme="primary"
                            mr={3}
                        >
                            Importer
                        </Button>
                        <Button variant="outline" colorScheme="gray" onClick={handleModalClose}>
                            Annuler
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CsvUploadModal;
