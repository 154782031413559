import React, { useState, memo, useMemo, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  Icon,
  Text,
  VStack,
  HStack,
  IconButton,
  Link,
  Tooltip,
  Avatar,
  AvatarBadge,
  useColorMode,
  useColorModeValue,
  Badge,
  Switch,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { LuLayoutDashboard, LuFileSignature, LuListTodo, LuCalendarDays } from 'react-icons/lu';
import { CiLogout } from 'react-icons/ci';
import { BsLayoutSidebarInset, BsLayoutSidebarInsetReverse } from 'react-icons/bs';
import { HiChevronUpDown } from 'react-icons/hi2';
import { useProfilePictures } from '../Utils/UtilsProfilePictures';

const SideBarLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { toggleColorMode } = useColorMode();
  const { profilePictures, fetchProfilePicture } = useProfilePictures();
  const userId = localStorage.getItem('userId');

  const sidebarBgColor = useColorModeValue('secondary.100', 'gray.900');
  const textColor = useColorModeValue('gray.900', 'gray.200');
  const profileNameColor = useColorModeValue('gray.700', 'gray.300');
  const collapseIconColor = useColorModeValue('gray.400', 'gray.400');
  const borderColor = useColorModeValue('secondary.200', 'gray.700');
  const textCategoryColor = useColorModeValue('gray.500', 'gray.600');
  const avatarBg = useColorModeValue('primary.600', 'primary.700');
  const mainBgColor = useColorModeValue("secondary.50", "gray.800");

  useEffect(() => {
    fetchProfilePicture(userId);
  }, [fetchProfilePicture, userId]);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const profilePictureSrc = profilePictures[userId] === 'NOT_FOUND' ? '/path-to-default-avatar.png' : profilePictures[userId];

  const profileSection = useMemo(() => (
    <Menu>
      <MenuButton as={Flex} alignItems="center" cursor="pointer" variant="ghost" p={1} borderRadius="md">
        <HStack spacing={3}>
          <Avatar
            name="Fabien Gaudreau"
            src={profilePictureSrc}
            alt="Profile"
            boxSize="40px"
            borderRadius="50%"
            bg={avatarBg}
          >
            <AvatarBadge boxSize="16px" bg="green.500" />
          </Avatar>
          {!collapsed && <VStack align="flex-start" spacing="1px">
            <Text fontSize="md" fontWeight="bold" color={textColor}>
              Fabien Gaudreau
            </Text>
            <Text fontSize="sm" color={profileNameColor}>Programmeur</Text>
          </VStack>}
          {!collapsed && <HiChevronUpDown size="25px" />}
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItem as={RouterLink} to="/logout" icon={<CiLogout />} fontSize='md'>Déconnexion</MenuItem>
        <MenuItem as={RouterLink} to="/dashboard/settings" fontSize='md'>Réglages du profil</MenuItem>
        <MenuItem fontSize='md'>Dark/Light mode  <Switch ml={2} onChange={toggleColorMode}/></MenuItem>
      </MenuList>
    </Menu>
  ), [textColor, profileNameColor, toggleColorMode, collapsed, avatarBg, profilePictureSrc]);

  return (
    <Flex height="100vh">
      <Box
        as="aside"
        w={collapsed ? '90px' : '250px'}
        bg={sidebarBgColor}
        display="flex"
        flexDirection="column"
        position="fixed"
        top="0"
        bottom="0"
        zIndex="100"
        transition="width 0.3s ease"
        overflowX="hidden"
        borderRight="1px solid"
        borderColor={borderColor}
      >
        {/* Top Section */}
        <Flex justifyContent="space-between" alignItems="center" p={4} borderBottom="1px solid" borderColor={borderColor} h="80px">
          <HStack spacing={2}>
            <Avatar name="Chef Prints" src="/path-to-logo" boxSize="40px" borderRadius="10px" boxShadow="lg" bg={avatarBg} />
            {!collapsed && <Text fontSize="xl" fontWeight="600" color={textColor}>Chef Prints</Text>}
          </HStack>
          <IconButton
            aria-label="Collapse Sidebar"
            icon={collapsed ? <BsLayoutSidebarInsetReverse size="18px" /> : <BsLayoutSidebarInset size="18px" />}
            size="xs"
            onClick={toggleCollapse}
            variant="ghost"
            sx={{
              svg: {
                color: collapseIconColor,
              },
            }}
          />
        </Flex>

        {/* Dashboard Section */}
        <Flex p={4} pt={2} flexDirection="column">
          <Text fontSize="sm" fontWeight="600" color={textCategoryColor} pb={2}>Principal</Text>
          <VStack spacing={1} align="stretch">
            <NavItem to="/dashboard" icon={LuLayoutDashboard} label="Tableau de bord" isCollapsed={collapsed} />
            <NavItem to="/blueprints" icon={LuListTodo} label="Blueprints" isCollapsed={collapsed} />
            <NavItem to="/liste-epicerie" icon={LuFileSignature} label="Liste d'épicerie" isCollapsed={collapsed} />
            <NavItem to="/recettes" icon={LuCalendarDays} label="Recettes" isCollapsed={collapsed} />
          </VStack>
        </Flex>

        {/* Admin Section */}
        <Flex p={4} pt={2} flexDirection="column">
          <Text fontSize="sm" fontWeight="bold" color={textCategoryColor} pb={2}>Admin</Text>
          <VStack spacing={1} align="stretch">
            <NavItem to="/gestion" icon={LuListTodo} label="Gestion" isCollapsed={collapsed} />
          </VStack>
        </Flex>

        {/* Bottom Section */}
        <Box mt="auto" p={4} borderTop="1px solid" borderColor={borderColor}>
          {profileSection}
        </Box>
      </Box>

      {/* Main content */}
      <Box as="main" flex="1" ml={collapsed ? '70px' : '250px'} borderTopLeftRadius="25px" transition="margin-left 0.3s ease" overflow="hidden" bg={mainBgColor}  >
        {children}
      </Box>
    </Flex>
  );
};

// NavItem Component for Sidebar Links
const NavItem = memo(({ to, icon, label, isCollapsed, badge }) => {
  const { pathname } = useLocation();
  const activeBg = useColorModeValue('primary.700', 'primary.700');
  const activeColor = useColorModeValue('white', 'gray.300');
  const inactiveColor = useColorModeValue('secondary.900', 'gray.100');
  const hoverBg = useColorModeValue('secondary.200', 'primary.300');
  const hoverColor = useColorModeValue('black', 'gray.100');
  const borderColor = useColorModeValue('secondary.200', 'gray.700');

  return (
    <Flex
      direction="column"
      alignItems={isCollapsed ? 'center' : 'flex-start'}
      justifyContent={isCollapsed ? 'center' : 'flex-start'}
      _hover={pathname === to ? {} : { bg: hoverBg, color: hoverColor }} // Disable hover if active
      bg={pathname === to ? activeBg : 'transparent'}
      color={pathname === to ? activeColor : inactiveColor}
      border="1px solid"
      borderColor={pathname === to ? borderColor : 'transparent'}
      borderRadius="xl"
      cursor="pointer"
      w="100%"
      transition='background-color 0.2s, color 0.2s'
    >
      <Tooltip label={label} fontSize="sm" placement="right" isDisabled={!isCollapsed}>
        <Link as={RouterLink} to={to} style={{ textDecoration: 'none' }} display="flex" justifyContent={isCollapsed ? 'center' : 'flex-start'} alignItems="center" p="5px" h="35px" borderRadius="md" w="100%">
          <Icon as={icon} boxSize="20px" ml={isCollapsed ? 0 : 2} />
          {!isCollapsed && (
            <>
              <Text ml={2} fontSize="md" fontWeight="medium">{label}</Text>
              {badge && (
                <Badge colorScheme="primary" borderRadius="full" fontSize="md" w={6} h={6} pt="1px" alignContent="center" ml={2}>
                  {badge}
                </Badge>
              )}
            </>
          )}
        </Link>
      </Tooltip>
    </Flex>
  );
});

export default SideBarLayout;
