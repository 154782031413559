import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { resetPassword } from '../4 - API/API-Auth';
import {
  Box,
  Heading,
  Text,
  Button,
  Input,
  VStack,
  useColorModeValue,
  Container,
  Spinner,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react';

const ResetPasswordPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const bgModalColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.300');

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value.trim() === '') {
      setNewPasswordError('Un nouveau mot de passe est requis');
    } else if (e.target.value.length < 8) {
      setNewPasswordError('Le mot de passe doit comporter au moins 8 caractères');
    } else {
      setNewPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value.trim() === '') {
      setConfirmPasswordError('Confirmez votre mot de passe');
    } else if (e.target.value !== newPassword) {
      setConfirmPasswordError('Les mots de passe ne correspondent pas');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword.trim() === '' || confirmPassword.trim() === '' || confirmPassword !== newPassword) {
      return;
    }

    setIsLoading(true);
    try {
      // eslint-disable-next-line no-unused-vars
      const data = await resetPassword(token, newPassword);

      setMessage('Votre mot de passe a été réinitialisé avec succès.');
      setResetSuccess(true);

      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setMessage(error.message || 'Erreur lors de la réinitialisation du mot de passe.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box bg={bgColor} minH="100vh" display="flex" alignItems="center" justifyContent="center" px={4}>
      <Container maxW="md" centerContent py={8} borderRadius="md" boxShadow="2xl" bg={bgModalColor} p={8}>
        <VStack spacing={6} align="center" w="full">
          <Heading as="h2" size="lg" color={textColor}>
            Réinitialiser le mot de passe
          </Heading>

          {message && (
            <Box
              bg={resetSuccess ? 'green.100' : 'red.100'}
              p={4}
              borderRadius="md"
              w="full"
              textAlign="center"
            >
              <Text color={resetSuccess ? 'green.700' : 'red.700'}>{message}</Text>
            </Box>
          )}

          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <VStack spacing={4} align="stretch">
              <FormControl isInvalid={newPasswordError}>
                <FormLabel htmlFor="newPassword" color={textColor}>Nouveau mot de passe</FormLabel>
                <Input
                  id="newPassword"
                  type="password"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  onBlur={handleNewPasswordChange}
                  isInvalid={!!newPasswordError}
                  required
                />
                {newPasswordError && <FormErrorMessage>{newPasswordError}</FormErrorMessage>}
              </FormControl>

              <FormControl isInvalid={confirmPasswordError}>
                <FormLabel htmlFor="confirmPassword" color={textColor}>Confirmez le mot de passe</FormLabel>
                <Input
                  id="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  onBlur={handleConfirmPasswordChange}
                  isInvalid={!!confirmPasswordError}
                  required
                />
                {confirmPasswordError && <FormErrorMessage>{confirmPasswordError}</FormErrorMessage>}
              </FormControl>

              <Button type="submit" colorScheme="blue" isLoading={isLoading}>
                {isLoading ? <Spinner size="sm" /> : "Réinitialiser le mot de passe"}
              </Button>
              {resetSuccess && (
                <Button type="button" colorScheme="gray" onClick={() => navigate('/login')}>
                  Retour à la connexion
                </Button>
              )}
            </VStack>
          </form>
        </VStack>
      </Container>
    </Box>
  );
};

export default ResetPasswordPage;
