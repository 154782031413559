// utils/measurementConverter.js

/**
 * Converts a decimal number to a string with fractions to the nearest 1/4.
 *
 * @param {number} decimal - The decimal number to convert.
 * @returns {string} - The string representation with fractions (e.g., '1 1/4', '2', '0.75').
 */
function decimalToFractionString(decimal) {
  const fractionMap = {
    0: "",
    0.25: " 1/4",
    0.5: " 1/2",
    0.75: " 3/4",
  };

  const rounded = Math.round(decimal * 4) / 4;
  const whole = Math.floor(rounded);
  const fraction = rounded - whole;

  if (whole === 0 && fraction === 0) {
    return "0";
  } else if (whole === 0) {
    return fractionMap[fraction].trim();
  } else if (fraction === 0) {
    return `${whole}`;
  } else {
    return `${whole}${fractionMap[fraction]}`;
  }
}

/**
 * Converts grams or milliliters into standard kitchen measurements.
 *
 * @param {number} amount - The amount to convert.
 * @param {string} unit - The unit of the amount ('g' for grams, 'ml' for milliliters).
 * @param {string} [ingredientName] - The name of the ingredient (required for solids).
 * @returns {string} - The converted measurement (e.g., '1 1/4 Tasse', '750ml', '1kg').
 */
export function convertToStandardMeasurement(amount, unit, ingredientName = null) {
  if (typeof amount !== "number" || amount <= 0) {
    return "";
  }

  if (unit === "ml") {
    // For liquids, prefer Litre over Tasse
    if (amount >= 1000) {
      const litres = amount / 1000;
      const roundedLitres = Math.round(litres * 4) / 4;
      const fractionLitres = decimalToFractionString(roundedLitres);
      return `${fractionLitres} Litre${roundedLitres > 1 ? "s" : ""}`;
    }
    if (amount >= 60 && amount < 1000) {
      const cups = amount / 240;
      const roundedCups = Math.round(cups * 4) / 4;
      const fractionCups = decimalToFractionString(roundedCups);
      return `${fractionCups} Tasse${roundedCups > 1 ? "s" : ""}`;
    }
    if (amount < 60 && amount > 15) {
      const tablespoons = amount / 15;
      const roundedTablespoons = Math.round(tablespoons * 4) / 4;
      const fractionTablespoons = decimalToFractionString(roundedTablespoons);
      return `${fractionTablespoons} c. à soupe`;
    }
    if (amount <= 15) {
      const teaspoons = amount / 5;
      const roundedTeaspoons = Math.round(teaspoons * 4) / 4;
      const fractionTeaspoons = decimalToFractionString(roundedTeaspoons);
      return `${fractionTeaspoons} c. à thé`;
    }
  } else if (unit === "g") {
    // Decide whether to use kg or Tasse based on the amount
    if (amount >= 1000) {
      const kg = amount / 1000;
      const roundedKg = Math.round(kg * 4) / 4;
      const fractionKg = decimalToFractionString(roundedKg);
      return `${fractionKg} kg`;
    } else if (amount >= 240) {
      const cups = amount / 240;
      const roundedCups = Math.round(cups * 4) / 4;
      const fractionCups = decimalToFractionString(roundedCups);
      return `${fractionCups} Tasse${roundedCups > 1 ? "s" : ""}`;
    } else if (amount < 240 && amount >= 15) {
      const tablespoons = amount / 15;
      const roundedTablespoons = Math.round(tablespoons * 4) / 4;
      const fractionTablespoons = decimalToFractionString(roundedTablespoons);
      return `${fractionTablespoons} c. à soupe`;
    } else if (amount < 15) {
      const teaspoons = amount / 5;
      const roundedTeaspoons = Math.round(teaspoons * 4) / 4;
      const fractionTeaspoons = decimalToFractionString(roundedTeaspoons);
      return `${fractionTeaspoons} c. à thé`;
    } else {
      return `${amount} g`;
    }
  } else {
    console.warn(`Unsupported unit "${unit}". Use 'g' or 'ml'.`);
    return `${amount}${unit}`;
  }
}
