// MobileBarLayout.js
import React, { useMemo, useRef, useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, Flex, Icon, Text, VStack, useColorModeValue, Link } from "@chakra-ui/react";
import { LuListTodo, LuListTodo as LuListTodoFilled } from "react-icons/lu";
import { PiUserCircleFill, PiUserCircle, PiBookmarkSimpleFill, PiBookmarkSimple, PiMagnifyingGlass, PiMagnifyingGlassFill } from "react-icons/pi";
import { IoCompassOutline, IoCompass } from "react-icons/io5";
import { motion } from "framer-motion";

// Define Motion Component
const MotionBox = motion(Box);

const MobileBarLayout = () => {
  const { pathname } = useLocation();
  const activeColor = useColorModeValue("primary.500", "white");
  const inactiveColor = useColorModeValue("gray.600", "gray.200");
  const bgColor = useColorModeValue("white", "gray.900");
  const activeBgColor = useColorModeValue("primary.500", "white");

  // Refs for each nav item
  const navRefs = useRef([]);

  // State to store the position and width of the active item
  const [indicatorStyle, setIndicatorStyle] = useState({
    left: 0,
    width: 0,
  });

  // Define navigation items with memoization
  const navItems = useMemo(
    () => [
      {
        to: "/dashboard",
        icon: pathname === "/dashboard" ? IoCompass : IoCompassOutline,
        label: "Accueil",
      },
      {
        to: "/community-recipes",
        icon: pathname === "/community-recipes" ? PiMagnifyingGlassFill : PiMagnifyingGlass,
        label: "Explorer",
      },
      {
        to: "/recettes",
        icon: pathname === "/recettes" ? PiBookmarkSimpleFill : PiBookmarkSimple,
        label: "Recettes",
      },
      {
        to: "/liste-epicerie",
        icon: pathname === "/liste-epicerie" ? LuListTodoFilled : LuListTodo,
        label: "Épicerie",
      },
      {
        to: "/dashboard/settings",
        icon: pathname === "/dashboard/settings" ? PiUserCircleFill : PiUserCircle,
        label: "Profil",
      },
    ],
    [pathname]
  );

  useEffect(() => {
    // Find the index of the active nav item
    const activeIndex = navItems.findIndex((item) => item.to === pathname);
    if (activeIndex === -1) return;

    const activeRef = navRefs.current[activeIndex];
    if (activeRef) {
      const { offsetLeft, clientWidth } = activeRef;
      console.log(`Active Index: ${activeIndex}, Left: ${offsetLeft}, Width: ${clientWidth}`); // Debugging
      setIndicatorStyle({
        left: offsetLeft + clientWidth * 0.1, // Adjust left position if needed
        width: clientWidth * 0.8, // Reduce the width by 20%
      });
    }
  }, [pathname, navItems]);

  return (
    <Box
      position="fixed"
      bottom="0"
      right="0"
      left="0"
      bg={bgColor}
      boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
      zIndex="1000"
      borderRadius="50px 50px 0 0"
      pb="15px"
      px={2}
      pt={2}
    >
      <Flex justify="space-evenly" align="center" h="80px" position="relative">
        {/* Moving Background Indicator*/}
        <MotionBox
          initial={{ left: 0, width: 0 }}
          animate={{ left: indicatorStyle.left, width: indicatorStyle.width }}
          transition={{ type: "tween", duration: 0.3 }}
          position="absolute"
          bottom="8px"
          h="3px"
          bg={activeBgColor}
          borderRadius="full"
          zIndex="-1"
        />

        {/* Navigation Items */}
        {navItems.map((item, index) => (
          <MobileNavItem
            key={index}
            to={item.to}
            icon={item.icon}
            label={item.label}
            isActive={pathname === item.to}
            activeColor={activeColor}
            inactiveColor={inactiveColor}
            ref={(el) => (navRefs.current[index] = el)}
          />
        ))}
      </Flex>
    </Box>
  );
};

// MobileNavItem Component
const MobileNavItem = React.forwardRef(({ to, icon, label, isActive, activeColor, inactiveColor }, ref) => {
  return (
    <Link
      as={RouterLink}
      to={to}
      _focus={{ outline: "none" }}
      flex="1"
      display="flex"
      alignItems="center"
      justifyContent="center"
      _hover={{ textDecoration: "none" }}
      _active={{ textDecoration: "none" }}
      style={{ position: "relative" }}
      aria-label={label}
      ref={ref}
    >
      <VStack spacing={0.5} align="center" justify="center" h="100%" position="relative" overflow="hidden">
        {/* Icon */}
        <Icon as={icon} boxSize="28px" color={isActive ? activeColor : inactiveColor} transition="color 0.2s" />

        {/* Label */}
        <Text fontSize="xs" fontWeight="500" color={isActive ? activeColor : inactiveColor} textAlign="center" transition="color 0.2s">
          {label}
        </Text>
      </VStack>
    </Link>
  );
});

export default MobileBarLayout;
