import React from 'react';
import GeneralGestionFormModal from './GeneralGestionFormModal';

const FoodGroupFormModal = ({ isOpen, onClose, onSave, initialData }) => {
    const entityName = 'Food Group';
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const apiEndpoint = `${apiUrl}/api/v1/blueprint/food-group`;

    const formFields = [
        {
            name: 'name',
            label: 'Nom du groupe alimentaire',
            placeholder: 'Nom du groupe alimentaire...',
            isRequired: true,
            type: 'text',
        },
        {
            name: 'color',
            label: 'Choisir une couleur',
            type: 'color',
        },
    ];

    const modalTitle = (data) => (data ? 'Modifier le groupe alimentaire' : 'Ajouter un nouveau groupe alimentaire');

    const successMessage = (data) =>
        data
            ? 'Le groupe alimentaire a été mis à jour avec succès.'
            : 'Le groupe alimentaire a été créé avec succès.';

    const failureMessage = 'Une erreur est survenue. Veuillez réessayer plus tard.';

    return (
        <GeneralGestionFormModal
            isOpen={isOpen}
            onClose={onClose}
            onSave={onSave}
            initialData={initialData}
            entityName={entityName}
            apiEndpoint={apiEndpoint}
            formFields={formFields}
            modalTitle={modalTitle}
            successMessage={successMessage}
            failureMessage={failureMessage}
            toastProps={{ /* your toast props here */ }}
        />
    );
};

export default FoodGroupFormModal;
