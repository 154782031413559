// RadioCardGroup.jsx
import React from "react";
import { useRadio, Box, Text, Stack, HStack, Icon, useColorModeValue } from "@chakra-ui/react";

/**
 * Custom RadioCard Component
 * This component transforms a Radio input into a stylized button.
 *
 * Props:
 * - children: The label text for the radio option.
 * - icon: (Optional) An icon to display alongside the label.
 * - ...rest: Any other props to pass to the Radio component.
 */
const RadioCard = (props) => {
  const { children, icon, ...rest } = props;
  const { getInputProps, getRadioProps, state } = useRadio(rest);

  const input = getInputProps();
  const radio = getRadioProps();

  // Define colors based on the current color mode
  const checkedBgColor = useColorModeValue("primary.500", "primary.500"); // Use 'primary' color
  const uncheckedBgColor = useColorModeValue("gray.100", "gray.700");
  const checkedColor = useColorModeValue("white", "white");
  const uncheckedColor = useColorModeValue("gray.800", "white");

  return (
    <Box as="label">
      {/* Hidden Radio Input */}
      <input {...input} />

      {/* Styled Box Acting as the Button */}
      <Box
        {...radio}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow={state.isChecked ? "md" : "sm"}
        px={2}
        py={1}
        bg={state.isChecked ? checkedBgColor : uncheckedBgColor}
        color={state.isChecked ? checkedColor : uncheckedColor}
        _checked={{
          bg: checkedBgColor,
          color: checkedColor,
          borderColor: checkedBgColor,
        }}
        _focus={{
          bg: state.isChecked ? checkedBgColor : uncheckedBgColor,
        }}
        transition="background-color 0.2s, color 0.2s, box-shadow 0.2s"
        display="flex"
        alignItems="center"
      >
        {/* Optional Icon */}
        {icon && (
          <HStack spacing={1}>
            <Icon fontSize="20px" as={icon} />
            <Text fontSize="md">{children}</Text>
          </HStack>
        )}
        {!icon && <Text>{children}</Text>}
      </Box>
    </Box>
  );
};

/**
 * RadioGroup Component
 * This component renders a group of RadioCard components.
 *
 * Props:
 * - options: An array of objects representing the radio options. Each object should have a 'label', 'value', and optionally an 'icon'.
 * - alignment: The alignment of the radio buttons ('horizontal' or 'vertical').
 * - value: The currently selected value.
 * - onChange: Function to call when the selected value changes.
 */
const RadioCardGroup = ({ options, alignment = "vertical", value, onChange }) => {
  return (
    <Stack direction={alignment === "horizontal" ? "row" : "column"} spacing={4}>
      {options.map((option) => (
        <RadioCard
          key={option.value}
          value={option.value}
          icon={option.icon}
          isChecked={value === option.value}
          onChange={() => onChange(option.value)}
        >
          {option.label}
        </RadioCard>
      ))}
    </Stack>
  );
};

export default RadioCardGroup;
