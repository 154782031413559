// client/src/components/3 - Modal/3 - Blueprints Modals/BlueprintCreateSteps.js

import BlueprintStepOne from "../../2 - Components/CreateBlueprint/BlueprintStepOne";
import BlueprintStepTwo from "../../2 - Components/CreateBlueprint/BlueprintStepTwo";
import BlueprintStepThree from "../../2 - Components/CreateBlueprint/BlueprintStepThree";
import BlueprintStepFour from "../../2 - Components/CreateBlueprint/BlueprintStepFour"; // New Step 4
import BlueprintStepFive from "../../2 - Components/CreateBlueprint/BlueprintStepFive"; // New Step 5

const steps = [
  {
    title: "Étape 1",
    description: "Informations générales sur la recette",
    component: BlueprintStepOne,
  },
  {
    title: "Étape 2",
    description: "Sélectionner les composantes de base",
    component: BlueprintStepTwo,
    shouldSkip: (data) => !data.stepOne?.name,
  },
  {
    title: "Étape 3",
    description: "Sélectionner les sous-catégories",
    component: BlueprintStepThree,
    shouldSkip: (data) => !data.stepTwo?.dishComponents?.length,
  },
  {
    title: "Étape 4",
    description: "Sélectionner les aliments",
    component: BlueprintStepFour,
    shouldSkip: (data) => {
      const selectedSubcategories = data.stepThree?.selectedSubcategories;
      return !selectedSubcategories || Object.keys(selectedSubcategories).length === 0;
    },
  },
  {
    title: "Étape 5",
    description: "Résumé",
    component: BlueprintStepFive,
  },
];

export default steps;
