// src/components/Dashboard.jsx

import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Avatar,
  Heading,
  VStack,
  HStack,
  Grid,
  Image,
  Button,
  useColorModeValue,
  Icon,
  Badge,
  useTheme,
  Link as ChakraLink,
  Center,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Background from "../5 - General/Utils/UtilsBackground";
import { FaUserPlus, FaClipboardList, FaShoppingCart, FaUtensils, FaArrowRight, FaPlus, FaGlobe, FaSeedling } from "react-icons/fa";

// Placeholder Images
import placeholderProfile from "../../img/Placeholder.jpg";
import placeholderBlueprint from "../../img/Placeholder1.jpg";
import placeholderRecipe from "../../img/Placeholder1.jpg";
import CommunityRecipeAnnouncementCardImage from "../../img/CRAnnCard.png";

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [blueprints, setBlueprints] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [popularBlueprints, setPopularBlueprints] = useState([]);
  const [stats, setStats] = useState({});
  const imageUrl = CommunityRecipeAnnouncementCardImage;
  const theme = useTheme();

  // Colors based on color mode and primary color
  const bg = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const hoverBg = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const statColor = theme.colors.primary?.[500] || "primary.500"; // Fallback to primary.500
  const bgColor = useColorModeValue("rgba(255, 255, 255, 0.85)", "rgba(26, 32, 44, 0.85)");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const apiUrl = process.env.REACT_APP_API_ENDPOINT || "";

    // Fetch dashboard data
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/v1/user/dashboard`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Error fetching dashboard data");
        }

        const data = await response.json();

        setUserData(data.userData);
        setStats(data.stats);
        setBlueprints(data.blueprints);
        setRecipes(data.recipes);
        setPopularBlueprints(data.popularBlueprints);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Optionally, set an error state here to display a message to the user
      }
    };

    fetchData();
  }, []);

  // If userData is null (e.g., not logged in), show a message or redirect
  if (!userData) {
      return (
        <Center minHeight="100vh">
          <Spinner size="xl" colorScheme="primary" />
        </Center>
      );
    }

  return (
    <Box
      bg={bg}
      h={{ base: "auto", md: "100vh" }}
      py={4}
      px={{ base: 4, md: 8 }}
      backgroundImage={`url(${Background})`}
      backgroundSize="cover"
      backgroundPosition="center"
      overflowY={{ base: "visible", md: "scroll" }}
      pb="120px"
    >
      <Box maxW="1200px" mx="auto" bg={bgColor} borderRadius="lg" p={{ base: 4, md: 8 }} boxShadow="xl">
        {/* Header Section */}
        <Flex mb={4} flexDirection={{ base: "column", md: "row" }}>
          <HStack spacing={6}>
            <Avatar
              size="xl"
              name={`${userData.firstName} ${userData.lastName}`}
              src={userData.profilePicture || placeholderProfile}
              borderWidth="4px"
              borderColor={statColor}
            />
            <VStack align="start" spacing={1}>
              <Heading size="lg" color={textColor}>
                Bonjour, {userData.firstName}!
              </Heading>
              <Text fontSize="md" color="gray.500">
                Bienvenue sur Pense et Cuisine
              </Text>
            </VStack>
          </HStack>
        </Flex>

        {/* Announcement Card */}
        <Box position="relative" borderRadius="10px" overflow="hidden" p={2} mb={6}>
          <Box bgImage={`url(${imageUrl})`} bgSize="cover" bgPosition="center" position="absolute" top="0" left="0" right="0" bottom="0" zIndex="1" />
          <Box bg="black" opacity="0.6" position="absolute" top="0" left="0" right="0" bottom="0" zIndex="2" />
          <Box position="relative" zIndex="3" p={4} color="white">
            <Text fontSize="lg" fontWeight="bold" mb={1}>
              Nouvelle fonctionnalité!
            </Text>
            <Text fontSize="md" width="75%" mr="auto">
              Nous sommes ravis d'annoncer une nouvelle fonctionnalité ajoutée. Essayez-la vous-même!
            </Text>
            <Box position="absolute" bottom="4" right="4">
              <Button colorScheme="primary" variant="solid">
                Détails
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Quick Actions Section */}
        <Box mb={10}>
          <Heading size="md" mb={6} color={textColor}>
            Actions Rapides
          </Heading>
          <Grid
            templateColumns={{
              base: "repeat(2, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(4, 1fr)",
            }}
            gap={6}
          >
            <QuickActionButton to="/create-recipe" icon={FaPlus} label="Créer Recette" colorScheme="primary" />
            <QuickActionButton to="/view-grocery-list" icon={FaShoppingCart} label="Voir Liste d'Achats" colorScheme="blue" />
            <QuickActionButton to="/explore-cultures" icon={FaGlobe} label="Explorer Cultures" colorScheme="green" />
            <QuickActionButton to="/browse-ingredients" icon={FaSeedling} label="Parcourir Ingrédients" colorScheme="orange" />
          </Grid>
        </Box>

        {/* Next Steps */}
        {!userData.profileCompleted && (
          <Box mb={10}>
            <Heading size="md" mb={4} color={textColor}>
              Prochaines Étapes
            </Heading>
            <Stack spacing={4}>
              {!userData.profileCompleted && (
                <Flex
                  w="full"
                  p={6}
                  bg={cardBg}
                  borderRadius="md"
                  boxShadow="md"
                  align="center"
                  justify="space-between"
                  _hover={{
                    bg: hoverBg,
                    boxShadow: "xl",
                  }}
                  transition="all 0.3s ease"
                  flexDirection={{ base: "column", md: "row" }} // Stack vertically on mobile
                  textAlign={{ base: "center", md: "left" }} // Center text on mobile
                >
                  <HStack spacing={4} mb={{ base: 4, md: 0 }}>
                    <Icon as={FaUserPlus} boxSize={8} color={statColor} />
                    <Text fontSize="lg" color={textColor}>
                      Complétez votre profil
                    </Text>
                  </HStack>
                  <Button
                    as={Link}
                    to="/profile"
                    size="md"
                    colorScheme="primary"
                    variant="outline"
                    rightIcon={<FaArrowRight />}
                    width={{ base: "100%", md: "auto" }}
                  >
                    Mettre à jour
                  </Button>
                </Flex>
              )}
              {stats.userTotalRecipes === 0 && (
                <Flex
                  w="full"
                  p={6}
                  bg={cardBg}
                  borderRadius="md"
                  boxShadow="md"
                  align="center"
                  justify="space-between"
                  _hover={{
                    bg: hoverBg,
                    boxShadow: "xl",
                  }}
                  transition="all 0.3s ease"
                  flexDirection={{ base: "column", md: "row" }} // Stack vertically on mobile
                  textAlign={{ base: "center", md: "left" }} // Center text on mobile
                >
                  <HStack spacing={4} mb={{ base: 4, md: 0 }}>
                    <Icon as={FaUtensils} boxSize={8} color={statColor} />
                    <Text fontSize="lg" color={textColor}>
                      Créez votre première recette
                    </Text>
                  </HStack>
                  <Button
                    as={Link}
                    to="/create-recipe"
                    size="md"
                    colorScheme="primary"
                    variant="outline"
                    rightIcon={<FaArrowRight />}
                    width={{ base: "100%", md: "auto" }} // Full width on mobile
                  >
                    Commencer
                  </Button>
                </Flex>
              )}
              {stats.totalBlueprints === 0 && (
                <Flex
                  w="full"
                  p={6}
                  bg={cardBg}
                  borderRadius="md"
                  boxShadow="md"
                  align="center"
                  justify="space-between"
                  _hover={{
                    bg: hoverBg,
                    boxShadow: "xl",
                  }}
                  transition="all 0.3s ease"
                  flexDirection={{ base: "column", md: "row" }} // Stack vertically on mobile
                  textAlign={{ base: "center", md: "left" }} // Center text on mobile
                >
                  <HStack spacing={4} mb={{ base: 4, md: 0 }}>
                    <Icon as={FaClipboardList} boxSize={8} color={statColor} />
                    <Text fontSize="lg" color={textColor}>
                      Créez votre premier blueprint
                    </Text>
                  </HStack>
                  <Button
                    as={Link}
                    to="/create-blueprint"
                    size="md"
                    colorScheme="primary"
                    variant="outline"
                    rightIcon={<FaArrowRight />}
                    width={{ base: "100%", md: "auto" }} // Full width on mobile
                  >
                    Créer
                  </Button>
                </Flex>
              )}
              {/* Additional Steps can be added here */}
            </Stack>
          </Box>
        )}

        {/* Latest Recipes */}
        <Box mb={10}>
          <Heading size="md" mb={4} color={textColor}>
            Vos Dernières Recettes
          </Heading>
          {recipes.length > 0 ? (
            <VStack spacing={4}>
              {recipes.map((recipe) => (
                <Link to={`/recipe/${recipe._id}`} key={recipe._id}>
                  <Box p={4} bg={cardBg} borderRadius="md" boxShadow="md">
                    <HStack spacing={4}>
                      <Image boxSize="80px" objectFit="cover" src={recipe.recipePicture || placeholderRecipe} alt={recipe.name} borderRadius="md" />
                      <VStack align={{ base: "center", md: "start" }} spacing={1} flex="1">
                        <Text fontWeight="bold" fontSize="lg" color={textColor} isTruncated>
                          {recipe.name}
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                          {recipe.description}
                        </Text>
                      </VStack>
                      {recipe.isNew && (
                        <Badge colorScheme="green" variant="solid">
                          Nouveau
                        </Badge>
                      )}
                    </HStack>
                  </Box>
                </Link>
              ))}
            </VStack>
          ) : (
            <>
              <Text color="gray.500">Vous n'avez pas encore de recettes.</Text>
              <Button as={Link} to="/create-recipe" colorScheme="primary" size="lg" mt={4}>
                Créer une recette
              </Button>
            </>
          )}
          <Button
            as={Link}
            to="/recipes"
            variant="solid"
            colorScheme="primary"
            mt={6}
            width="full"
            rightIcon={<FaArrowRight />}
            _hover={{ transform: "translateY(-2px)" }}
            transition="transform 0.2s"
          >
            Voir plus de recettes
          </Button>
        </Box>

        {/* Popular Blueprints */}
        <Box mb={10}>
          <Heading size="md" mb={4} color={textColor}>
            Blueprints Populaires
          </Heading>
          <Grid
            templateColumns={{
              base: "1fr",
              sm: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
            gap={6}
          >
            {popularBlueprints.map((blueprint) => (
              <Link to={`/blueprint/${blueprint._id}`} key={blueprint._id}>
                <Box
                  p={4}
                  bg={cardBg}
                  borderRadius="md"
                  boxShadow="md"
                  _hover={{
                    bg: hoverBg,
                    boxShadow: "xl",
                    transform: "translateY(-2px)",
                  }}
                  transition="all 0.3s ease"
                >
                  <VStack spacing={3} align="start">
                    <Image
                      boxSize="100px"
                      objectFit="cover"
                      src={blueprint.blueprintPicture || placeholderBlueprint}
                      alt={blueprint.name}
                      borderRadius="md"
                    />
                    <Text fontWeight="bold" fontSize="lg" color={textColor} noOfLines={1}>
                      {blueprint.name}
                    </Text>
                    <HStack>
                      <Icon as={FaClipboardList} color={statColor} />
                      <Text fontSize="sm" color="gray.500">
                        Utilisé {blueprint.numberOfTimesUsed} fois
                      </Text>
                    </HStack>
                  </VStack>
                </Box>
              </Link>
            ))}
          </Grid>
          <Button
            as={Link}
            to="/popular-blueprints"
            variant="solid"
            colorScheme="primary"
            mt={6}
            width="full"
            rightIcon={<FaArrowRight />}
            _hover={{ transform: "translateY(-2px)" }}
            transition="transform 0.2s"
          >
            Voir tous les blueprints populaires
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

// Reusable QuickActionButton Component
const QuickActionButton = ({ to, icon, label, colorScheme }) => {
  const buttonBg = useColorModeValue(`${colorScheme}.100`, `${colorScheme}.700`);
  const buttonHoverBg = useColorModeValue(`${colorScheme}.200`, `${colorScheme}.600`);
  const textColor = useColorModeValue(`${colorScheme}.800`, `${colorScheme}.100`);

  return (
    <ChakraLink as={Link} to={to} _hover={{ textDecoration: "none" }}>
      <Flex
        direction="column"
        align="center"
        justify="center"
        bg={buttonBg}
        borderRadius="md"
        p={6}
        boxShadow="md"
        _hover={{
          bg: buttonHoverBg,
          boxShadow: "lg",
          transform: "translateY(-2px)",
        }}
        transition="all 0.3s ease"
        height="100%" // Ensure buttons are of equal height
      >
        <Icon as={icon} w={8} h={8} color={`${colorScheme}.500`} />
        <Text mt={4} fontSize="md" fontWeight="medium" color={textColor} textAlign="center">
          {label}
        </Text>
      </Flex>
    </ChakraLink>
  );
};

export default Dashboard;
