// RecipeList.js
import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  VStack,
  Heading,
  Spinner,
  Flex,
  Input,
  Center,
  SimpleGrid,
  useBreakpointValue,
  Button,
  HStack,
  useColorModeValue,
  ButtonGroup,
  Text,
} from "@chakra-ui/react";
import FeedbackModal from "../3 - Modal/5 - Feedback Modals/FeedbackModal";
import RecipeItem from "./RecipeGridItem";
import EditRecipeDrawer from "../3 - Modal/4 - Recipe Modals/EditRecipeDrawer";
import useCustomToast from "../5 - General/Utils/UtilsNotification";

const RecipeList = () => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const customToast = useCustomToast();
  const [selectedRecipeId, setSelectedRecipeId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const isMobile = useBreakpointValue({ base: true, md: false });
  const inputStyle = { fontSize: isMobile ? "16px" : "14px" };

  // Define ColorMode values
  const textColor = useColorModeValue("gray.800", "white");
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const placeholderTextColor = useColorModeValue("gray.500", "gray.300");
  const borderColor = useColorModeValue("gray.300", "gray.700");
  const createRecipeButtonColorScheme = useColorModeValue("primary", "primary");

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRecipeForEdit, setSelectedRecipeForEdit] = useState(null);

  // New State for Active List: 'creations' or 'favorites'
  const [activeList, setActiveList] = useState("creations");

  const fetchRecipes = async () => {
    try {
      setLoading(true); // Ensure loading state is set when fetching
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      const response = await fetch(`${apiUrl}/api/v1/user/recipe-list/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch recipes");
      }

      const data = await response.json();
      setRecipes(data.recipeList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching recipes:", error);
      customToast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la récupération.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false); // Ensure loading state is unset on error
    }
  };

  useEffect(() => {
    fetchRecipes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectRecipe = (recipeId) => {
    setSelectedRecipeId(selectedRecipeId === recipeId ? null : recipeId); // Toggle selection
  };

  const handleEditRecipe = (recipe) => {
    setSelectedRecipeForEdit(recipe);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedRecipeForEdit(null);
  };

  // Filter recipes based on search query
  const searchFilteredRecipes = useMemo(() => {
    return recipes.filter((recipe) => recipe.name.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [recipes, searchQuery]);

  // Further filter based on activeList if searchQuery is empty
  const filteredRecipes = useMemo(() => {
    if (searchQuery.trim() !== "") {
      return searchFilteredRecipes;
    } else {
      if (activeList === "creations") {
        return recipes; // Assuming all recipes are creations
      } else if (activeList === "favorites") {
        return recipes.filter((recipe) => recipe.isFavorite); // Filter favorites
      }
    }
  }, [searchQuery, searchFilteredRecipes, recipes, activeList]);

  // Determine if empty state should be shown
  const isEmpty = filteredRecipes.length === 0 && !loading;

  if (loading) {
    return (
      <Center minHeight="100vh">
        <Spinner size="xl" colorScheme="primary" />
      </Center>
    );
  }

  return (
    <Box overflowY="auto" maxHeight="100vh" pb="100px">
      {/* Sticky Topbar */}
      <Flex px={4} pt={4} alignItems="center" justifyContent="space-between" mb={4} position="sticky" top="0" zIndex="1" boxShadow="md" bg={bgColor}>
        <VStack alignItems="flex-start" w="100%">
          <HStack w="100%" justifyContent="space-between" mb={2} align="center">
            <Heading color={textColor}>Mes recettes</Heading>
            <HStack spacing={1}>
              <Button
                colorScheme={createRecipeButtonColorScheme}
                border="1px solid"
                borderColor={borderColor}
                onClick={() => (window.location.href = "/blueprints")}
                borderRadius="full"
                fontSize="md"
              >
                Créer une recette
              </Button>
              <FeedbackModal />
            </HStack>
          </HStack>
          <Input
            placeholder="Rechercher dans mes recettes..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={inputStyle}
            borderColor={borderColor}
            color={textColor}
            _placeholder={{ color: placeholderTextColor }}
            mt={2}
          />
          <ButtonGroup size="sm" isAttached variant="outline" w="100%" my={2}>
            <Button
              flex="1"
              color={activeList === "creations" ? "white" : "gray.800"}
              bg={activeList === "creations" ? "primary.700" : "transparent"}
              borderColor={activeList === "creations" ? "primary.500" : "gray.300"}
              onClick={() => setActiveList("creations")}
              _focus={{ boxShadow: "none" }}
              _hover={{ boxShadow: "none" }}
              borderRadius='full'
            >
              Mes créations
            </Button>
            <Button
              flex="1"
              color={activeList === "favorites" ? "white" : "gray.800"}
              bg={activeList === "favorites" ? "primary.700" : "transparent"}
              borderColor={activeList === "favorites" ? "primary.500" : "gray.300"}
              onClick={() => setActiveList("favorites")}
              _focus={{ boxShadow: "none" }}
              _hover={{ boxShadow: "none" }}
              borderRadius='full'
            >
              Mes favoris
            </Button>
          </ButtonGroup>
        </VStack>
      </Flex>

      {/* Content */}
      {!isEmpty ? (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} p={4} spacing={5}>
          {filteredRecipes.map((recipe) => (
            <RecipeItem
              key={recipe._id}
              recipe={recipe}
              isSelected={selectedRecipeId === recipe._id}
              onClick={() => handleSelectRecipe(recipe._id)}
              onClose={() => setSelectedRecipeId(null)}
              onRecipeUpdate={fetchRecipes}
              onEditRecipe={handleEditRecipe}
            />
          ))}
        </SimpleGrid>
      ) : (
        // Empty State
        <Center mt={10} px={4}>
          <VStack spacing={4} textAlign="center">
            <Text fontSize={{ base: "lg", md: "xl" }} color={textColor}>
              Vous semblez ne pas avoir de recettes enregistrées. Commencez par créer la vôtre ou ajoutez-en une depuis notre bibliothèque.
            </Text>
            <HStack spacing={4}>
              <Button colorScheme="primary" onClick={() => (window.location.href = "/blueprints")}>
                Créer une recette
              </Button>
              <Button colorScheme="secondary" variant="outline" onClick={() => (window.location.href = "/community-recipes")}>
                Ajouter depuis la bibliothèque
              </Button>
            </HStack>
          </VStack>
        </Center>
      )}

      {/* Recipe Drawer */}
      {selectedRecipeForEdit && (
        <EditRecipeDrawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} recipe={selectedRecipeForEdit} onRecipeUpdate={fetchRecipes} />
      )}
    </Box>
  );
};

export default RecipeList;
