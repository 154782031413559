import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  Text,
  Button,
  Checkbox,
  Stack,
  VStack,
  InputGroup,
  InputLeftElement,
  Heading,
  Box,
  Textarea,
  Divider,
  SimpleGrid,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  Flex,
  StepIcon,
  StepSeparator,
  StepNumber,
  useSteps,
  IconButton,
  HStack,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Center,
  useDisclosure,
  useColorModeValue,
  Spinner,
  useBreakpointValue,
  RadioGroup,
  Icon,
} from "@chakra-ui/react";

// Icon Imports
import { FaAppleAlt, FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp, FaInfo, FaRandom } from "react-icons/fa";
import { PiArrowDown, PiHandSwipeLeft, PiHandSwipeRight, PiQuestionMark, PiSmiley, PiSmileyMeh, PiSmileySad } from "react-icons/pi";
import { FiSearch } from "react-icons/fi";
import { RxReset } from "react-icons/rx";
import { LuX } from "react-icons/lu";

// Library Imports
import { AnimatePresence, motion } from "framer-motion";
import { useSwipeable } from "react-swipeable";

// Custom Components Imports
import FeedbackModal from "../5 - Feedback Modals/FeedbackModal";
import RadioCardGroup from "../../2 - Components/Reusable/CustomRadioCard";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import { convertToStandardMeasurement } from "../../5 - General/Utils/UtilsMeasurementConverter";
import FoodProfileModal from "../9 - Reusable Modals/FoodProfileModal";

export const BlueprintStartDrawer = ({ isOpen, onClose, blueprint }) => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  // State variables for the Start Drawer
  const [blueprintData, setBlueprintData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAliments, setSelectedAliments] = useState({});
  const [selectedAlimentsInCategory, setSelectedAlimentsInCategory] = useState({});
  const { isOpen: isOverlayOpen, onOpen: onOverlayOpen, onClose: onOverlayClose } = useDisclosure();
  const { isOpen: isFoodProfileOpen, onOpen: onFoodProfileOpen, onClose: onFoodProfileClose } = useDisclosure();
  const [selectedFoodName, setSelectedFoodName] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [expandedAliments, setExpandedAliments] = useState([]);
  const toast = useCustomToast();

  // State variables for the modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recipeName, setRecipeName] = useState("");
  const [recipeDescription, setRecipeDescription] = useState("");
  const [isCloseAlertOpen, setIsCloseAlertOpen] = useState(false);
  const cancelCloseRef = useRef();
  const scrollableContentRef = useRef(null);

  // Color Mode Variables
  const topBarBgColor = useColorModeValue("secondary.50", "gray.700");
  const borderColor = useColorModeValue("secondary.200", "gray.600");
  const scrollableContentAreaBg = useColorModeValue("gray.50", "gray.800");
  const inputBorderColor = useColorModeValue("gray.200", "gray.700");
  const searchBarBg = useColorModeValue("white", "gray.700");
  const foodGroupBg = useColorModeValue("gray.200", "gray.700");
  const foodGroupExpandedBg = useColorModeValue("secondary.500", "primary.500");
  const alimentBg = useColorModeValue("gray.200", "gray.600");
  const overlayBoxBg = useColorModeValue("white", "gray.700");
  const overlayItemBg = useColorModeValue("gray.100", "gray.600");
  const overlayItemTextColor = useColorModeValue("gray.800", "gray.200");
  const accMainTextColor = useColorModeValue("gray.800", "gray.200");
  const expandedAccMainTextColor = useColorModeValue("white", "gray.200");
  const accSubTextColor = useColorModeValue("gray.600", "gray.300");
  const expandedAccSubTextColor = useColorModeValue("gray.200", "gray.300");
  const ResumeAccButtonBg = useColorModeValue("secondary.200", "secondary.500");
  const ResumeAccBg = useColorModeValue("secondary.50", "gray.700");
  const ResumeAccWeightTagTextColor = useColorModeValue("secondary.800", "primary.800");

  // Styles variables
  const fontSizeAccButton = "md";
  const inputStyle = { fontSize: isMobile ? "16px" : "14px" };

  // State for completed steps
  const [completedSteps, setCompletedSteps] = useState([]);

  // State variable for expanded indices
  const [expandedIndices, setExpandedIndices] = useState([]);

  // Fetch blueprint data by ID
  useEffect(() => {
    if (!blueprint || !blueprint._id) {
      return;
    }
    const fetchBlueprintData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/api/v1/blueprint/blueprint/${blueprint._id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch blueprint data");
        }

        const data = await response.json();
        setBlueprintData(data);
      } catch (error) {
        console.error("Error fetching blueprint data:", error);
        toast({
          status: "error",
          title: "Error",
          description: "An error occurred while fetching the blueprint data.",
        });
      }
    };
    if (!blueprintData) {
      fetchBlueprintData();
    }
  }, [blueprint, apiUrl, toast, blueprintData]);

  // Initialize recipe name and description when blueprint data is loaded
  useEffect(() => {
    if (blueprintData) {
      setRecipeName(blueprintData.name || "");
      setRecipeDescription(blueprintData.description || "Une recette personnalisée générée à partir des éléments sélectionnés");
    }
  }, [blueprintData]);

  const steps = useMemo(() => {
    return (
      blueprintData?.dishComponents?.map((component) => ({
        title: component.dishComponent?.name || "Composant inconnu",
      })) || []
    );
  }, [blueprintData]);

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [direction, setDirection] = useState("right");
  const previousStep = useRef(activeStep);
  useEffect(() => {
    if (isOpen) {
      setIsCloseAlertOpen(false);
      onOverlayOpen();
      setTimeout(() => {
        onOverlayClose();
      }, 10000);
    }
  }, [isOpen, onOverlayOpen, onOverlayClose]);

  useEffect(() => {
    if (activeStep > previousStep.current) {
      setDirection("right");
    } else if (activeStep < previousStep.current) {
      setDirection("left");
    }
    previousStep.current = activeStep;
  }, [activeStep]);

  // Swipe handlers for navigating between steps
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (activeStep < steps.length - 1) {
        setActiveStep(activeStep + 1);
      }
    },
    onSwipedRight: () => {
      if (activeStep > 0) {
        setActiveStep(activeStep - 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  useEffect(() => {
    if (scrollableContentRef.current) {
      scrollableContentRef.current.scrollTop = 0;
    }
    setSearchTerm("");
    setExpandedIndices([]);
  }, [activeStep]);

  const currentDishComponent = blueprintData?.dishComponents?.[activeStep];
  const filteredSubCategories = currentDishComponent?.subCategories?.filter((subCategory) => {
    if (!currentDishComponent) return false;
    const hasFoods = subCategory?.food && subCategory?.food.length > 0;
    if (!hasFoods) return false;
    if (searchTerm.trim() === "") return true;
    const matchingAliments = subCategory.food.filter((aliment) => aliment.name.toLowerCase().includes(searchTerm.toLowerCase()));
    return matchingAliments.length > 0;
  });

  // Update expanded indices when search term changes
  useEffect(() => {
    if (searchTerm.trim() !== "") {
      // Expand all accordions
      const indices = filteredSubCategories.map((_, index) => index);
      setExpandedIndices(indices);
    }
    // When searchTerm is empty, do not modify expandedIndices
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  if (!blueprint || !blueprint._id) {
    return null;
  }

  if (!blueprintData) {
    return (
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={isMobile ? "full" : "lg"} closeOnOverlayClick={false} closeOnEsc={false}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <HStack display="flex" w="100%" justify="space-between" align="center">
              <Text>Chargement du blueprint...</Text>
              <IconButton
                variant="solid"
                size="sm"
                colorScheme="gray"
                borderRadius="full"
                border="1px solid"
                borderColor="gray.400"
                icon={<LuX fontSize="25px" />}
                onClick={() => setIsCloseAlertOpen(true)}
              />
            </HStack>
          </DrawerHeader>
          <DrawerBody display="flex" flexDirection="column" p={0} overflow="hidden">
            <Center flex="1">
              <Spinner />
            </Center>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  if (!blueprint?._id) {
    console.error("Blueprint ID is missing");
    return; // Prevent further actions if ID is missing
  }

  const activeStepText = steps[activeStep]?.title || "";

  if (!currentDishComponent) {
    return null;
  }

  const getMaxFoodsForComponent = (componentId) => {
    if (!componentId) {
      console.error("Invalid componentId:", componentId);
      return 0;
    }
    const component = blueprintData?.dishComponents?.find((comp) => comp.dishComponent?._id === componentId);
    return component ? component.maxFoods : 0;
  };

  const getPercentageForComponent = (componentId) => {
    const component = blueprintData?.dishComponents?.find((comp) => comp.dishComponent?._id === componentId);
    return component ? component.percentage : 0;
  };

  const getComponentAllowedWeight = (componentId) => {
    const percentage = getPercentageForComponent(componentId);
    const totalAllowedWeight = blueprintData?.totalAllowedWeight || 0;
    return (totalAllowedWeight * percentage) / 100;
  };

  const toggleAlimentExpansion = (alimentId) => {
    setExpandedAliments((prev) => {
      if (prev.includes(alimentId)) {
        return prev.filter((id) => id !== alimentId);
      } else {
        return [...prev, alimentId];
      }
    });
  };

  const calculateBaseWeight = (componentId) => {
    const totalSelected = selectedAliments
      ? Object.values(selectedAliments)
          .flat()
          .filter((item) => item.componentId === componentId).length
      : 0;
    const componentAllowedWeight = getComponentAllowedWeight(componentId);
    return totalSelected > 0 ? componentAllowedWeight / totalSelected : 0;
  };

  const selectRandomIngredients = async () => {
    if (!blueprintData || !blueprintData.dishComponents) return;

    const newSelectedAliments = {}; // Temporary object to hold selections

    for (let i = 0; i < blueprintData.dishComponents.length; i++) {
      const component = blueprintData.dishComponents[i];
      const maxFoods = component.maxFoods || 0;
      const subCategories = component.subCategories || [];

      console.log("Selecting random ingredients for component", component.dishComponent?.name);
      console.log("Max foods allowed:", maxFoods);

      let totalSelected = 0; // Track the total number of selected aliments for this component

      subCategories.forEach((subCategory) => {
        const availableFoods = subCategory.food || [];
        const remainingSlots = maxFoods - totalSelected; // Calculate remaining slots based on maxFoods

        if (remainingSlots <= 0) return; // Stop if we've reached the max for this component

        const randomFoodCount = Math.min(availableFoods.length, remainingSlots); // Ensure we don't select more than allowed

        // Randomly shuffle the available foods and select up to randomFoodCount
        const shuffledFoods = availableFoods.sort(() => 0.5 - Math.random());
        const selectedFoods = shuffledFoods.slice(0, randomFoodCount);

        if (!newSelectedAliments[subCategory.subCategory?._id]) {
          newSelectedAliments[subCategory.subCategory?._id] = [];
        }

        // Add selected foods to the result
        selectedFoods.forEach((food) => {
          newSelectedAliments[subCategory.subCategory?._id].push({
            alimentId: food._id,
            componentId: component.dishComponent._id,
            selectedOption: "normal", // Default to 'normal'
          });
        });

        totalSelected += selectedFoods.length; // Update the total selected foods count for this component
      });

      // Update the state after processing each component
      setSelectedAliments((prev) => ({
        ...prev,
        ...newSelectedAliments, // Apply the selected aliments for the current step
      }));

      // Move to the current step for visual feedback
      setActiveStep(i);

      // Add a delay of 1.5 seconds to show the transition and animation
      await new Promise((resolve) => setTimeout(resolve, 1500));
    }

    // After all steps are completed, move to the final step and open the accordion
    setActiveStep(steps.length - 1);
    setIsAccordionOpen(true);
  };

  const handleAlimentChange = (categoryId, alimentId, checked) => {
    const componentId = currentDishComponent.dishComponent?._id;

    if (!componentId) return;

    setSelectedAliments((prev) => {
      const updated = { ...prev };

      if (!updated[categoryId]) {
        updated[categoryId] = [];
      }

      if (checked) {
        updated[categoryId].push({
          alimentId,
          componentId,
          selectedOption: "normal",
        });

        const totalSelectedInComponentAfter = updated
          ? Object.values(updated)
              .flat()
              .filter((item) => item.componentId === componentId).length
          : 0;

        const maxFoods = getMaxFoodsForComponent(componentId);

        if (totalSelectedInComponentAfter === maxFoods + 1) {
          toast({
            status: "info",
            title: "Trop de bons ingrédients !",
            description:
              "Vous avez sélectionné plus d'aliments que recommandé pour ce composant. À vous de voir si vous continuez ou retirez un aliment !",
          });
        }
      } else {
        // Remove the aliment
        updated[categoryId] = updated[categoryId].filter((item) => item.alimentId !== alimentId);

        // If no aliments left in the category, remove the category key
        if (updated[categoryId].length === 0) {
          delete updated[categoryId];
        }
      }

      // Update selectedAlimentsInCategory
      setSelectedAlimentsInCategory((prev) => {
        const updatedInCategory = { ...prev };

        if (!updatedInCategory[categoryId]) {
          updatedInCategory[categoryId] = [];
        }

        if (checked) {
          updatedInCategory[categoryId].push({
            alimentId,
            componentId,
            selectedOption: "normal",
          });
        } else {
          updatedInCategory[categoryId] = updatedInCategory[categoryId].filter((item) => item.alimentId !== alimentId);

          if (updatedInCategory[categoryId].length === 0) {
            delete updatedInCategory[categoryId];
          }
        }

        return updatedInCategory;
      });
      setSearchTerm("");
      return updated;
    });
  };

  // Check if an aliment is selected
  const isAlimentSelected = (subCategoryId, alimentId) => {
    // Check if selectedAliments and the specific subCategoryId exist before accessing them
    if (!selectedAliments || !selectedAliments[subCategoryId]) {
      return false;
    }
    return selectedAliments[subCategoryId].some((item) => item.alimentId === alimentId);
  };

  const handleOptionChange = (subCategoryId, alimentId, option) => {
    setSelectedAliments((prev) => {
      const updated = { ...prev };
      const componentId = currentDishComponent?.dishComponent?._id;

      if (!componentId) return prev;

      if (!updated[subCategoryId]) {
        updated[subCategoryId] = [];
      }

      const alimentIndex = updated[subCategoryId].findIndex((item) => item.alimentId === alimentId);

      if (alimentIndex !== -1) {
        updated[subCategoryId][alimentIndex].selectedOption = option;
      }

      setSearchTerm("");
      return updated;
    });
  };

  // Adjust aliment weight based on selected option
  const adjustAlimentWeight = (baseWeight, selectedOption) => {
    if (selectedOption === "un_peu") return baseWeight * 0.5;
    if (selectedOption === "beaucoup") return baseWeight * 1.5;
    return baseWeight;
  };

  const getSelectedAlimentsCountPerCategory = () => {
    if (!currentDishComponent) return {};

    const counts = {};
    currentDishComponent.subCategories.forEach((subCategory) => {
      const subCategoryId = subCategory?.subCategory?._id;
      if (subCategoryId) {
        counts[subCategoryId] = selectedAlimentsInCategory[subCategoryId]?.length || 0;
      }
    });
    return counts;
  };

  const getTotalSelectedAliments = () => {
    return Object.values(selectedAliments)?.flat()?.length || 0;
  };

  // Handle saving the selected aliments and recipe
  const handleSave = async () => {
    if (!recipeName || !blueprintData || !blueprintData.dishComponents) {
      toast({
        status: "error",
        title: "Invalid Recipe",
        description: "Recipe name or blueprint data is missing.",
      });
      return;
    }
    console.log("blueprintData before saving:", blueprintData);
    try {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");

      if (!userId) {
        toast({
          status: "error",
          title: "Connexion perdue",
          description: "Utilisateur non défini. Veuillez vous déconnecter et vous reconnecter pour continuer !",
        });
        return;
      }

      const recipe = {
        name: recipeName || "Recette sans nom",
        description: recipeDescription || "Une recette personnalisée générée à partir des éléments sélectionnés",
        totalAllowedWeight: blueprintData.totalAllowedWeight,
        estimatedPrepTime: blueprintData.estimatedPrepTime,
        estimatedCookTime: blueprintData.estimatedCookTime,
        createdWith: blueprintData._id,
        recipePicture: blueprintData.blueprintPicture,
        recipeComponents: blueprintData.dishComponents?.map((component) => ({
          dishComponent: component.dishComponent?._id,
          subCategories: component.subCategories?.map((subCategory) => ({
            subCategory: subCategory.subCategory?._id,
            food:
              selectedAliments[subCategory.subCategory?._id]
                ?.filter((item) => item.componentId === component.dishComponent?._id)
                .map((item) => {
                  if (component.isWeighted) {
                    const baseWeight = calculateBaseWeight(item.componentId);
                    const adjustedWeight = adjustAlimentWeight(baseWeight, item.selectedOption);
                    const currentWeight = adjustedWeight;
                    return {
                      foodId: item.alimentId,
                      weight: currentWeight,
                    };
                  } else {
                    return {
                      foodId: item.alimentId,
                      units: component.numOfUnitsPerIngredients,
                    };
                  }
                }) || [],
          })),
        })),
      };

      const response = await fetch(`${apiUrl}/api/v1/user/recipe/create/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(recipe),
      });

      if (!response.ok) {
        throw new Error("Failed to save recipe");
      }
      const data = await response.json();
      console.log("Recipe saved with ID:", data._id);
      // Reset or update state after save
      setSelectedAliments({});
      setRecipeName("");
      setRecipeDescription("");
      toast({
        status: "success",
        title: "Recette enregistrée !",
        description: "Votre recette a été sauvegardée avec succès.",
      });
      onClose();
    } catch (error) {
      toast({
        status: "error",
        title: "Erreur",
        description: "Une erreur est survenue lors de l'enregistrement de votre recette.",
      });
    }
  };

  // Animations
  const variants = {
    enter: (direction) => ({
      x: direction === "right" ? "100%" : "-100%",
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction === "right" ? "-100%" : "100%",
      opacity: 0,
    }),
  };

  // Calculate remaining aliments for current component
  const componentId = currentDishComponent.dishComponent?._id;
  const maxFoods = getMaxFoodsForComponent(componentId);
  const selectedAlimentsInComponent = selectedAliments
    ? Object.values(selectedAliments)
        .flat()
        .filter((item) => item.componentId === componentId).length
    : 0;

  const remainingAliments = maxFoods - selectedAlimentsInComponent;

  // Determine the color scheme for the Tag
  let tagBackgroundColor = "gray.100";
  let tagTextColor = "gray.800";

  if (remainingAliments === 0) {
    tagBackgroundColor = "primary.100";
    tagTextColor = "primary.900";
  } else if (remainingAliments < 0) {
    tagBackgroundColor = "red.100";
    tagTextColor = "red.800";
  } else {
    // Optional: handle when remainingAliments > 0 (for clarity)
    tagBackgroundColor = "secondary.100";
    tagTextColor = "secondary.800";
  }

  const funText = {
    loading: "On prépare la magie du blueprint...",
    error: "Oups ! Quelque chose ne va pas...",
    weightExceeded: "Vous avez dépassé la quantité recommandée, mais c'est vous le chef !",
    confirmClose: "Êtes-vous sûr de vouloir quitter sans sauvegarder ? On perd tout !",
    cancelClose: "Ouf, on reste ici.",
    recipeSaved: "Votre recette est sauvée ! Elle est prête à être savourée.",
    saveRecipe: "C'est parti pour sauvegarder !",
    nextStep: "Allons-y ! Suivant",
    prevStep: "Reculons d'un pas",
    namePrompt: "Donne un super nom à ta recette !",
    descriptionPrompt: "Ajoute lui aussi description !",
  };

  const handleDrawerClose = () => {
    setSelectedAliments({});
    setRecipeName("");
    setRecipeDescription("");
    onClose();
  };

  const openModalWithFood = (foodName) => {
    setSelectedFoodName(foodName);
    onFoodProfileOpen();
  };

  // Reset current step
  const resetCurrentStep = () => {
    const componentId = currentDishComponent.dishComponent?._id;
    if (!componentId) return;
    setSelectedAliments((prev) => {
      const updated = { ...prev };
      // Remove aliments associated with the current component
      Object.keys(updated).forEach((subCategoryId) => {
        updated[subCategoryId] = updated[subCategoryId].filter((item) => item.componentId !== componentId);
        if (updated[subCategoryId].length === 0) {
          delete updated[subCategoryId];
        }
      });
      return updated;
    });
    setSearchTerm("");
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={handleDrawerClose}
      size={isMobile ? "full" : "lg"}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader bg={topBarBgColor}>
          <HStack display="flex" w="100%" justify="space-between" align="center">
            <Text>{`${blueprintData?.name || "Blueprint"}`}</Text>
            <HStack>
              <FeedbackModal />
              <IconButton
                variant="solid"
                size="sm"
                colorScheme="gray"
                borderRadius="full"
                border="1px solid"
                borderColor="gray.400"
                icon={<PiQuestionMark fontSize="25px" />}
                onClick={() => onOverlayOpen()}
              />
              <IconButton
                variant="solid"
                size="sm"
                colorScheme="gray"
                borderRadius="full"
                border="1px solid"
                borderColor="gray.400"
                icon={<LuX fontSize="25px" />}
                onClick={() => setIsCloseAlertOpen(true)}
              />
            </HStack>
          </HStack>
        </DrawerHeader>
        <DrawerBody display="flex" flexDirection="column" p={0} overflow="hidden" position="relative" {...handlers}>
          {/* Stepper */}
          <Box pb={3} bg={topBarBgColor}>
            <Stepper size="lg" index={activeStep} mx={4}>
              {steps.map((step, index) => (
                <Step key={index} isCompleted={completedSteps.includes(index)} onClick={() => setActiveStep(index)} gap="0">
                  <StepIndicator boxSize="35px">
                    <StepStatus complete={<StepIcon />} active={<StepNumber />} incomplete={<StepNumber />} />
                  </StepIndicator>
                  <StepSeparator _horizontal={{ height: "4px", borderRadius: "full" }} />
                </Step>
              ))}
            </Stepper>
          </Box>
          {/* Header Section */}
          <Box bg={topBarBgColor}>
            <Box bg={topBarBgColor}>
              <HStack justifyContent="center" mx={4} mt={2}>
                <Text fontSize="xl" fontWeight="bold">
                  Ingrédients pour: {activeStepText}
                </Text>
              </HStack>
              {/*
              <Text fontSize="md" color="gray.600" textAlign="center" m={1}>
                Poids recommandé par portion: {getComponentAllowedWeight(componentId)} g
              </Text>
               */}
            </Box>
            {/* Tag for Remaining Ingredients */}
            <Flex w="100%" flexDir="row" justifyContent="center" alignItems="center" pb={2} borderBottom="1px solid" borderColor={borderColor}>
              <Tag
                bg={tagBackgroundColor}
                color={tagTextColor}
                mx={1}
                my={2}
                py={2}
                px={3}
                textAlign="center"
                borderRadius="full"
                display="flex"
                justifyContent="center"
                alignItems="center"
                w="fit-content"
              >
                <Text textAlign="center" fontSize="md">
                  {remainingAliments > 0 ? (
                    <>
                      <Text>
                        Il te reste{" "}
                        <Text as="span" fontWeight="black">
                          {remainingAliments}
                        </Text>{" "}
                        {remainingAliments === 1 ? "ingrédient" : "ingrédients"} à ajouter pour équilibrer!
                      </Text>
                    </>
                  ) : remainingAliments === 0 ? (
                    "C'est parfait ! Passe à l'étape suivante !"
                  ) : (
                    <>
                      <Text as="span" fontWeight="black">
                        {Math.abs(remainingAliments)}
                      </Text>{" "}
                      {Math.abs(remainingAliments) === 1 ? "ingrédient" : "ingrédients"} en trop, ta recette semble diluée!
                    </>
                  )}
                </Text>
              </Tag>
              <IconButton
                onClick={selectRandomIngredients}
                icon={<FaRandom />}
                variant="solid"
                size="sm"
                border="1px solid"
                borderColor="gray.400"
                colorScheme="gray"
                borderRadius="full"
                ml={2}
              />
            </Flex>
          </Box>
          {/* Scrollable Content Area */}
          <Box
            ref={scrollableContentRef}
            flex="1"
            overflowY="auto"
            overflowX="hidden"
            px={4}
            position="relative"
            className="scrollable-content"
            bg={scrollableContentAreaBg}
            borderRadius="md"
            boxShadow="sm"
            p={4}
          >
            {/* Enhanced Search Input */}
            <InputGroup mb={4}>
              <InputLeftElement pointerEvents="none">
                <FiSearch color="gray.500" />
              </InputLeftElement>
              <Input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Rechercher un aliment..."
                variant="filled"
                bg={searchBarBg}
                border="1px solid"
                borderColor={inputBorderColor}
              />
            </InputGroup>

            {/* Animated and Styled Accordions */}
            <AnimatePresence mode="wait" initial={false}>
              <motion.div
                key={activeStep}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ duration: 0.3 }}
                style={{ flex: 1 }}
              >
                {filteredSubCategories.length > 0 ? (
                  <Accordion allowMultiple index={expandedIndices} onChange={(indices) => setExpandedIndices(indices)}>
                    {filteredSubCategories.map((subCategory, subIndex) => {
                      const subCategoryId = subCategory?.subCategory?._id;
                      const selectedCounts = getSelectedAlimentsCountPerCategory();

                      if (!subCategoryId) {
                        return null;
                      }

                      // Filter aliments based on search term
                      const filteredAliments = subCategory.food.filter((aliment) => aliment.name.toLowerCase().includes(searchTerm.toLowerCase()));

                      return (
                        <AccordionItem key={subCategoryId || subIndex} border="none" mb={2} borderRadius="xl" overflow="hidden" boxShadow="base">
                          {({ isExpanded }) => (
                            <>
                              <h2>
                                <AccordionButton
                                  _expanded={{ bg: foodGroupExpandedBg, color: "white" }}
                                  bg={foodGroupBg}
                                  borderRadius="xl"
                                  px={4}
                                  py={2}
                                  transition="background-color 0.2s, color 0.2s"
                                >
                                  <Box flex="1" textAlign="left" fontWeight="bold" fontSize="lg">
                                    <Text
                                      color={isExpanded ? expandedAccMainTextColor : accMainTextColor} // Corrected
                                      fontWeight="bold"
                                      mr={2}
                                      fontSize={fontSizeAccButton}
                                    >
                                      {subCategory.subCategory?.name || "Sous-catégorie"}
                                    </Text>
                                    <Box
                                      as="span"
                                      fontSize="md"
                                      color={isExpanded ? expandedAccSubTextColor : accSubTextColor} // Corrected
                                    >
                                      {subCategory.food.length} aliment
                                      {subCategory.food.length > 1 ? "s" : ""} ({selectedCounts[subCategoryId] || 0} sélectionné
                                      {subCategoryId && selectedCounts[subCategoryId] > 1 ? "s" : ""})
                                    </Box>
                                  </Box>
                                  <Text
                                    color={isExpanded ? expandedAccMainTextColor : accMainTextColor} // Corrected
                                    fontWeight="bold"
                                    mr={2}
                                    fontSize={fontSizeAccButton}
                                  >
                                    {isExpanded ? "Fermer" : "Ouvrir"}
                                  </Text>
                                  {isExpanded ? <FaChevronUp fontSize={fontSizeAccButton} /> : <FaChevronDown fontSize={fontSizeAccButton} />}
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                <Stack spacing={3}>
                                  {filteredAliments
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((aliment) => {
                                      const isChecked = isAlimentSelected(subCategoryId, aliment._id);
                                      const selectedAlimentItem = selectedAliments[subCategoryId]?.find((item) => item?.alimentId === aliment?._id);
                                      const selectedOption = selectedAlimentItem?.selectedOption || "normal";

                                      return (
                                        <Box key={aliment._id} p={2} borderRadius="md" bg={isChecked ? alimentBg : "transparent"} w="full">
                                          <HStack justify="space-between" alignItems="center" w="full">
                                            <Checkbox
                                              isChecked={isChecked}
                                              onChange={(e) => handleAlimentChange(subCategoryId, aliment?._id, e.target.checked)}
                                              colorScheme="primary"
                                              flex="1"
                                            >
                                              <Text fontWeight="500" fontSize="md">
                                                {aliment?.name || "Aliment"}
                                              </Text>
                                            </Checkbox>
                                            <IconButton
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedFoodName(aliment?.name);
                                                openModalWithFood(aliment?.name);
                                              }}
                                              size="sm"
                                              icon={<FaInfo />}
                                              colorScheme="gray"
                                              mr={2}
                                              borderRadius="full"
                                            />
                                            {isChecked &&
                                              (currentDishComponent.isWeighted === false ? (
                                                <Tag fontSize="sm" bg="secondary.100">
                                                  Unités par portion: {currentDishComponent.numOfUnitsPerIngredients}
                                                </Tag>
                                              ) : (
                                                <RadioGroup
                                                  name={`option-${aliment?._id}`}
                                                  value={selectedOption}
                                                  onChange={(value) => handleOptionChange(subCategoryId, aliment?._id, value)}
                                                >
                                                  <HStack spacing={1}>
                                                    <RadioCardGroup
                                                      options={[
                                                        {
                                                          label: "Moins",
                                                          value: "un_peu",
                                                          icon: PiSmileySad,
                                                        },
                                                        {
                                                          label: "Normal",
                                                          value: "normal",
                                                          icon: PiSmileyMeh,
                                                        },
                                                        {
                                                          label: "Plus!",
                                                          value: "beaucoup",
                                                          icon: PiSmiley,
                                                        },
                                                      ]}
                                                      alignment="vertical"
                                                      value={selectedOption}
                                                      onChange={(value) => handleOptionChange(subCategoryId, aliment?._id, value)}
                                                    />
                                                  </HStack>
                                                </RadioGroup>
                                              ))}
                                          </HStack>
                                          <Divider mt={2} />
                                        </Box>
                                      );
                                    })}
                                </Stack>
                              </AccordionPanel>
                            </>
                          )}
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                ) : (
                  <Text mt={4} color="gray.500" textAlign="center">
                    Aucun composant disponible.
                  </Text>
                )}
              </motion.div>
            </AnimatePresence>
            <Box position="absolute" top={0} bottom={0} left={0} right={0} pointerEvents="none"></Box>
          </Box>
          {/* Footer Section */}
          {/* Button Next-Reset-Previous Section */}
          <HStack justifyContent="space-between" m={4} align="center">
            <Button
              onClick={() => setActiveStep((prev) => Math.max(prev - 1, 0))}
              isDisabled={activeStep === 0}
              leftIcon={<FaChevronLeft />}
              variant="outline"
              colorScheme="secondary"
              size="sm"
            >
              {funText.prevStep}
            </Button>
            <IconButton
              onClick={resetCurrentStep}
              variant="outline"
              colorScheme="red"
              icon={<RxReset />}
              size="sm"
              isDisabled={selectedAlimentsInComponent === 0}
              aria-label="Réinitialiser l'étape"
            />
            <Button
              rightIcon={<FaChevronRight />}
              variant="solid"
              colorScheme="primary"
              isDisabled={selectedAlimentsInComponent === 0}
              onClick={() => {
                if (activeStep === steps.length - 1 && getTotalSelectedAliments() !== 0) {
                  // If you're at the last step, toggle the accordion
                  if (!isAccordionOpen) {
                    setIsAccordionOpen(true);
                  } else {
                    // Mark the last step as completed
                    if (!completedSteps.includes(activeStep)) {
                      setCompletedSteps((prev) => [...prev, activeStep]);
                    }
                    setIsModalOpen(true);
                  }
                } else {
                  setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
                }
              }}
              size="sm"
            >
              {activeStep === steps.length - 1 ? "Vérifier et Sauvegarder" : funText.nextStep}
            </Button>
          </HStack>
          {/* Selected Items Accordion */}
          <Accordion allowToggle index={isAccordionOpen ? 0 : -1} onChange={() => setIsAccordionOpen(!isAccordionOpen)}>
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      h="70px"
                      borderY="1px solid"
                      borderColor={borderColor}
                      borderRadius="md"
                      display="flex"
                      alignItems="center"
                      bg={ResumeAccBg}
                    >
                      {/* First Box with Vertical Alignment */}
                      <Box flex="1" textAlign="left" display="flex" alignItems="center">
                        <Heading size="sm">{getTotalSelectedAliments()} Aliments sélectionnés</Heading>
                      </Box>

                      {/* Second Box with Flex Properties */}
                      <Box
                        display="flex"
                        flexDir="row"
                        align="center"
                        justifyContent="center"
                        textAlign="right"
                        p={2}
                        bg={ResumeAccButtonBg}
                        borderRadius="lg"
                        as="span"
                      >
                        <Text fontSize="md" fontWeight="bold" mr={2}>
                          {isExpanded ? "Fermer" : "Ouvrir"} le résumé
                        </Text>
                        <Icon my="auto" as={isExpanded ? FaChevronDown : FaChevronUp} />
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel maxH="50vh" overflowY="auto">
                    <SimpleGrid columns={1} spacing={1}>
                      {blueprintData?.dishComponents?.map((component) => (
                        <Box key={component?.dishComponent?._id} mb={4}>
                          <HStack align="center" mb={2}>
                            <Icon as={FaAppleAlt} color="green.500" /> {/* Example Icon */}
                            <Text fontWeight="bold" fontSize="lg">
                              {component.dishComponent?.name || "Composant"}
                            </Text>
                          </HStack>
                          {component.subCategories?.map((subCategory) => (
                            <Box key={subCategory.subCategory?._id} pl={2}>
                              {selectedAliments[subCategory?.subCategory?._id]
                                ?.filter((item) => item.componentId === component.dishComponent?._id)
                                .map((item) => {
                                  const aliment = subCategory.food?.find((a) => a._id === item.alimentId);
                                  const baseWeight = calculateBaseWeight(item.componentId);
                                  const adjustedWeight = adjustAlimentWeight(baseWeight, item.selectedOption);
                                  const currentWeight = convertToStandardMeasurement(Math.ceil(adjustedWeight), "ml");

                                  const isExpanded = expandedAliments.includes(item.alimentId);

                                  return (
                                    <HStack justifyContent="space-between" alignItems="center" p={1} key={item.alimentId}>
                                      {/* Aliment Name with Checkbox */}
                                      <HStack>
                                        <Checkbox isChecked={true} colorScheme="teal" pointerEvents="none" />
                                        <Text
                                          isTruncated={!isExpanded}
                                          maxW="none"
                                          fontSize="md"
                                          onClick={() => toggleAlimentExpansion(item.alimentId)}
                                          cursor="pointer"
                                          title={aliment?.name || "Aliment"}
                                        >
                                          {aliment?.name || "Aliment"}
                                        </Text>
                                      </HStack>

                                      {/* Conditionally Render Divider and Weight */}
                                      {!isExpanded && (
                                        <>
                                          <Box flex="1" mx={1}>
                                            <Divider borderColor="gray.300" />
                                          </Box>
                                        </>
                                      )}
                                      <Tag fontSize="sm" bg="secondary.100" color={ResumeAccWeightTagTextColor}>
                                        <Text isTruncated>{currentWeight || "0"}</Text>
                                      </Tag>
                                    </HStack>
                                  );
                                })}
                            </Box>
                          ))}
                        </Box>
                      ))}
                    </SimpleGrid>

                    {/* Conditionally show the congratulatory text only on the last step */}
                    {activeStep === steps.length - 1 && (
                      <HStack justifyContent="center" align="center" p={1} mb={2}>
                        <Text fontSize="md" fontWeight="bold" textAlign="center">
                          Bravo, chef! Tu viens de créer une recette incroyable! N'oublie pas de la sauvegarder!
                        </Text>
                      </HStack>
                    )}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>

          <FoodProfileModal isOpen={isFoodProfileOpen} onClose={onFoodProfileClose} onOpen={onFoodProfileOpen} foodName={selectedFoodName} />
          {/* Tutorial Overlay */}
          {isOverlayOpen && (
            <VStack
              position="fixed"
              top="0"
              left="0"
              right="0"
              bottom="0"
              backgroundColor="rgba(0, 0, 0, 0.6)"
              display="flex"
              justifyContent="center"
              alignItems="center"
              zIndex="1400"
              pointerEvents="auto"
              px={4}
              onClick={onOverlayClose}
            >
              <Box
                bg={overlayBoxBg}
                p={8}
                borderRadius="xl"
                boxShadow="lg"
                maxW="md"
                textAlign="center"
              >
                <VStack spacing={6}>
                  <Text fontSize="18px" color={overlayItemTextColor}>
                    <b>Bienvenue dans le <br />créateur de recettes!</b>
                    <br /><br />
                    Choisissez des aliments en les sélectionnant pour chaque composant afin de construire une recette réellement à votre goût.
                  </Text>

                  <HStack justify="space-between" p={4} bg={overlayItemBg} borderRadius="md">
                    <PiHandSwipeLeft color={overlayItemTextColor} size={36} />
                    <Box flex="1" textAlign="center" mx={2}>
                      <Text color={overlayItemTextColor} fontSize="16px">
                        Glissez pour passer aux composants suivants
                      </Text>
                    </Box>
                    <PiHandSwipeRight color={overlayItemTextColor} size={36} />
                  </HStack>

                  <VStack justify="space-between" p={4} bg={overlayItemBg} borderRadius="md">
                    <Box textAlign="center">
                      <Text color={overlayItemTextColor} fontSize="16px">
                        Voyez un résumé de votre recette ici
                      </Text>
                    </Box>
                    <PiArrowDown color={overlayItemTextColor} size={36} />
                  </VStack>
                </VStack>
              </Box>
            </VStack>
          )}
        </DrawerBody>

        {/* Modal for entering recipe name and description */}
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{funText.namePrompt}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>{funText.namePrompt}</FormLabel>
                <Input style={inputStyle} value={recipeName} onChange={(e) => setRecipeName(e.target.value)} />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>{funText.descriptionPrompt}</FormLabel>
                <Textarea value={recipeDescription} onChange={(e) => setRecipeDescription(e.target.value)} />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={() => setIsModalOpen(false)}>
                Annuler
              </Button>
              <Button
                colorScheme="primary"
                onClick={() => {
                  setIsModalOpen(false);
                  handleSave();
                }}
              >
                Sauvegarder
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Alert Dialog for Confirming Close */}
        <AlertDialog isOpen={isCloseAlertOpen} leastDestructiveRef={cancelCloseRef} onClose={() => setIsCloseAlertOpen(false)} isCentered>
          <AlertDialogOverlay>
            <AlertDialogContent m={8}>
              <AlertDialogHeader>{funText.confirmClose}</AlertDialogHeader>
              <AlertDialogFooter>
                <Button ref={cancelCloseRef} onClick={() => setIsCloseAlertOpen(false)}>
                  {funText.cancelClose}
                </Button>
                <Button colorScheme="red" onClick={onClose} ml={3}>
                  Fermer sans sauvegarder
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </DrawerContent>
    </Drawer>
  );
};
