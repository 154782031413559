import React, { useState } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";

const EditRecipeDrawer = ({ isOpen, onClose, recipe, onRecipeUpdate }) => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const toast = useToast();
  const [name, setName] = useState(recipe.name);
  const [description, setDescription] = useState(recipe.description || "");

  // Define ColorMode values
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${apiUrl}/api/v1/user/recipe/update/${recipe._id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            description,
            // Include other fields as needed
          }),
        }
      );

      if (response.ok) {
        toast({
          title: "Recette mise à jour",
          description: `${name} a été mise à jour.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onRecipeUpdate(); // Refresh the recipe list
        onClose();
      } else {
        throw new Error("Failed to update recipe");
      }
    } catch (error) {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la mise à jour.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
      <DrawerOverlay />
      <DrawerContent bg={bgColor} color={textColor}>
        <DrawerHeader>Modifier la recette</DrawerHeader>
        <DrawerBody>
          <FormControl mb={4}>
            <FormLabel>Nom</FormLabel>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nom de la recette"
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Description</FormLabel>
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description de la recette"
            />
          </FormControl>
          {/* Include other fields and controls as needed */}
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Annuler
          </Button>
          <Button colorScheme="primary" onClick={handleSave}>
            Sauvegarder
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default EditRecipeDrawer;
