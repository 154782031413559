import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Button,
  Text,
  Image,
  HStack,
  VStack,
  useColorModeValue,
  Icon,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tag,
  IconButton,
  Link,
} from "@chakra-ui/react";
import { AiFillStar } from "react-icons/ai";
import { FaChevronLeft, FaClock, FaUtensils } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import Placeholder from "../../../img/Placeholder1.jpg";
import CommunityRecipeGridItem from "../../2 - Components/CommunityRecipeGridItem"; // Import the recipe grid item component

const RecipeDetailDrawer = ({ isOpen, onClose, recipe, onAdd }) => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  // Définition des couleurs en fonction du mode
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("black", "white");
  const starColor = useColorModeValue("#e8b923", "yellow.300");
  const accBgColor = useColorModeValue("gray.100", "gray.700");
  const tagBgColor = useColorModeValue("primary.800", "gray.200");
  const tagTextColor = useColorModeValue("white", "primary.800");
  const textSubColor = useColorModeValue("gray.600", "gray.400");
  const [isDescriptionExpanded, setIsDescriptionExpanded] = React.useState(false);
  const [relatedRecipes, setRelatedRecipes] = useState([]);
  const [currentRecipe, setCurrentRecipe] = useState(recipe); // Track the currently selected recipe
  const [isDrawerOpen, setIsDrawerOpen] = useState(isOpen);
  const headerHeight = "35vh";

  // Fetch related recipes when the drawer is opened
  useEffect(() => {
    if (isDrawerOpen) {
      const fetchRelatedRecipes = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await fetch(`${apiUrl}/api/v1/recipe/recipe/related/${currentRecipe._id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }); // Endpoint to get related recipes
          const data = await response.json();
          setRelatedRecipes(data);
        } catch (error) {
          console.error("Error fetching related recipes:", error);
        }
      };
      fetchRelatedRecipes();
    }
  }, [isDrawerOpen, currentRecipe._id, apiUrl]);

  // Handle clicking on a related recipe
  const handleRelatedRecipeClick = (relatedRecipe) => {
    // Close the drawer first
    setIsDrawerOpen(false);

    // After the drawer is closed, set the new recipe and reopen the drawer
    setTimeout(() => {
      setCurrentRecipe(relatedRecipe);
      setIsDrawerOpen(true);
    }, 300); // Slight delay to allow for smooth closing and reopening of the drawer
  };

  return (
    <Drawer placement="right" onClose={onClose} isOpen={isDrawerOpen} size="full">
      <DrawerOverlay />
      <DrawerContent bg={bgColor}>
        <DrawerBody p={0} m={0}>
          {/* En-tête avec image de fond */}
          <Box position="relative" height={headerHeight} bg={bgColor} maxH="35%">
            <Image src={currentRecipe.cover || Placeholder} alt={currentRecipe.name} objectFit="cover" width="100%" height="100%" />
            <HStack position="absolute" top="10px" left="10px" right="10px" justifyContent="space-between" alignItems="center" zIndex="1">
              <IconButton icon={<FaChevronLeft />} size="md" color="white" bg="gray.700" onClick={onClose} borderRadius="full" aria-label="Fermer" />
              <Button leftIcon={<FiPlus />} colorScheme="primary" variant="solid" onClick={() => onAdd(currentRecipe)} size="md" borderRadius="full">
                Ajouter à mes favoris
              </Button>
            </HStack>
          </Box>

          <Box bg={bgColor} borderTopRadius="35px" position="relative" top="-35px" p={6}>
            {/* Nom de la recette et note étoilée */}
            <HStack>
              <Text fontSize="3xl" fontWeight="bold">
                {currentRecipe.name}
              </Text>
              <HStack>
                <Icon as={AiFillStar} color={starColor} />
                <Text fontSize="md" fontWeight="bold">
                  {currentRecipe.rating || "4.8"}
                </Text>
              </HStack>
            </HStack>
            <Text fontSize="xl" fontWeight="bold" color={useColorModeValue("gray.800", "white")}>
              Conçue par:{" "}
              <Link color={useColorModeValue("primary.500", "primary.300")} fontWeight="bold">
                Spicy Mittens
              </Link>
            </Text>

            <VStack align="start" spacing={4} mt={4}>
              <Box w="100%">
                <Text fontSize="lg" color={textSubColor}>
                  {isDescriptionExpanded
                    ? currentRecipe.description
                    : `${currentRecipe.description.slice(0, 130)}${currentRecipe.description.length > 130 ? "..." : ""}`}
                  {currentRecipe.description.length > 130 && (
                    <Button
                      variant="link"
                      color="primary.500"
                      onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
                      display="inline"
                      ml={1}
                    >
                      {isDescriptionExpanded ? "Lire moins" : "Lire plus"}
                    </Button>
                  )}
                </Text>
              </Box>

              {/* Temps de préparation et de cuisson */}
              <VStack spacing={4} alignItems="flex-start">
                <HStack alignItems="flex-start" justifyContent="flex-start">
                  <Text fontSize="lg" color={textSubColor}>
                    Préparation :
                  </Text>
                  <Icon as={FaClock} boxSize="20px" color={starColor} />
                  <Text fontSize="lg" fontWeight="500" color={textColor}>
                    {currentRecipe.estimatedPrepTime.amount} {currentRecipe.estimatedPrepTime.unit}
                  </Text>
                </HStack>
                <HStack alignItems="flex-start" justifyContent="flex-start">
                  <Text fontSize="lg" color={textSubColor}>
                    Cuisson :
                  </Text>
                  <Icon as={FaClock} boxSize="20px" color={starColor} />
                  <Text fontSize="lg" fontWeight="500" color={textColor}>
                    {currentRecipe.estimatedCookTime.amount} {currentRecipe.estimatedCookTime.unit}
                  </Text>
                </HStack>
              </VStack>
              <Box w="100%">
                <Accordion allowToggle>
                  <AccordionItem border="none">
                    {({ isExpanded }) => (
                      <>
                        <h2>
                          <AccordionButton
                            _expanded={{ bg: "primary.500", color: "white" }}
                            px={4}
                            py={3}
                            borderRadius="md"
                            boxShadow="md"
                            border="none"
                          >
                            <Box flex="1" textAlign="left">
                              <Text fontSize="lg" fontWeight="bold" color={isExpanded ? "white" : textColor}>
                                Ingrédients
                              </Text>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} bg={accBgColor} borderBottomRadius="20px" border="none">
                          {recipe.recipeComponents && recipe.recipeComponents.length > 0 ? (
                            recipe.recipeComponents.map((component) => (
                              <Box key={component.dishComponent._id} mb={4}>
                                {/* Nom de la composante */}
                                <HStack mb={2}>
                                  <Icon as={FaUtensils} color={starColor} />
                                  <Text fontSize="md" fontWeight="semibold" color={textColor}>
                                    {component.dishComponent.name}
                                  </Text>
                                </HStack>
                                {/* Liste des sous-catégories */}
                                <VStack align="start" pl={4} spacing={1}>
                                  {component.subCategories &&
                                    component.subCategories.length > 0 &&
                                    component.subCategories
                                      .filter((subCategory) => subCategory.food && subCategory.food.length > 0)
                                      .map((subCategory) => (
                                        <Box key={subCategory.subCategory._id} w="100%">
                                          <Text fontSize="sm" fontWeight="medium" color={textColor}>
                                            {subCategory.subCategory.name}
                                          </Text>
                                          {/* Liste des aliments */}
                                          <VStack align="start" pl={2} spacing={1}>
                                            {subCategory.food.map((foodItem) => (
                                              <HStack key={foodItem.food._id} w="100%" justifyContent="space-between">
                                                <Text fontSize="sm" color={textColor}>
                                                  {foodItem.food.name}
                                                </Text>
                                                <Tag fontSize="sm" color={tagTextColor} bg={tagBgColor}>
                                                  {foodItem.weight} {foodItem.units}
                                                </Tag>
                                              </HStack>
                                            ))}
                                          </VStack>
                                        </Box>
                                      ))}
                                </VStack>
                              </Box>
                            ))
                          ) : (
                            <Text fontSize="sm" color={textColor}>
                              Aucun ingrédient disponible.
                            </Text>
                          )}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </Accordion>
              </Box>
              {/* Related Recipes */}
              <Box mt={8} w="100%">
                <Text fontSize="lg" fontWeight="bold" mb={4}>
                  Recettes Similaires
                </Text>
                <ScrollMenu>
                  {relatedRecipes.length > 0 ? (
                    relatedRecipes.map((relatedRecipe) => (
                      <CommunityRecipeGridItem
                        key={relatedRecipe._id}
                        recipe={relatedRecipe}
                        isSelected={false}
                        onClick={() => handleRelatedRecipeClick(relatedRecipe)} // Handle the click
                        onClose={() => {}}
                      />
                    ))
                  ) : (
                    <Text>Aucune recette similaire trouvée.</Text>
                  )}
                </ScrollMenu>
              </Box>
            </VStack>
          </Box>
        </DrawerBody>

        {/* Pied de page (facultatif) */}
        {/* Vous pouvez enlever le DrawerFooter si non nécessaire */}
      </DrawerContent>
    </Drawer>
  );
};

export default RecipeDetailDrawer;
