// client\src\components\3 - Modal\3 - Blueprints Modals\BlueprintCreateContext.js

import React, { createContext, useContext, useState, useCallback } from 'react';

const BlueprintCreateContext = createContext();

export const useBlueprintCreate = () => {
  return useContext(BlueprintCreateContext);
};

export const BlueprintCreateProvider = ({ children, steps }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [wizardData, setWizardData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openWizard = useCallback((initialData = {}) => {
    setWizardData(initialData);
    setCurrentStepIndex(0);
    setIsModalOpen(true);
  }, []);

  const closeWizard = useCallback(() => {
    setIsModalOpen(false);
    setWizardData({});
    setCurrentStepIndex(0);
  }, []);

  const goToNextStep = useCallback(() => {
    let nextIndex = currentStepIndex + 1;
    while (nextIndex < steps.length && steps[nextIndex].shouldSkip?.(wizardData)) {
      nextIndex += 1;
    }
    if (nextIndex < steps.length) {
      setCurrentStepIndex(nextIndex);
    } else {
      // Optionally, handle the end of the wizard
      closeWizard();
    }
  }, [currentStepIndex, steps, wizardData, closeWizard]);

  const goToPreviousStep = useCallback(() => {
    let prevIndex = currentStepIndex - 1;
    while (prevIndex >= 0 && steps[prevIndex].shouldSkip?.(wizardData)) {
      prevIndex -= 1;
    }
    if (prevIndex >= 0) {
      setCurrentStepIndex(prevIndex);
    }
  }, [currentStepIndex, steps, wizardData]);

  const updateWizardData = useCallback((newData) => {
    if (typeof newData === 'function') {
      setWizardData((prevData) => newData(prevData));
    } else {
      setWizardData((prevData) => ({ ...prevData, ...newData }));
    }
  }, []);

  const value = {
    currentStepIndex,
    currentStep: steps[currentStepIndex],
    wizardData,
    isModalOpen,
    openWizard,
    closeWizard,
    goToNextStep,
    goToPreviousStep,
    updateWizardData,
    steps,
  };

  return (
    <BlueprintCreateContext.Provider value={value}>
      {children}
    </BlueprintCreateContext.Provider>
  );
};
