import { extendTheme } from "@chakra-ui/react";
import { theme as baseTheme } from "@saas-ui/react";
import "@fontsource-variable/inter";

const colors = {
  black: "#0f0f14",
  primary: {
    50: "#f3f8f7",
    100: "#d0e5de",
    200: "#a7cec0",
    300: "#75b29d",
    400: "#59a288",
    500: "#328c6c",
    600: "#0f7853",
    700: "#00613f",
    800: "#005236",
    900: "#003c27"
  },
  secondary: {
    50: "#f8f7f6",
    100: "#e4dfdc",
    200: "#cdc4be",
    300: "#b1a39a",
    400: "#a29186",
    500: "#8d796c",
    600: "#7c6555",
    700: "#6a4f3d",
    800: "#5e412e",
    900: "#4b2c17"
  },
  gray: {
    50: "#f9f9fb",
    100: "#f1f1f5",
    200: "#e7e7ed",
    300: "#d3d3de",
    400: "#ababc0",
    500: "#7c7c9c",
    600: "#52526c",
    700: "#353546",
    750: "#3a3a4d",
    800: "#1f1f29",
    900: "#191921",
  },
  purple: {
    50: "#f8f6fd",
    100: "#e4daf8",
    200: "#d0bff2",
    300: "#b397ea",
    400: "#9f7ce4",
    500: "#8356dd",
    600: "#6f3bd7",
    700: "#581cd1",
    800: "#4100c2",
    900: "#310094",
  },
  pink: {
    50: "#fdf5f9",
    100: "#f7d9e8",
    200: "#f1bad5",
    300: "#e88ebb",
    400: "#e16da8",
    500: "#d73c8a",
    600: "#cc066a",
    700: "#a90055",
    800: "#850043",
    900: "#640032",
  },
  red: {
    50: "#fdf6f6",
    100: "#f7d9d9",
    200: "#f0b8b8",
    300: "#e88d8d",
    400: "#e27373",
    500: "#da4b4b",
    600: "#d01919",
    700: "#ae0000",
    800: "#950000",
    900: "#6e0000",
  },
  orange: {
    50: "#fdfaf6",
    100: "#f8ebde",
    200: "#f0d4b7",
    300: "#e5b27f",
    400: "#da934a",
    500: "#d07418",
    600: "#b85d00",
    700: "#934a00",
    800: "#743a00",
    900: "#5f3000",
  },
  yellow: {
    50: "#fefefc",
    100: "#fbf9ed",
    200: "#f4edcb",
    300: "#ece0a3",
    400: "#e0cc68",
    500: "#c6a500",
    600: "#9f8400",
    700: "#7c6700",
    800: "#5d4d00",
    900: "#4d4000",
  },
  green: {
    50: "#f5fdf9",
    100: "#caf4df",
    200: "#8de8ba",
    300: "#3cd78a",
    400: "#00bf60",
    500: "#00a553",
    600: "#008844",
    700: "#006a35",
    800: "#00572c",
    900: "#004824",
  },
  teal: {
    50: "#f0fcfc",
    100: "#bef2f2",
    200: "#80e5e5",
    300: "#24d2d2",
    400: "#00b3b3",
    500: "#009999",
    600: "#007c7c",
    700: "#006161",
    800: "#005151",
    900: "#004242",
  },
  cyan: {
    50: "#f4fbfd",
    100: "#cfeff5",
    200: "#b9e7f1",
    300: "#9fdfeb",
    400: "#4ec3db",
    500: "#23b5d2",
    600: "#00a3c4",
    700: "#0087a2",
    800: "#006f85",
    900: "#005667",
  },
  blue: {
    50: "#f1f7fc",
    100: "#cce0f5",
    200: "#a6c9ed",
    300: "#7cb0e4",
    400: "#5498dc",
    500: "#2e81d4",
    600: "#0668cc",
    700: "#004fa0",
    800: "#004183",
    900: "#00356b",
  },
};

export const theme = extendTheme(
  {
    colors,
    global: {
      ":not(.chakra-dont-set-collapse) > .chakra-collapse": {
        overflow: "initial !important",
      },
    },
    fonts: {
      heading: `'Inter Variable', sans-serif`,
      body: `'Inter Variable', sans-serif`,
    },
    components: {
      Input: {
        sizes: {
          lg: {
            field: {
              borderRadius: "24px", // Setting border radius to 12px (xl)
              height: "48px",
              fontSize: "14px", // Large font size
              fontWeight: "normal", // Bold font weight for lg
              fontFamily: `'Inter Variable', sans-serif`,
            },
          },
          md: {
            field: {
              borderRadius: "20px", // Setting border radius to 12px (xl)
              height: "40px",
              fontSize: "14px", // Large font size
              fontWeight: "400", // Bold font weight for lg
              fontFamily: `'Inter Variable', sans-serif`,
            },
          },
          sm: {
            field: {
              borderRadius: "16px", // Setting border radius to 12px (xl)
              height: "32px",
              fontSize: "14px", // Large font size
              fontWeight: "400", // Bold font weight for lg
              fontFamily: `'Inter Variable', sans-serif`,
            },
          },
          xs: {
            field: {
              borderRadius: "12px", // Setting border radius to 12px (xl)
              height: "24px",
              fontSize: "14px", // Large font size
              fontWeight: "400", // Bold font weight for lg
              fontFamily: `'Inter Variable', sans-serif`,
            },
          },
        },
        defaultProps: {
          size: "md",
          variant: "outline",
        },
      },
      NumberInput: {
        sizes: {
          lg: {
            field: {
              borderRadius: "24px", // Setting border radius to 12px (xl)
              height: "48px",
              fontSize: "14px", // Large font size
              fontWeight: "normal", // Bold font weight for lg
              fontFamily: `'Inter Variable', sans-serif`,
            },
          },
          md: {
            field: {
              borderRadius: "20px", // Setting border radius to 12px (xl)
              height: "40px",
              fontSize: "14px", // Large font size
              fontWeight: "400", // Bold font weight for lg
              fontFamily: `'Inter Variable', sans-serif`,
            },
          },
          sm: {
            field: {
              borderRadius: "16px", // Setting border radius to 12px (xl)
              height: "32px",
              fontSize: "14px", // Large font size
              fontWeight: "400", // Bold font weight for lg
              fontFamily: `'Inter Variable', sans-serif`,
            },
          },
          xs: {
            field: {
              borderRadius: "12px", // Setting border radius to 12px (xl)
              height: "24px",
              fontSize: "14px", // Large font size
              fontWeight: "400", // Bold font weight for lg
              fontFamily: `'Inter Variable', sans-serif`,
            },
          },
        },
        defaultProps: {
          size: "md",
          variant: "outline",
        },
      },
    },
  },
  baseTheme
);

export default theme;
