import React, { useState } from "react";
import { Box, Text, Image, Flex, IconButton, Button, HStack, useColorModeValue } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { PiShareFat, PiPencilSimple, PiTrash, PiCopy, PiPlus } from "react-icons/pi";
import CustomAlert from "../3 - Modal/9 - Reusable Modals/CustomAlert";
import Placeholder from "../../img/Placeholder1.jpg";
import useCustomToast from "../5 - General/Utils/UtilsNotification";

const RecipeItem = ({ recipe, isSelected, onClick, onRecipeUpdate, onEditRecipe }) => {
  const [alertData, setAlertData] = useState(null);
  const customToast = useCustomToast();
  const apiUrl = process.env.REACT_APP_API_ENDPOINT || "https://api.penseetcuisine.ca";

  // Define ColorMode values
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const dateColor = useColorModeValue("gray.500", "gray.400");
  const borderSelectedColor = useColorModeValue("primary.700", "primary.400");
  const buttonDestructiveColorScheme = useColorModeValue("red", "red");
  const buttonColorScheme = useColorModeValue("secondary", "gray");
  const buttonAddToGroceryColorScheme = useColorModeValue("primary", "primary");
  const imageUrl = recipe.recipePicture && recipe.recipePicture !== "null" ? `${apiUrl}/${recipe.recipePicture}` : Placeholder;

  const handleDelete = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");
      const response = await fetch(`${apiUrl}/api/v1/user/recipe/delete/${recipe._id}/${userId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        customToast({
          title: "Recette supprimée",
          description: `${recipe.name} a été supprimée.`,
          status: "success",
        });
        onRecipeUpdate(); // Refresh the recipe list
        setAlertData(null); // Close the alert after successful deletion
      } else {
        throw new Error("Failed to delete recipe");
      }
    } catch (error) {
      customToast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la suppression.",
        status: "error",
      });
    }
  };

  const handleDuplicate = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");
      const response = await fetch(`${apiUrl}/api/v1/user/recipe/duplicate/${recipe._id}/${userId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        // eslint-disable-next-line no-unused-vars
        const data = await response.json();
        customToast({
          title: "Recette dupliquée",
          description: `${recipe.name} a été dupliquée.`,
          status: "success",
        });
        onRecipeUpdate(); // Refresh the recipe list
        setAlertData(null);
      } else {
        throw new Error("Failed to duplicate recipe");
      }
    } catch (error) {
      customToast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la duplication.",
        status: "error",
      });
    }
  };

  const handleAddToGroceryList = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");
      const response = await fetch(`${apiUrl}/api/v1/user/add-to-grocery-list/${userId}/${recipe._id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        customToast({
          title: "Ajouté à la liste d'épicerie",
          description: `${recipe.name} a été ajoutée à votre liste d'épicerie.`,
          status: "success",
        });
        setAlertData(null);
      } else {
        throw new Error("Failed to add to grocery list");
      }
    } catch (error) {
      customToast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de l'ajout à la liste d'épicerie.",
        status: "error",
      });
    }
  };

  // Open the delete confirmation alert
  const openDeleteAlert = (e) => {
    e.stopPropagation();
    setAlertData({
      variant: "destructive",
      title: "Confirmer la suppression",
      description: `Êtes-vous sûr de vouloir supprimer la recette "${recipe.name}"? Cette action est irréversible.`,
      onClose: () => setAlertData(null),
      onConfirm: handleDelete, // Perform the delete action
    });
  };

  // Open the duplicate confirmation alert
  const openDuplicateAlert = (e) => {
    e.stopPropagation();
    setAlertData({
      variant: "info",
      title: "Confirmer la duplication",
      description: `Êtes-vous sûr de vouloir dupliquer la recette "${recipe.name}"?`,
      onClose: () => setAlertData(null),
      onConfirm: handleDuplicate, // Perform the duplicate action
    });
  };

  // Open the add to grocery list confirmation alert
  const openAddToGroceryListAlert = (e) => {
    e.stopPropagation();
    setAlertData({
      variant: "info",
      title: "Confirmer l'ajout",
      description: `Voulez-vous ajouter la recette "${recipe.name}" à votre liste d'épicerie?`,
      onClose: () => setAlertData(null),
      onConfirm: handleAddToGroceryList, // Perform the add to grocery list action
    });
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    onEditRecipe(recipe);
  };

  const handleShare = (e) => {
    e.stopPropagation();
    // Implement sharing functionality here
    console.log("Share recipe:", recipe.name);
  };

  return (
    <motion.div
      layout
      onClick={onClick}
      style={{
        position: "relative",
        cursor: "pointer",
        marginBottom: "20px",
      }}
    >
      <Box
        w="100%"
        h={isSelected ? "300px" : "150px"}
        overflow="hidden"
        bg={bgColor}
        transition="height 0.3s ease"
        borderRadius="xl"
        border={isSelected ? "2px" : "0px"}
        borderColor={borderSelectedColor}
      >
        <Box position="relative" height="100%">
          <Image src={imageUrl} alt={recipe.name} objectFit="cover" width="100%" height="100%" borderRadius="xl" overflow="hidden" />

          {/* Buttons in Top-Right Corner when expanded */}
          {isSelected && (
            <Box position="absolute" top="10px" right="10px" left="10px">
              <HStack justifyContent="space-between">
                <Button
                  leftIcon={<PiPencilSimple fontSize="18px" />}
                  size="sm"
                  borderRadius="full"
                  colorScheme={buttonColorScheme}
                  onClick={handleEdit}
                >
                  Modifier
                </Button>
                <Button
                  leftIcon={<PiPlus fontSize="18px" />}
                  size="sm"
                  onClick={openAddToGroceryListAlert}
                  aria-label="Ajouter à la liste d'épicerie"
                  colorScheme={buttonAddToGroceryColorScheme}
                  borderRadius="full"
                >
                  Ajouter à la liste d'épicerie
                </Button>
              </HStack>
            </Box>
          )}

          {/* Name, Date, and Buttons Overlay */}
          <Box
            p={2}
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            bg={bgColor}
            color={textColor}
            opacity={isSelected ? 0.9 : 0.75}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {/* Name and Date */}
            <Box mb={isSelected ? 4 : 0}>
              <Text fontWeight="bold" fontSize="lg" color={textColor} isTruncated>
                {recipe.name}
              </Text>
              <Text fontSize="xs" color={dateColor}>
                Créé le {recipe.createdAt ? new Date(recipe.createdAt).toLocaleDateString() : "Date inconnue"}
              </Text>
            </Box>

            {/* Buttons when expanded */}
            {isSelected && (
              <Flex justifyContent="space-between" alignItems="center" w="100%">
                <HStack justifyContent="end" w="100%">
                  <IconButton
                    icon={<PiShareFat fontSize="18px" />}
                    borderRadius="full"
                    size="sm"
                    onClick={handleShare}
                    colorScheme={buttonColorScheme}
                  />
                  <IconButton
                    icon={<PiCopy fontSize="18px" />}
                    borderRadius="full"
                    size="sm"
                    colorScheme={buttonColorScheme}
                    onClick={openDuplicateAlert}
                  />
                  <IconButton
                    icon={<PiTrash fontSize="18px" />}
                    borderRadius="full"
                    size="sm"
                    colorScheme={buttonDestructiveColorScheme}
                    onClick={openDeleteAlert}
                  />
                </HStack>
              </Flex>
            )}
          </Box>
        </Box>
      </Box>

      {/* Render the CustomAlert if there's alert data */}
      {alertData && (
        <CustomAlert
          isOpen={alertData !== null}
          variant={alertData.variant}
          title={alertData.title}
          description={alertData.description}
          onClose={() => setAlertData(null)}
          onConfirm={alertData.onConfirm} // Optional confirm action (for destructive actions)
        />
      )}
    </motion.div>
  );
};

export default RecipeItem;
