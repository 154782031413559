import React, { useState } from 'react';
import { forgotPassword } from '../4 - API/API-Auth';
import { Box, FormControl, FormLabel, Input, Button, Text, VStack, useColorModeValue } from '@chakra-ui/react';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const bgColor = useColorModeValue('white', 'white');
  const formBgColor = useColorModeValue('gray.100', 'gray.100');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = await forgotPassword(email);
      setMessage(data.message || 'Le lien de réinitialisation du mot de passe a été envoyé.');
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <Box className="forgot-password-container" bg={bgColor} minH="100vh" display="flex" justifyContent="center" alignItems="center">
      <Box className="forgot-password-content" w="100%" maxW="md" p={8} borderRadius="md" boxShadow="lg" bg={formBgColor}>
        <form onSubmit={handleSubmit} className="forgot-password-form">
          <VStack spacing={4}>
            <Text fontSize="2xl" fontWeight="bold">Mot de passe oublié</Text>
            <FormControl id="email">
              <FormLabel>Entrez votre courriel associé au compte:</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormControl>
            <Button
              type="submit"
              colorScheme='primary'
              width="full"
            >
              Envoyer un lien de réinitialisation
            </Button>
            {message && <Text>{message}</Text>}
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

export default ForgotPasswordPage;
