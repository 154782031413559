import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Image,
  Button,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useBlueprintCreate } from "../../3 - Modal/3 - Blueprints Modals/BlueprintCreateContext";

const BlueprintStepOne = () => {
  const { wizardData, updateWizardData } = useBlueprintCreate();
  const [localData, setLocalData] = useState(
    wizardData.stepOne || {
      name: "",
      description: "",
      totalAllowedWeight: "",
      cuisineType: [],
      imagePreview: null,
      cuisineTypeOptions: [],
    }
  );

  // Define colors for light and dark mode
  const bg = useColorModeValue("gray.50", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const inputBg = useColorModeValue("white", "gray.700");
  const tooltipBg = useColorModeValue("gray.100", "gray.700");
  const buttonColorScheme = useColorModeValue("primary", "primary");

  useEffect(() => {
    updateWizardData({ stepOne: localData });
  }, [localData, updateWizardData]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      const apiUrl = process.env.REACT_APP_API_ENDPOINT;

      if (!token) {
        console.error("Authorization token missing");
        return;
      }
      try {
        const [cuisineTypesResponse] = await Promise.all([
          fetch(`${apiUrl}/api/v1/blueprint/dish-type`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);
        const cuisineTypesData = await cuisineTypesResponse.json();

        setLocalData((prevData) => ({
          ...prevData,
          cuisineTypeOptions: cuisineTypesData.map((type) => ({
            value: type._id,
            label: type.name,
          })),
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleChange = (field, value) => {
    setLocalData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLocalData((prevData) => ({
        ...prevData,
        imageFile: file,
        imagePreview: URL.createObjectURL(file),
      }));
    }
  };

  const { name, description, cuisineType, imagePreview, totalAllowedWeight, cuisineTypeOptions } = localData;

  return (
    <Box padding="5" margin="auto" maxW="900px" borderWidth="1px" borderRadius="lg" bg={bg} borderColor={borderColor}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} alignItems="center">
        {/* Image Section */}
        <Box display="flex" height="100%">
          <FormControl>
            <FormLabel>
              <Tooltip
                label="Téléchargez une image pour représenter visuellement le blueprint. Cette Image sera l'image principale que les utilisateurs voient. Grosseur recommandée, 400px par 400px, par souci de performances."
                fontSize="md"
                bg={tooltipBg}
              >
                Importer l'image du blueprint
              </Tooltip>
            </FormLabel>
            <Image
              src={imagePreview || "https://placehold.co/400x400"}
              margin="auto"
              objectFit="cover"
              borderRadius="md"
              border="1px solid #ccc"
              maxH="100%"
              minH="200px"
            />
            <Button as="label" cursor="pointer" colorScheme={buttonColorScheme} mt={4}>
              Importer une image du plat
              <Input type="file" hidden onChange={handleImageUpload} accept="image/*" />
            </Button>
          </FormControl>
        </Box>

        {/* Form Section */}
        <VStack spacing="6" align="stretch">
          {/* Dish Name */}
          <FormControl isRequired>
            <FormLabel>
              <Tooltip label="Donnez un nom unique à votre blueprint pour l'identifier facilement." fontSize="md" bg={tooltipBg}>
                Nom du blueprint
              </Tooltip>
            </FormLabel>
            <Input placeholder="Entrez le nom du blueprint" value={name} onChange={(e) => handleChange("name", e.target.value)} bg={inputBg} />
          </FormControl>

          {/* Description */}
          <FormControl isRequired>
            <FormLabel>
              <Tooltip
                label="Décrivez brièvement le blueprint en expliquant ses composants ou son objectif. Maximum de 3 lignes."
                fontSize="md"
                bg={tooltipBg}
              >
                Description
              </Tooltip>
            </FormLabel>
            <Textarea
              placeholder="Décrivez brièvement le blueprint..."
              value={description}
              onChange={(e) => handleChange("description", e.target.value)}
              bg={inputBg}
            />
          </FormControl>

          {/* Total Allowed Weight of the dish */}
          <FormControl isRequired>
            <FormLabel>
              <Tooltip label="Indiquez le poids total recommandé pour ce blueprint (en grammes)." fontSize="md" bg={tooltipBg}>
                Poids total recommandé du plat (g)
              </Tooltip>
            </FormLabel>
            <NumberInput min={0} value={totalAllowedWeight}>
              <NumberInputField
                placeholder="Entrez le poids total recommandé du plat"
                onChange={(e) => handleChange("totalAllowedWeight", e.target.value)}
                bg={inputBg}
              />
            </NumberInput>
          </FormControl>

          {/* Cuisine Type (Multiple selection) */}
          <FormControl>
            <FormLabel>
              <Tooltip label="Sélectionnez la famille de repas correspondant à ce blueprint (choix multiple possible)." fontSize="md" bg={tooltipBg}>
                Famille de repas
              </Tooltip>
            </FormLabel>
            <Select
              isMulti
              placeholder="Sélectionnez les familles de repas"
              options={cuisineTypeOptions}
              value={cuisineType}
              onChange={(selectedOptions) => handleChange("cuisineType", selectedOptions)}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </FormControl>
        </VStack>
      </SimpleGrid>
    </Box>
  );
};

export default BlueprintStepOne;
