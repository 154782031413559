// API-Auth.js
const apiUrl = process.env.REACT_APP_API_ENDPOINT;
/**
 * API call to login a user
 * @param {string} email - User's email
 * @param {string} password - User's password
 * @returns {object} - The response data from the backend
 */
export const login = async (email, password) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Erreur lors de la connexion");
    }

    return data; // Success response (token, user info, etc.)
  } catch (error) {
    throw error;
  }
};

/**
 * API call to send forgot password email
 * @param {string} email - User's email
 * @returns {object} - The response message from the backend
 */
// 4 - API/API-Auth.js
export const forgotPassword = async (email) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/auth/forgot-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Erreur lors de l'envoi du courriel de réinitialisation du mot de passe.");
    }

    return data;
  } catch (error) {
    throw new Error(error.message || "Erreur lors de l'envoi du courriel de réinitialisation du mot de passe.");
  }
};
/**
 * API call to reset password
 * @param {string} token - Reset token from the email link
 * @param {string} newPassword - New password to set
 * @returns {object} - The response message from the backend
 */
export const resetPassword = async (token, newPassword) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/auth/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, newPassword }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Erreur lors de la réinitialisation du mot de passe");
    }

    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * API call to register a user
 * @param {object} formData - User's registration details
 * @returns {object} - The response data from the backend
 */
export const register = async (formData) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Erreur lors de l'inscription");
    }

    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * API call to verify email
 * @param {string} token - Verification token from the email link
 * @returns {object} - The response message from the backend
 */
export const verifyEmail = async (token) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/auth/verify-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Erreur lors de la vérification de l'email");
    }

    return data;
  } catch (error) {
    throw error;
  }
};
