import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  Tag,
  Tooltip,
  TagLabel,
  Checkbox,
  TagCloseButton,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spinner,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import { fetchSubCategories, fetchFoodsBySubCategory } from "../../5 - General/Utils/UtilsOptionsDefinitions";
import { FaEllipsisV } from "react-icons/fa";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../../5 - General/Utils/UtilsStrictModeDroppable";

const DishComponentEditModal = ({ isOpen, onClose, dishComponent, onSave }) => {
  const [name, setName] = useState(dishComponent.name || "");
  const [isWeighted, setIsWeighted] = useState(dishComponent.isWeighted || true);
  const [percentage, setPercentage] = useState(dishComponent.percentage || 0);
  const [maxFoods, setMaxFoods] = useState(dishComponent.maxFoods || 0);

  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [alimentsData, setAlimentsData] = useState({});
  const [selectedAliments, setSelectedAliments] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const customToast = useCustomToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subcategories = await fetchSubCategories();
        setSubcategories(subcategories);

        // Initialize selected subcategories from dishComponent data
        const selectedSubcatOptions = subcategories.filter((subcat) => dishComponent.subCategories.some((sc) => sc.subCategory === subcat.value));
        setSelectedSubcategories(selectedSubcatOptions);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dishComponent]);

  useEffect(() => {
    const fetchAliments = async () => {
      if (selectedSubcategories.length === 0) return;
      setIsLoading(true);
      try {
        const alimentsBySousCategorie = { ...alimentsData };
        const initialSelectedAliments = { ...selectedAliments };

        for (const subcat of selectedSubcategories) {
          // Avoid re-fetching aliments for subcategories we already have data for
          if (!alimentsBySousCategorie[subcat.value]) {
            const aliments = await fetchFoodsBySubCategory(subcat.value);
            alimentsBySousCategorie[subcat.value] = aliments;
          }

          const scData = dishComponent.subCategories.find((sc) => sc.subCategory === subcat.value);

          if (scData) {
            // scData.food is an array of food items, each with 'food' and 'level'
            const scFoodItems = scData.food;

            const selectedAlimentsForSubcat = alimentsBySousCategorie[subcat.value]
              .filter((aliment) => scFoodItems.some((foodItem) => foodItem.food.toString() === aliment._id.toString()))
              .map((aliment) => {
                const foodItem = scFoodItems.find((item) => item.food.toString() === aliment._id.toString());
                return {
                  value: aliment._id,
                  label: aliment.name,
                  level: foodItem.level || "basic",
                };
              });

            initialSelectedAliments[subcat.value] = selectedAlimentsForSubcat;
          } else {
            initialSelectedAliments[subcat.value] = [];
          }
        }

        setAlimentsData(alimentsBySousCategorie);
        setSelectedAliments(initialSelectedAliments);
      } catch (error) {
        console.error("Error fetching aliments:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAliments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubcategories]);

  const handleSubcategoriesChange = async (selectedOptions) => {
    const newSelectedSubcategories = selectedOptions || [];
    const previousSubcatIds = selectedSubcategories.map((subcat) => subcat.value);
    const newSubcatIds = newSelectedSubcategories.map((subcat) => subcat.value);

    // Find subcategories that were added
    const addedSubcatIds = newSubcatIds.filter((id) => !previousSubcatIds.includes(id));

    // For each added subcategory, fetch aliments and select them all
    setIsLoading(true);
    try {
      const alimentsBySousCategorie = { ...alimentsData };
      const updatedSelectedAliments = { ...selectedAliments };

      for (const subcatId of addedSubcatIds) {
        const aliments = await fetchFoodsBySubCategory(subcatId);
        alimentsBySousCategorie[subcatId] = aliments;

        const selectedAlimentsForSubcat = aliments.map((aliment) => ({
          value: aliment._id,
          label: aliment.name,
          level: "basic", // default level
        }));
        updatedSelectedAliments[subcatId] = selectedAlimentsForSubcat;
      }

      setAlimentsData(alimentsBySousCategorie);
      setSelectedAliments(updatedSelectedAliments);
    } catch (error) {
      console.error(`Error fetching aliments for subcategories:`, error);
    } finally {
      setIsLoading(false);
    }

    setSelectedSubcategories(newSelectedSubcategories);
    // Remove aliments data for subcategories that are no longer selected
    const selectedSubcatIds = newSubcatIds;
    setSelectedAliments((prev) => {
      const updatedSelectedAliments = {};
      for (const subcatId of selectedSubcatIds) {
        updatedSelectedAliments[subcatId] = prev[subcatId] || [];
      }
      return updatedSelectedAliments;
    });
  };

  const handleAlimentsChange = (sousCategorieId, selectedOptions) => {
    setSelectedAliments((prev) => {
      const previousAliments = prev[sousCategorieId] || [];
      const updatedAliments = selectedOptions.map((option) => {
        const existingAliment = previousAliments.find((a) => a.value === option.value);
        if (existingAliment) {
          return existingAliment; // Keep existing level
        } else {
          return { ...option, level: "basic" }; // Default to 'basic' level
        }
      });
      return {
        ...prev,
        [sousCategorieId]: updatedAliments,
      };
    });
  };

  // Function to update the level of an aliment
  const updateAlimentLevel = (sousCategorieId, alimentId, newLevel) => {
    setSelectedAliments((prev) => {
      const updatedAliments = prev[sousCategorieId]?.map((aliment) => {
        if (aliment.value === alimentId) {
          return { ...aliment, level: newLevel };
        }
        return aliment;
      });
      return {
        ...prev,
        [sousCategorieId]: updatedAliments,
      };
    });
  };

  const handleSave = () => {
    const updatedSubCategories = selectedSubcategories.map((subcat) => ({
      subCategory: subcat.value,
      subCategoryName: subcat.label,
      food: (selectedAliments[subcat.value] || []).map((aliment) => ({
        food: aliment.value,
        foodName: aliment.label, // Include foodName here
        level: aliment.level || "basic",
      })),
    }));

    const updatedComponent = {
      ...dishComponent,
      name,
      isWeighted,
      percentage,
      maxFoods,
      subCategories: updatedSubCategories,
    };
    onSave(updatedComponent);
    customToast({
      status: "success",
      title: "Composante mise à jour.",
      description: "La composante a été mise à jour avec succès.",
    });
  };

  const menuPortalTarget = typeof document !== "undefined" ? document.body : null;

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newOrder = Array.from(selectedSubcategories);
    const [movedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedItem);

    setSelectedSubcategories(newOrder);
  };

  // Custom MultiValue component for the Select component
  const CustomMultiValue = (props) => {
    const { data, selectProps, removeProps } = props;
    const { subcatId, updateAlimentLevel } = selectProps;
    const isAdvanced = data.level === "advanced";

    return (
      <Tooltip label={isAdvanced ? "Avancé" : "Débutant"} placement="top" hasArrow>
        <Tag
          m="2px"
          px={2}
          py={2}
          borderRadius="full"
          variant="solid"
          bg="gray.100"
          border="1px solid"
          borderColor="gray.500"
          color="gray.600"
          transition="all 0.1s ease"
          cursor="default"
        >
          <TagCloseButton
            mr={2}
            ml={0}
            pl={0}
            backgroundColor="gray.500"
            color="white"
            onClick={removeProps.onClick}
            _focus={{ boxShadow: "none" }}
            _hover={{ backgroundColor: "red.700", color: "white" }}
          />
          <Checkbox
            isChecked={isAdvanced}
            onChange={(e) => {
              const newLevel = e.target.checked ? "advanced" : "basic";
              updateAlimentLevel(subcatId, data.value, newLevel);
            }}
            borderColor="gray.500"
            size="md"
            mr={3}
            pointerEvents="auto"
          />
          <TagLabel>{data.label}</TagLabel>
        </Tag>
      </Tooltip>
    );
  };

  const CustomMultiValueSubCat = (props) => {
    const { data, removeProps } = props;
    return (
      <Tooltip label="Désélectionner" placement="top" hasArrow>
        <Tag
          m="2px"
          px={3}
          py={1}
          borderRadius="full"
          variant="solid"
          bg="gray.100"
          border="1px solid"
          borderColor="gray.500"
          color="gray.600"
          transition="all 0.1s ease"
          cursor="pointer"
          _hover={{ borderColor: "red.500", color: "white", bg: "red.500" }}
          onClick={removeProps.onClick}
        >
          <TagLabel>{data.label}</TagLabel>
        </Tag>
      </Tooltip>
    );
  };

  const defaultIndices = selectedSubcategories.map((_, index) => index);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" scrollBehavior="inside" closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent maxHeight="90vh" overflowY="hidden">
        <ModalHeader>Modifier les sous-catégories et les aliments pour la composante: {name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <FormControl mb={4}>
            <FormLabel>Nom de la composante</FormLabel>
            <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Nom de la composante" />
          </FormControl>
          <FormControl mb={4}>
            <Checkbox isChecked={isWeighted} onChange={(e) => setIsWeighted(e.target.checked)}>
              Cette composante fonctionne au poids (si non coché, fonctionne à l'unité seulement)
            </Checkbox>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Pourcentage</FormLabel>
            <NumberInput value={percentage} onChange={(valueString) => setPercentage(parseFloat(valueString))} min={0} max={100}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Nombre maximal d'aliments</FormLabel>
            <NumberInput value={maxFoods} onChange={(valueString) => setMaxFoods(parseInt(valueString))} min={0}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Sous-catégories</FormLabel>
            <Select
              options={subcategories}
              isMulti
              value={selectedSubcategories}
              onChange={handleSubcategoriesChange}
              placeholder="Sélectionnez des sous-catégories"
              closeMenuOnSelect={false}
              hideSelectedOptions={true}
              components={{
                MultiValue: CustomMultiValueSubCat,
                ClearIndicator: null,
              }}
              menuPortalTarget={menuPortalTarget}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </FormControl>

          {isLoading ? (
            <Box display="flex" justifyContent="center" mt={4}>
              <Spinner size="xl" />
            </Box>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <StrictModeDroppable droppableId="subcategoriesDroppable">
                {(provided) => (
                  <Accordion allowMultiple defaultIndex={defaultIndices} ref={provided.innerRef} {...provided.droppableProps}>
                    {selectedSubcategories.map((subcat, index) => {
                      const selectedCount = selectedAliments[subcat.value]?.length || 0;

                      const hasSelection = selectedCount > 0;
                      const tagColor = hasSelection ? "green" : "red";

                      return (
                        <Draggable key={subcat.value} draggableId={subcat.value} index={index}>
                          {(provided) => (
                            <Box ref={provided.innerRef} {...provided.draggableProps}>
                              <AccordionItem key={subcat.value}>
                                <Box display="flex" alignItems="center">
                                  <Box {...provided.dragHandleProps} p={2} cursor="grab">
                                    <FaEllipsisV />
                                  </Box>
                                  <AccordionButton>
                                    <HStack flex="1" textAlign="left">
                                      <Tag colorScheme={tagColor}>{selectedCount}</Tag>
                                      <Box>{subcat.label}</Box>
                                    </HStack>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </Box>
                                <AccordionPanel pb={4}>
                                  {isLoading ? (
                                    <Text>Chargement des aliments...</Text>
                                  ) : (
                                    <>
                                      {/* Select component for aliments */}
                                      <Select
                                        isMulti
                                        name={`aliments-${subcat.value}`}
                                        options={
                                          alimentsData[subcat.value]?.map((aliment) => {
                                            const existingAliment = selectedAliments[subcat.value]?.find((a) => a.value === aliment._id);
                                            return {
                                              value: aliment._id,
                                              label: aliment.name,
                                              level: existingAliment?.level || "basic",
                                            };
                                          }) || []
                                        }
                                        placeholder="Sélectionnez des aliments..."
                                        closeMenuOnSelect={false}
                                        onChange={(selectedOptions) => {
                                          handleAlimentsChange(subcat.value, selectedOptions);
                                        }}
                                        menuPortalTarget={menuPortalTarget}
                                        menuPosition="fixed"
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                        }}
                                        value={selectedAliments[subcat.value]}
                                        hideSelectedOptions={true}
                                        components={{
                                          MultiValue: CustomMultiValue,
                                          ClearIndicator: null,
                                        }}
                                        subcatId={subcat.value} // Pass subcatId directly
                                        updateAlimentLevel={updateAlimentLevel} // Pass the function directly
                                      />
                                    </>
                                  )}
                                </AccordionPanel>
                              </AccordionItem>
                            </Box>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Accordion>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Annuler
          </Button>
          <Button colorScheme="primary" onClick={handleSave}>
            Enregistrer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DishComponentEditModal;
