import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Text, Tabs, TabList, TabPanels, Tab, TabPanel, Spinner, Center, TabIndicator, Button, HStack, VStack } from "@chakra-ui/react";

// Import table components
import FoodGroupTable from "./Gestion/FoodGroupTable";
import DepartmentTable from "./Gestion/DepartmentTable";
import SubCategoryTable from "./Gestion/SubCategoryTable";
import CategoryTable from "./Gestion/CategoryTable";
import FoodTable from "./Gestion/FoodTable";
import DishTypeTable from "./Gestion/DishTypeTable";

// Import form modals
import FoodGroupFormModal from "../3 - Modal/2 - Gestion Modals/FoodGroupFormModal";
import DepartmentFormModal from "../3 - Modal/2 - Gestion Modals/DepartmentFormModal";
import SubCategoryFormModal from "../3 - Modal/2 - Gestion Modals/SubCategoryFormModal";
import CategoryFormModal from "../3 - Modal/2 - Gestion Modals/CategoryFormModal";
import FoodFormModal from "../3 - Modal/2 - Gestion Modals/FoodFormModal";
import DishTypeFormModal from "../3 - Modal/2 - Gestion Modals/DishTypeFormModal";

// Import CSV Upload and Download modals
import CsvUploadModal from "../3 - Modal/2 - Gestion Modals/CsvUploadModal";
import CsvDownloadModal from "../3 - Modal/2 - Gestion Modals/CsvDownloadModal";

// Import custom toast
import useCustomToast from "../5 - General/Utils/UtilsNotification";

const Gestion = () => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [data, setData] = useState({
    foodGroups: null,
    departments: null,
    subCategories: null,
    categories: null,
    foods: null,
    dishTypes: null,
  });
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const customToast = useCustomToast();
  const token = localStorage.getItem("token");

  // State to control modals
  const [modals, setModals] = useState({
    isFoodGroupModalOpen: false,
    isDepartmentModalOpen: false,
    isSubCategoryModalOpen: false,
    isCategoryModalOpen: false,
    isFoodModalOpen: false,
    isDishTypeModalOpen: false,
  });

  // States to hold the item being edited
  const [currentItem, setCurrentItem] = useState({
    currentFoodGroup: null,
    currentDepartment: null,
    currentSubCategory: null,
    currentCategory: null,
    currentFood: null,
    currentDishType: null,
  });

  const openModal = (modalName) => setModals((prev) => ({ ...prev, [modalName]: true }));
  const closeModal = (modalName, itemName) => {
    setModals((prev) => ({ ...prev, [modalName]: false }));
    setCurrentItem((prev) => ({ ...prev, [itemName]: null }));
  };

  const tabDataMap = useMemo(() => ({
    0: {
      dataKeys: ["foods", "foodGroups", "departments", "categories", "subCategories", "dishTypes"],
      endpoints: {
        foods: "food",
        foodGroups: "food-group",
        departments: "department",
        categories: "category",
        subCategories: "sub-category",
        dishTypes: "dish-type",
      },
    },
    1: {
      dataKeys: ["subCategories"],
      endpoints: {
        subCategories: "sub-category",
      },
    },
    2: {
      dataKeys: ["categories"],
      endpoints: {
        categories: "category",
      },
    },
    3: {
      dataKeys: ["departments"],
      endpoints: {
        departments: "department",
      },
    },
    4: {
      dataKeys: ["foodGroups"],
      endpoints: {
        foodGroups: "food-group",
      },
    },
    5: {
      dataKeys: ["dishTypes"],
      endpoints: {
        dishTypes: "dish-type",
      },
    },
  }), []);

  const fetchDataForTab = useCallback(
    async (tabIndex, force = false) => {
      const tabInfo = tabDataMap[tabIndex];
      if (!tabInfo) return;

      const { dataKeys, endpoints } = tabInfo;

      setLoading(true);

      try {
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };

        const promises = dataKeys.map(async (dataKey) => {
          if (data[dataKey] && !force) return;

          const endpoint = endpoints[dataKey];
          const response = await fetch(`${apiUrl}/api/v1/blueprint/${endpoint}`, { headers });

          if (!response.ok) {
            throw new Error(`Failed to fetch ${dataKey}`);
          }

          const responseData = await response.json();

          setData((prevData) => ({
            ...prevData,
            [dataKey]: responseData,
          }));
        });

        await Promise.all(promises);
      } catch (error) {
        console.error("Error fetching data:", error);
        customToast({
          status: "error",
          title: "Erreur",
          description: "Échec de la récupération des données du serveur.",
        });
      } finally {
        setLoading(false);
      }
    },
    [apiUrl, token, customToast, data, tabDataMap]
  );

  useEffect(() => {
    fetchDataForTab(tabIndex);
  }, [fetchDataForTab, tabIndex]);

  const fetchDataForCsv = async (category) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    const endpointMap = {
      food: "food",
      subCategory: "sub-category",
      category: "category",
      department: "department",
      foodGroup: "food-group",
      dishType: "dish-type",
    };

    const endpoint = `${apiUrl}/api/v1/blueprint/${endpointMap[category]}`;

    const response = await fetch(endpoint, { headers });
    if (!response.ok) {
      throw new Error("Failed to fetch data for CSV");
    }

    const data = await response.json();
    return data;
  };

  const handleSave = () => {
    fetchDataForTab(tabIndex, true);
  };

  const handleEdit = (item, itemName, modalName) => {
    setCurrentItem((prev) => ({ ...prev, [itemName]: item }));
    openModal(modalName);
  };

  const handleDelete = async (id, endpoint, successMessage, errorMessage) => {
    try {
      await fetch(`${apiUrl}/api/v1/blueprint/${endpoint}/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      customToast({
        status: "success",
        title: "Succès",
        description: successMessage,
      });
      fetchDataForTab(tabIndex, true);
    } catch (error) {
      console.error(errorMessage, error);
      customToast({
        status: "error",
        title: "Erreur",
        description: errorMessage,
      });
    }
  };

  return (
    <Box p={0} overflow="hidden" height="100%">
      <VStack justify="start" w="100%">
        <HStack w="100%" justifyContent="space-between" mb={2} p={8} pb={0}>
          <Text fontSize="3xl" fontWeight="bold">
            Gestion des données
          </Text>
          <HStack>
            <CsvUploadModal onSuccess={() => fetchDataForTab(tabIndex, true)} />
            <CsvDownloadModal fetchDataForCsv={fetchDataForCsv} />
          </HStack>
        </HStack>
        <Text w="100%" justifyContent="flex-start" flex="1" fontWeight="400" fontSize="16px" p={8} pt={0} mb={2}>
          Cette page vous permet de gérer les groupes alimentaires, les départements, les sous-catégories et les aliments.
        </Text>
      </VStack>
      <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)} variant="unstyled" colorScheme="primary" height="100%">
        <TabList p={8} pt={4} pb={0}>
          <Tab>Aliments</Tab>
          <Tab>Sous-catégories d'aliments</Tab>
          <Tab>Catégories d'aliments</Tab>
          <Tab>Rayons d'épicerie</Tab>
          <Tab>Groupes alimentaires</Tab>
          <Tab>Types de plats</Tab>
        </TabList>

        <TabIndicator mt="-1.5px" height="4px" bg="primary.500" borderRadius="1px" />
        <TabPanels borderTop="1px solid" borderColor="gray.300" p={8} pt={4} pb={0} height="100%">
          {/* Food Tab */}
          <TabPanel>
            {loading || data.foods === null ? (
              <Center minHeight="100vh">
                <Spinner size="xl" />
              </Center>
            ) : (
              <VStack justify="start" w="100%">
                <HStack w="100%" justifyContent="flex-end">
                  <Button
                    colorScheme="primary"
                    mb={4}
                    onClick={() => {
                      setCurrentItem((prev) => ({
                        ...prev,
                        currentFood: null,
                      }));
                      openModal("isFoodModalOpen");
                    }}
                  >
                    Ajouter un nouvel aliment
                  </Button>
                </HStack>
                <FoodTable
                  data={data.foods || []}
                  onEdit={(food) => handleEdit(food, "currentFood", "isFoodModalOpen")}
                  onDelete={(id) => handleDelete(id, "food", "Aliment supprimé.", "Échec de la suppression de l'aliment.")}
                />
                <FoodFormModal
                  isOpen={modals.isFoodModalOpen}
                  onClose={() => closeModal("isFoodModalOpen", "currentFood")}
                  onSave={handleSave}
                  initialData={currentItem.currentFood}
                  foodGroups={data.foodGroups}
                  departments={data.departments}
                  subCategories={data.subCategories}
                  categories={data.categories}
                  dishTypes={data.dishTypes}
                />
              </VStack>
            )}
          </TabPanel>
          {/* SubCategories Tab */}
          <TabPanel>
            {loading || data.subCategories === null ? (
              <Center minHeight="100vh">
                <Spinner size="xl" />
              </Center>
            ) : (
              <VStack justify="start" w="100%">
                <HStack w="100%" justifyContent="flex-end">
                  <Button
                    colorScheme="primary"
                    mb={4}
                    onClick={() => {
                      setCurrentItem((prev) => ({
                        ...prev,
                        currentSubCategory: null,
                      }));
                      openModal("isSubCategoryModalOpen");
                    }}
                  >
                    Ajouter une nouvelle sous-catégorie
                  </Button>
                </HStack>
                <SubCategoryTable
                  data={data.subCategories}
                  onEdit={(subCategory) => handleEdit(subCategory, "currentSubCategory", "isSubCategoryModalOpen")}
                  onDelete={(id) => handleDelete(id, "sub-category", "Sous-catégorie supprimée.", "Échec de la suppression de la sous-catégorie.")}
                />
                <SubCategoryFormModal
                  isOpen={modals.isSubCategoryModalOpen}
                  onClose={() => closeModal("isSubCategoryModalOpen", "currentSubCategory")}
                  onSave={handleSave}
                  initialData={currentItem.currentSubCategory}
                />
              </VStack>
            )}
          </TabPanel>
          {/* Categories Tab */}
          <TabPanel>
            {loading || data.categories === null ? (
              <Center minHeight="100vh">
                <Spinner size="xl" />
              </Center>
            ) : (
              <VStack justify="start" w="100%">
                <HStack w="100%" justifyContent="flex-end">
                  <Button
                    colorScheme="primary"
                    mb={4}
                    onClick={() => {
                      setCurrentItem((prev) => ({
                        ...prev,
                        currentCategory: null,
                      }));
                      openModal("isCategoryModalOpen");
                    }}
                  >
                    Ajouter une nouvelle catégorie
                  </Button>
                </HStack>
                <CategoryTable
                  data={data.categories}
                  onEdit={(category) => handleEdit(category, "currentCategory", "isCategoryModalOpen")}
                  onDelete={(id) => handleDelete(id, "category", "Catégorie supprimée.", "Échec de la suppression de la catégorie.")}
                />
                <CategoryFormModal
                  isOpen={modals.isCategoryModalOpen}
                  onClose={() => closeModal("isCategoryModalOpen", "currentCategory")}
                  onSave={handleSave}
                  initialData={currentItem.currentCategory}
                />
              </VStack>
            )}
          </TabPanel>
          {/* Departments Tab */}
          <TabPanel>
            {loading || data.departments === null ? (
              <Center minHeight="100vh">
                <Spinner size="xl" />
              </Center>
            ) : (
              <VStack justify="start" w="100%">
                <HStack w="100%" justifyContent="flex-end">
                  <Button
                    colorScheme="primary"
                    mb={4}
                    onClick={() => {
                      setCurrentItem((prev) => ({
                        ...prev,
                        currentDepartment: null,
                      }));
                      openModal("isDepartmentModalOpen");
                    }}
                  >
                    Ajouter un nouveau rayon
                  </Button>
                </HStack>
                <DepartmentTable
                  data={data.departments}
                  onEdit={(department) => handleEdit(department, "currentDepartment", "isDepartmentModalOpen")}
                  onDelete={(id) => handleDelete(id, "department", "Rayon supprimé.", "Échec de la suppression du rayon.")}
                />
                <DepartmentFormModal
                  isOpen={modals.isDepartmentModalOpen}
                  onClose={() => closeModal("isDepartmentModalOpen", "currentDepartment")}
                  onSave={handleSave}
                  initialData={currentItem.currentDepartment}
                />
              </VStack>
            )}
          </TabPanel>
          {/* Food Groups Tab */}
          <TabPanel>
            {loading || data.foodGroups === null ? (
              <Center minHeight="100vh">
                <Spinner size="xl" />
              </Center>
            ) : (
              <VStack justify="start" w="100%">
                <HStack w="100%" justifyContent="flex-end">
                  <Button
                    colorScheme="primary"
                    mb={4}
                    onClick={() => {
                      setCurrentItem((prev) => ({
                        ...prev,
                        currentFoodGroup: null,
                      }));
                      openModal("isFoodGroupModalOpen");
                    }}
                  >
                    Ajouter un nouveau groupe alimentaire
                  </Button>
                </HStack>
                <FoodGroupTable
                  data={data.foodGroups}
                  onEdit={(foodGroup) => handleEdit(foodGroup, "currentFoodGroup", "isFoodGroupModalOpen")}
                  onDelete={(id) => handleDelete(id, "food-group", "Groupe alimentaire supprimé.", "Échec de la suppression du groupe alimentaire.")}
                />
                <FoodGroupFormModal
                  isOpen={modals.isFoodGroupModalOpen}
                  onClose={() => closeModal("isFoodGroupModalOpen", "currentFoodGroup")}
                  onSave={handleSave}
                  initialData={currentItem.currentFoodGroup}
                />
              </VStack>
            )}
          </TabPanel>
          {/* DishTypes Tab */}
          <TabPanel>
            {loading || data.dishTypes === null ? (
              <Center minHeight="100vh">
                <Spinner size="xl" />
              </Center>
            ) : (
              <VStack justify="start" w="100%">
                <HStack w="100%" justifyContent="flex-end">
                  <Button
                    colorScheme="primary"
                    mb={4}
                    onClick={() => {
                      setCurrentItem((prev) => ({
                        ...prev,
                        currentDishType: null,
                      }));
                      openModal("isDishTypeModalOpen");
                    }}
                  >
                    Ajouter un nouveau type de plat
                  </Button>
                </HStack>
                <DishTypeTable
                  data={data.dishTypes}
                  onEdit={(dishType) => handleEdit(dishType, "currentDishType", "isDishTypeModalOpen")}
                  onDelete={(id) => handleDelete(id, "dish-type", "Type de plat supprimé.", "Échec de la suppression du type de plat.")}
                />
                <DishTypeFormModal
                  isOpen={modals.isDishTypeModalOpen}
                  onClose={() => closeModal("isDishTypeModalOpen", "currentDishType")}
                  onSave={handleSave}
                  initialData={currentItem.currentDishType}
                />
              </VStack>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Gestion;
