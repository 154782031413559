import React from "react";
import { Box, Text, VStack, Button, useBreakpointValue, Link } from "@chakra-ui/react";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const PasswordResetConfirmation = () => {
  const navigate = useNavigate();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minH="100vh" bg="gray.50" p={6}>
      <VStack spacing={6} w="100%" maxW={useBreakpointValue({ base: "90%", md: "400px" })} textAlign="center">
        {/* Icon */}
        <Box bg="primary.100" p={6} borderRadius="full" display="flex" alignItems="center" justifyContent="center">
          <FaEnvelopeOpenText color="primary.500" size="3em" />
        </Box>

        {/* Text content */}
        <Text fontSize="2xl" fontWeight="bold">
          Vérifiez votre messagerie
        </Text>
        <Text color="gray.600" fontSize="md">
          Nous avons envoyé un e-mail de récupération de mot de passe à votre boîte de réception. Veuillez vérifier votre e-mail pour les instructions
          de réinitialisation de votre mot de passe.
        </Text>

        {/* Button to open email app */}
        <Button colorScheme="primary" size="lg" w="100%" onClick={() => window.open("mailto:")}>
          Ouvrir l'application de messagerie
        </Button>

        {/* Skip button or other actions */}
        <Text
          color="primary.500"
          fontSize="sm"
          fontWeight="bold"
          cursor="pointer"
          onClick={() => navigate("/login")} // Navigate to login page
        >
          Ignorer, je confirmerai plus tard
        </Text>

        {/* Help options */}
        <Text fontSize="sm" color="gray.500">
          Vous n'avez pas reçu l'e-mail ? Vérifiez votre filtre anti-spam,{" "}
          <Link color="primary.500" href="/login">
            essayez une autre adresse e-mail
          </Link>
        </Text>
      </VStack>
    </Box>
  );
};

export default PasswordResetConfirmation;
