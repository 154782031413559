import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { login, forgotPassword } from "../4 - API/API-Auth";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Image,
  Text,
  Flex,
  Link,
  Alert,
  AlertIcon,
  Stack,
  InputGroup,
  InputRightElement,
  VStack,
  useDisclosure,
  useColorModeValue,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useColorMode,
  ModalBody,
  ModalFooter,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash, FaChevronLeft } from "react-icons/fa";
import Logo from "../../img/LogoSquare.png";
import LoginImage from "../../img/Login.jpg";

function LoginPage() {
  const [email, setEmail] = useState("");
  const { setColorMode } = useColorMode();
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPasswordReset, setIsLoadingPasswordReset] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotMessage, setForgotMessage] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    localStorage.setItem("theme", "light");
  }, []);

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      setColorMode(theme);
    }
  }, [setColorMode]);

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => setShowPassword(!showPassword);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value.trim() === "") {
      setEmailError("Un courriel est requis");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value.trim() === "") {
      setPasswordError("Un mot de passe est requis");
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;

    if (email.trim() === "") {
      setEmailError("Un courriel est requis");
      valid = false;
    }

    if (password.trim() === "") {
      setPasswordError("Un mot de passe est requis");
      valid = false;
    }

    if (valid) {
      setIsLoading(true);
      try {
        const data = await login(email, password);

        localStorage.setItem("userId", data.user._id);
        localStorage.setItem("firstName", data.user.firstName);
        localStorage.setItem("lastName", data.user.lastName);
        localStorage.setItem("profileCompleted", data.profileCompleted);
        localStorage.setItem("role", data.role);
        localStorage.setItem("level", data.level);
        localStorage.setItem("token", data.token);
        localStorage.setItem("theme", data.user.theme);
        navigate("/dashboard");
      } catch (error) {
        if (error.message === "Vos informations d`identification sont invalides") {
          setGeneralError("Vos informations d'identification sont invalides");
        } else if (error.message === "Veuillez d'abord vérifier votre courriel") {
          setGeneralError("Veuillez d`abord vérifier votre courriel");
        } else {
          setGeneralError("Erreur de serveur, merci de réessayer plus tard");
        }
      }
      setIsLoading(false);
    }
  };

  const handleForgotSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoadingPasswordReset(true);
      await forgotPassword(forgotEmail);
      setForgotMessage("Le lien de réinitialisation du mot de passe a été envoyé à votre adresse courriel.");
      navigate("/reset-confirmation");
      setIsLoadingPasswordReset(false);
    } catch (error) {
      setForgotMessage(error.message);
      setIsLoadingPasswordReset(false);
    }
  };

  const bgColor = useColorModeValue("gray.200", "gray.800");
  const formBgColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.700", "white");

  // Check if the view is mobile or desktop
  const isMobile = useBreakpointValue({ base: true, md: false });
  const inputStyle = { fontSize: isMobile ? "16px" : "14px" };

  return (
    <Flex align="center" justify="center" minH="100vh" bg={bgColor}>
      {isMobile ? (
        // Mobile Layout
        <VStack position="relative" w="100%" h="100vh">
          {/* Background Image */}
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            h="100vh" // Ensure the background image covers the full height
            w="100%" // Ensure the background covers the full width
            css={{
              background: `url(/Login.jpg) no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;`,
            }}
          />
          {/* Foreground Content */}
          <Flex
            direction="column"
            justify="center" // Center the stack vertically
            align="center" // Center the stack horizontally
            h="100vh" // Ensure the content takes the full height
            w="100%" // Full width for responsiveness
            p={4}
          >
            <Stack
              spacing={0}
              mx="auto"
              maxW="100%"
              width="100%"
              direction="column"
              bg={formBgColor}
              boxShadow="lg"
              rounded="lg"
              overflow="hidden"
              zIndex={1} // Bring the content in front of the background
              p={6}
              py={12}
            >
              <VStack spacing={4} align="center" py={2}>
                <Image src={Logo} alt="Logo" h="80px" w="80px" />
                <Text fontSize="xl" fontWeight="bold" color={textColor}>
                  Bienvenue!
                </Text>
              </VStack>

              <Stack spacing={4} mt={6}>
                {generalError && (
                  <Alert status="warning">
                    <AlertIcon />
                    {generalError}
                  </Alert>
                )}

                <form onSubmit={handleSubmit}>
                  <FormControl id="email" isInvalid={emailError} mb={4} py={2}>
                    <FormLabel mb={0} fontSize="sm">
                      Courriel
                    </FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      onBlur={handleEmailChange}
                      placeholder="Entrez votre courriel"
                      style={inputStyle}
                    />
                    {emailError && (
                      <Text color="red.500" fontSize="sm">
                        {emailError}
                      </Text>
                    )}
                  </FormControl>

                  <FormControl id="password" isInvalid={passwordError} mb={1} py={1}>
                    <FormLabel mb={0} fontSize="sm">
                      Mot de passe
                    </FormLabel>
                    <InputGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={handlePasswordChange}
                        onBlur={handlePasswordChange}
                        placeholder="Entrez votre mot de passe"
                        style={inputStyle}
                      />
                      <InputRightElement h="full">
                        <Button variant="ghost" onClick={handleTogglePassword}>
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {passwordError && (
                      <Text color="red.500" fontSize="sm">
                        {passwordError}
                      </Text>
                    )}
                  </FormControl>

                  <Link onClick={onOpen} color="primary.500" fontSize="sm" textAlign="right" display="block" mb={8}>
                    Mot de passe oublié?
                  </Link>

                  <Button type="submit" colorScheme="primary" isLoading={isLoading} loadingText="Connexion en cours" width="full" h={10}>
                    Se connecter
                  </Button>
                </form>
              </Stack>

              <VStack spacing={4} mt={6} py={2}>
                <Text textAlign="center" fontSize="md">
                  Vous n'avez pas de compte?{" "}
                  <Link href="/register" color="primary.500">
                    Créer un compte
                  </Link>
                </Text>
              </VStack>
            </Stack>
          </Flex>
        </VStack>
      ) : (
        // Desktop Layout
        <VStack>
          <Stack
            spacing={0}
            mx="auto"
            maxW={{ md: "80%", lg: "60%" }}
            width="100%"
            direction="row"
            bg={formBgColor}
            boxShadow="lg"
            rounded="3xl"
            overflow="hidden"
          >
            <Flex flex={1} direction="column" justify="space-around" p={12} pt={8} bg={formBgColor} h="70vh">
              <VStack spacing={4} align="center">
                <Image src={Logo} alt="Logo" h="120px" w="120px" />
                <Text fontSize="2xl" fontWeight="bold" color={textColor}>
                  Bienvenue à Pense et cuisine!
                </Text>
              </VStack>

              <Stack spacing={4} mt={2}>
                {generalError && (
                  <Alert status="warning">
                    <AlertIcon />
                    {generalError}
                  </Alert>
                )}

                <form onSubmit={handleSubmit}>
                  <FormControl id="email" isInvalid={emailError} mb={4}>
                    <FormLabel mb={1}>Courriel</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      onBlur={handleEmailChange}
                      placeholder="Entrez votre courriel"
                      style={inputStyle}
                    />
                    {emailError && (
                      <Text color="red.500" fontSize="sm">
                        {emailError}
                      </Text>
                    )}
                  </FormControl>

                  <FormControl id="password" isInvalid={passwordError} mb={1}>
                    <FormLabel mb={1}>Mot de passe</FormLabel>
                    <InputGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={handlePasswordChange}
                        onBlur={handlePasswordChange}
                        placeholder="Entrez votre mot de passe"
                        style={inputStyle}
                        _placeholder={{ color: "gray.500", fontSize: "14px" }}
                      />
                      <InputRightElement h="full">
                        <Button variant="ghost" onClick={handleTogglePassword}>
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {passwordError && (
                      <Text color="red.500" fontSize="sm">
                        {passwordError}
                      </Text>
                    )}
                  </FormControl>

                  <Link onClick={onOpen} color="primary.500" fontSize="sm" textAlign="right" display="block" mb={8}>
                    Mot de passe oublié?
                  </Link>

                  <Button
                    type="submit"
                    colorScheme="primary"
                    isLoading={isLoading}
                    loadingText="Connexion en cours"
                    width="full"
                    h={12}
                    fontSize="14px"
                    fontWeight="500"
                    borderRadius="xl"
                  >
                    Se connecter
                  </Button>
                </form>
              </Stack>

              <VStack spacing={4} mt={6}>
                <Text textAlign="center" fontSize="md">
                  Vous n'avez pas de compte?{" "}
                  <Link href="/register" color="primary.500">
                    Créer un compte
                  </Link>
                </Text>
              </VStack>
            </Flex>

            {/* Side Image */}
            <Flex flex={1} display={{ base: "none", md: "flex" }}>
              <Image alt="Login Image" objectFit="cover" src={LoginImage} height="100%" maxWidth="100%" />
            </Flex>
          </Stack>
          <Text fontSize="sm" color="gray.500" mt={10}>
            © 2024 Pense et cuisine. Tous droits réservés.
          </Text>
        </VStack>
      )}
      {isMobile ? (
        <Drawer isOpen={isOpen} onClose={onClose} size="full" placement="top">
          <DrawerOverlay />
          <DrawerContent bg={bgColor} borderRadius="md" boxShadow="lg" p={2}>
            <VStack display="flex" justifyContent="space-between" alignItems="start">
              <Button aria-label="Fermer" leftIcon={<FaChevronLeft />} onClick={onClose} variant="ghost">
                Retour
              </Button>
              <DrawerHeader fontSize="2xl" color={textColor}>
                Réinitialisez votre mot de passe
              </DrawerHeader>
            </VStack>

            <DrawerBody mt={4}>
              <VStack spacing={4} align="flex-start" flexGrow="0">
                <Text fontSize="md" color={textColor}>
                  Entrez l'adresse courriel associée à votre compte et nous vous enverrons les instructions pour réinitialiser votre mot de passe.
                </Text>
                <form onSubmit={handleForgotSubmit} style={{ width: "100%" }}>
                  <FormControl id="forgot-email">
                    <FormLabel color={textColor}>Adresse courriel</FormLabel>
                    <Input
                      type="email"
                      value={forgotEmail}
                      onChange={(e) => setForgotEmail(e.target.value)}
                      required
                      placeholder="Entrez votre courriel"
                      _placeholder={{ color: "gray.500", fontSize: "14px" }}
                      style={inputStyle}
                    />
                  </FormControl>
                  {forgotMessage && (
                    <Alert status="warning">
                      <AlertIcon />
                      {forgotMessage}
                    </Alert>
                  )}
                </form>
              </VStack>
              <Button
                type="submit"
                onClick={handleForgotSubmit}
                colorScheme="primary"
                width="full"
                h={10}
                mt={6}
                isLoading={isLoadingPasswordReset}
                loadingText="Connexion en cours"
              >
                Envoyer les instructions
              </Button>
            </DrawerBody>

            <DrawerFooter justifyContent="center"></DrawerFooter>
          </DrawerContent>
        </Drawer>
      ) : (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent bg={bgColor} borderRadius="md" boxShadow="lg" p={2}>
            <ModalHeader fontSize="2xl" color={textColor}>
              Réinitialisez votre mot de passe
            </ModalHeader>
            <ModalBody mt={2}>
              <VStack spacing={4} align="flex-start" flexGrow="0">
                <Text fontSize="md" color={textColor}>
                  Entrez l'adresse courriel associée à votre compte et nous vous enverrons les instructions pour réinitialiser votre mot de passe.
                </Text>
                <form onSubmit={handleForgotSubmit} style={{ width: "100%" }}>
                  <FormControl id="forgot-email">
                    <FormLabel color={textColor}>Adresse courriel</FormLabel>
                    <Input
                      type="email"
                      value={forgotEmail}
                      onChange={(e) => setForgotEmail(e.target.value)}
                      required
                      placeholder="Entrez votre courriel"
                      _placeholder={{ color: "gray.500", fontSize: "14px" }}
                      style={inputStyle}
                    />
                  </FormControl>
                  {forgotMessage && (
                    <Alert status="warning" mt={6}>
                      <AlertIcon />
                      {forgotMessage}
                    </Alert>
                  )}
                </form>
              </VStack>
              <Button
                type="submit"
                onClick={handleForgotSubmit}
                colorScheme="primary"
                width="full"
                h={10}
                mt={6}
                isLoading={isLoadingPasswordReset}
                loadingText="Connexion en cours"
              >
                Envoyer les instructions
              </Button>
            </ModalBody>
            <ModalFooter justifyContent="center">
              <Button onClick={onClose} variant="ghost">
                Fermer
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
}

export default LoginPage;
