import React, { useState, useEffect, useRef } from "react";
import { Box, Circle, useColorModeValue } from "@chakra-ui/react";
import { pSBC } from "./UtilsColorPSBC";

// Component to render a background with circles
const CircleBackground = ({ w, h, name, color, orientation = "vertical", filter }) => {
  // Function to convert a string to a consistent hex color
  const stringToColor = (str) => {
    let hash = 0;
    if (str.length === 0) return "#000000";
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash &= hash; // Convert to 32bit integer
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
  };

  // Get the base color from the provided color or name
  const baseColor = color || stringToColor(name);

  // Use useColorModeValue to determine color adjustments (Light , Dark mode)
  const colorLighterAdjustment = useColorModeValue(0.2, 0);
  const colorNormalAdjustment = useColorModeValue(0.05, -0.2);
  const colorDarkerAdjustment = useColorModeValue(0.01, -0.4);
  const backgroundColorAdjustment = useColorModeValue(-0.2, -0.8);

  // Adjust colors based on color mode
  const colorLighter = pSBC(colorLighterAdjustment, baseColor);
  const colorNormal = pSBC(colorNormalAdjustment, baseColor);
  const colorDarker = pSBC(colorDarkerAdjustment, baseColor);
  const backgroundColor = pSBC(backgroundColorAdjustment, baseColor);

  // Reference to the container element
  const containerRef = useRef(null);
  // State to store the container width
  const [containerWidth, setContainerWidth] = useState(0);

  // Effect to update the container width on resize
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setContainerWidth(width);
      }
    };
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  // Calculate the base circle size
  const baseCircleSize = containerWidth * 4;
  let circleSize = baseCircleSize;
  if ((orientation === "vertical" || orientation === "vertical(180)") && containerWidth < 400) {
    circleSize = baseCircleSize * 1;
  }

  // Parse orientation to extract rotation
  let mainOrientation = orientation;
  let rotation = 0;

  const orientationMatch = orientation.match(/(vertical|horizontal)(\((\d+)\))?/);
  if (orientationMatch) {
    mainOrientation = orientationMatch[1];
    rotation = parseInt(orientationMatch[3]) || 0;
  }

  // Base positions for the circles
  const basePositions = [15, 45, 75];
  let positions = basePositions;

  // Reverse positions for 180-degree rotation
  if (rotation === 180) {
    positions = [...basePositions].reverse();
  }

  // Function to generate circle style
  const circleStyle = (color, position) => {
    let style = {
      position: "absolute",
      width: `${circleSize}px`,
      height: `${circleSize}px`,
      backgroundColor: color,
      borderRadius: "50%",
    };

    if (mainOrientation === "vertical") {
      // Vertical arrangement
      style.left = "50%";
      style.top = `${position}%`;
      style.transform = "translateX(-50%)"; // Center horizontally
    } else if (mainOrientation === "horizontal") {
      // Horizontal arrangement
      style.top = "50%";
      style.left = `${position}%`;
      style.transform = "translateY(-50%)"; // Center vertically
    } else {
      // Default to vertical
      style.left = "50%";
      style.top = `${position}%`;
      style.transform = "translateX(-50%)";
    }

    return style;
  };

  // Container style with optional filter and rotation
  const containerStyle = {
    position: "relative",
    width: w,
    height: h,
    backgroundColor: backgroundColor,
    overflow: "hidden",
    filter: filter || "none",
    transform: `rotate(${rotation}deg)`,
    transformOrigin: "center",
  };

  return (
    <Box ref={containerRef} style={containerStyle}>
      {containerWidth > 0 && (
        <>
          {/* Adjust the rendering order based on rotation */}
          {rotation === 180 ? (
            <>
              <Circle {...circleStyle(colorDarker, positions[2])} />
              <Circle {...circleStyle(colorNormal, positions[1])} />
              <Circle {...circleStyle(colorLighter, positions[0])} />
            </>
          ) : (
            <>
              <Circle {...circleStyle(colorDarker, positions[0])} />
              <Circle {...circleStyle(colorNormal, positions[1])} />
              <Circle {...circleStyle(colorLighter, positions[2])} />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default CircleBackground;
