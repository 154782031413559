// UtilsOptionsDefinitions.js
import { LuWheatOff, LuMilkOff } from "react-icons/lu";
const apiUrl = process.env.REACT_APP_API_ENDPOINT || "https://api.penseetcuisine.ca";

export const vitaminOptions = [
  { label: "Vitamine A", value: "Vitamine A", unit: "%" },
  { label: "Vitamine B1 (Thiamine)", value: "Vitamine B1", unit: "%" },
  { label: "Vitamine B2 (Riboflavine)", value: "Vitamine B2", unit: "%" },
  { label: "Vitamine B3 (Niacine)", value: "Vitamine B3", unit: "%" },
  { label: "Vitamine B5 (Acide pantothénique)", value: "Vitamine B5", unit: "%" },
  { label: "Vitamine B6", value: "Vitamine B6", unit: "%" },
  { label: "Vitamine B7 (Biotine)", value: "Vitamine B7", unit: "%" },
  { label: "Vitamine B9 (Acide folique)", value: "Vitamine B9", unit: "%" },
  { label: "Vitamine B12", value: "Vitamine B12", unit: "%" },
  { label: "Vitamine C", value: "Vitamine C", unit: "%" },
  { label: "Vitamine D", value: "Vitamine D", unit: "%" },
  { label: "Vitamine E", value: "Vitamine E", unit: "%" },
  { label: "Vitamine K", value: "Vitamine K", unit: "%" },
  { label: "Calcium", value: "Calcium", unit: "%" },
  { label: "Fer", value: "Fer", unit: "%" },
  { label: "Magnésium", value: "Magnésium", unit: "%" },
  { label: "Phosphore", value: "Phosphore", unit: "%" },
  { label: "Potassium", value: "Potassium", unit: "%" },
  { label: "Sodium", value: "Sodium", unit: "%" },
  { label: "Zinc", value: "Zinc", unit: "%" },
  { label: "Cuivre", value: "Cuivre", unit: "%" },
  { label: "Manganèse", value: "Manganèse", unit: "%" },
  { label: "Sélénium", value: "Sélénium", unit: "%" },
  { label: "Iode", value: "Iode", unit: "%" },
];

// Spiciness Options
export const spicinessOptions = [
  { label: "Aucun", value: "None" },
  { label: "Doux", value: "Mild" },
  { label: "Moyennement épicé", value: "Medium" },
  { label: "Épicé", value: "Spicy" },
  { label: "Très épicé", value: "Very Spicy" },
];

// Allergen Options
export const allergenOptions = [
  { label: "Aucune", value: "None" },
  { label: "Oeufs", value: "Oeufs" },
  { label: "Lactose", value: "Lactose" },
  { label: "Gluten", value: "Gluten" },
  { label: "Moutarde", value: "Moutarde" },
  { label: "Arachides", value: "Arachides" },
  { label: "Noix", value: "Noix" },
  { label: "Crustacés et mollusques", value: "Crustacés et mollusques" },
  { label: "Poisson", value: "Poisson" },
  { label: "Graines de sésame", value: "Graines de sésame" },
  { label: "Soja", value: "Soja" },
  { label: "Sulfites", value: "Sulfites" },
];

// Cooking Equipment Options
export const cookingEquipmentOptions = [
  { label: "Four", value: "Oven" },
  { label: "Cuisinière", value: "Stove" },
  { label: "Micro-ondes", value: "Microwave" },
  { label: "Grill", value: "Grill" },
  { label: "Friteuse à air", value: "Air Fryer" },
  { label: "Autocuiseur", value: "Pressure Cooker" },
  { label: "Mijoteuse", value: "Slow Cooker" },
  { label: "Sous Vide", value: "Sous Vide" },
  { label: "Instant Pot", value: "Instant Pot" },
  { label: "Blender", value: "Blender" },
];

// Dish Labels for dietary preferences
export const dishLabels = [
  { value: "isVegan", label: "VG", tooltip: "Vegan" },
  { value: "isVegetarian", label: "V", tooltip: "Vegetarian" },
  { value: "isGlutenFree", icon: LuWheatOff, tooltip: "Sans Gluten" },
  { value: "isLactoseFree", icon: LuMilkOff, tooltip: "Sans Lactose" },
];

export const seasonalityOptions = [
  { value: "All", label: "Toutes" },
  { value: "Winter", label: "Hiver" },
  { value: "Spring", label: "Printemps" },
  { value: "Summer", label: "Été" },
  { value: "Fall", label: "Automne" },
];

export const nutritionalFields = [
  { id: "calories", label: "Calories", placeholder: "Exemple : 52" },
  { id: "proteins", label: "Protéines (g)", placeholder: "Exemple : 0.3" },
  { id: "fats", label: "Lipides (g)", placeholder: "Exemple : 0.2" },
  { id: "carbohydrates", label: "Glucides (g)", placeholder: "Exemple : 14" },
  { id: "fibers", label: "Fibres (g)", placeholder: "Exemple : 2.4" },
  { id: "sodium", label: "Sodium (mg)", placeholder: "Exemple : 1" },
];

export const dietaryPreferenceOptions = [
  { id: "isVegan", label: "Végan" },
  { id: "isVegetarian", label: "Végétarien" },
  { id: "isGlutenFree", label: "Sans gluten" },
  { id: "isLactoseFree", label: "Sans lactose" },
];

export const checkboxOptions = [
  { id: "isOrganic", label: "Biologique" },
  { id: "isProcessed", label: "Transformé" },
  { id: "isPerishable", label: "Périssable" },
];

export const continentOptions = [
  { value: "Africa", label: "Afrique" },
  { value: "Asia", label: "Asie" },
  { value: "Europe", label: "Europe" },
  { value: "Oceania", label: "Océanie" },
  { value: "North America", label: "Amérique du Nord" },
  { value: "South America", label: "Amérique du Sud" },
];
// Function to fetch cuisine types
export const fetchCuisineTypes = async () => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/blueprint/dish-type`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`Error fetching dish-types: ${response.statusText}`);
    }
    const data = await response.json();
    return data.map((cuisine) => ({
      value: cuisine._id,
      label: cuisine.name,
    }));
  } catch (error) {
    console.error("Error fetching dish-types:", error);
    return [];
  }
};

// Cuisine Type Options
export let cuisineTypeOptions = [];

// Fetch and set cuisine type options
fetchCuisineTypes().then((options) => {
  cuisineTypeOptions = options;
});

// Fetch all dish components
export const fetchDishComponents = async () => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/blueprint/dish-component`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.map((component) => ({ value: component._id, label: component.name }));
  } catch (error) {
    console.error("Error fetching dish components:", error);
    return [];
  }
};

// Fetch foods by subcategory
export const fetchFoodsBySubCategory = async (subCategoryId) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/blueprint/food/by-sub-category/${subCategoryId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.foods || data;
  } catch (error) {
    console.error("Error fetching foods by subcategory:", error);
    return [];
  }
};
// Fetch categories
export const fetchCategories = async () => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/blueprint/categories`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.map((category) => ({ value: category._id, label: category.name }));
  } catch (error) {
    console.error("Error fetching categories:", error);
    return [];
  }
};

export const fetchSubCategories = async () => {
  const response = await fetch(`${apiUrl}/api/v1/blueprint/sub-category`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (!response.ok) {
    throw new Error(`Error fetching subcategories: ${response.statusText}`);
  }
  const data = await response.json();
  return data.map((subcat) => ({
    value: subcat._id,
    label: subcat.name,
  }));
};

// Fetch subcategories by categories
export const fetchSubCategoriesByCategory = async (categoryId) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/blueprint/sub-category/by-categories`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Body: JSON.stringify({ categoryId }),
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.map((subCategory) => ({ value: subCategory._id, label: subCategory.name }));
  } catch (error) {
    console.error("Error fetching subcategories by category:", error);
    return [];
  }
};
