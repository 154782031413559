import React from 'react';
import ModularTable from './ModularTable';
import { foodGroupTableColumns } from './TableConfig';

const FoodGroupTable = ({ data, onEdit, onDelete }) => {
  return (
    <ModularTable
      columns={foodGroupTableColumns}
      data={data}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};

export default FoodGroupTable;
