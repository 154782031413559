import React from 'react';
import { Outlet } from 'react-router-dom';
import { useBreakpointValue } from '@chakra-ui/react';
import SideBarLayout from './LayoutSideBar';  // Desktop sidebar layout
import MobileBarLayout from './MobileBarLayout';  // Mobile bottom bar layout

const Layout = () => {
  // Determine whether to render desktop or mobile layout
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      {isMobile ? (
        <>
          {/* Mobile bottom bar layout */}
          <Outlet />
          <MobileBarLayout />
        </>
      ) : (
        <SideBarLayout>
          <Outlet />
        </SideBarLayout>
      )}
    </>
  );
};

export default Layout;
