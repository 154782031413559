import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  HStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  RadioGroup,
  Radio,
  useToast,
  Tag,
  Tooltip,
  TagLabel,
  Checkbox,
  TagCloseButton,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { FaEllipsisV } from "react-icons/fa";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../../5 - General/Utils/UtilsStrictModeDroppable";
import { useBlueprintCreate } from "../../3 - Modal/3 - Blueprints Modals/BlueprintCreateContext";

const BlueprintStepFour = () => {
  const { wizardData, updateWizardData, goToNextStep } = useBlueprintCreate();
  const toast = useToast();

  const [currentDishComponentIndex, setCurrentDishComponentIndex] = useState(0);
  const [alimentsData, setAlimentsData] = useState({});
  const [selectedAliments, setSelectedAliments] = useState({});
  const [accordionModes, setAccordionModes] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [sousCategoriesOrder, setSousCategoriesOrder] = useState([]);

  const token = localStorage.getItem("token");

  const dishComponents = wizardData.stepTwo?.dishComponents || [];
  const currentDishComponent = dishComponents[currentDishComponentIndex];

  const sousCategories = useMemo(() => {
    if (!currentDishComponent) return [];
    const selectedSubcategories = wizardData.stepThree?.selectedSubcategories;
    const dishComponentId = currentDishComponent.id.toString();
    const subcats = selectedSubcategories[dishComponentId] || [];

    // Initialize sousCategoriesOrder if not set
    if (sousCategoriesOrder.length === 0) {
      setSousCategoriesOrder(subcats);
    }
    return sousCategoriesOrder.length > 0 ? sousCategoriesOrder : subcats;
  }, [currentDishComponent, wizardData.stepThree?.selectedSubcategories, sousCategoriesOrder]);

  useEffect(() => {
    const fetchAliments = async () => {
      if (!currentDishComponent || sousCategories.length === 0) return;
      setIsLoading(true);
      try {
        const alimentsBySousCategorie = {};

        for (const subcat of sousCategories) {
          const apiUrl = process.env.REACT_APP_API_ENDPOINT;
          const response = await fetch(`${apiUrl}/api/v1/blueprint/food/by-sub-category/${subcat.value}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            console.error(`Error fetching aliments for ${subcat.label}:`, response.statusText);
            throw new Error(`Error fetching aliments: ${response.statusText}`);
          }

          const aliments = await response.json();
          alimentsBySousCategorie[subcat.value] = aliments;
        }

        setAlimentsData(alimentsBySousCategorie);

        // Initialize selectedAliments and modes for each sous-catégorie
        const initialSelectedAliments = {};
        const initialAccordionModes = {};

        // Check if there is saved data for this dishComponent in wizardData.stepFour
        const savedStepFourData = wizardData.stepFour?.[currentDishComponent.id] || {};

        for (const subcat of sousCategories) {
          // Set default mode to 'remove' or use saved mode
          const savedMode = savedStepFourData.accordionModes?.[subcat.value] || "remove";
          initialAccordionModes[subcat.value] = savedMode;

          // Set selected aliments based on mode and saved data
          if (savedMode === "remove") {
            // In 'Supprimer' mode, pre-select all aliments unless saved selection exists
            let allAliments =
              alimentsBySousCategorie[subcat.value]?.map((aliment) => ({
                value: aliment._id,
                label: aliment.name,
                level: "basic",
              })) || [];

            let savedSelectedAliments = savedStepFourData.selectedAliments?.[subcat.value];

            if (savedSelectedAliments) {
              // Ensure that 'level' is included
              savedSelectedAliments = savedSelectedAliments.map((aliment) => ({
                ...aliment,
                level: aliment.level || "basic",
              }));
            } else {
              savedSelectedAliments = allAliments;
            }
            initialSelectedAliments[subcat.value] = savedSelectedAliments;
          } else {
            // In 'Ajouter' mode, start with empty selection or use saved selection
            let savedSelectedAliments = savedStepFourData.selectedAliments?.[subcat.value] || [];

            // Ensure that 'level' is included
            savedSelectedAliments = savedSelectedAliments.map((aliment) => ({
              ...aliment,
              level: aliment.level || "basic",
            }));

            initialSelectedAliments[subcat.value] = savedSelectedAliments;
          }
        }

        setSelectedAliments(initialSelectedAliments);
        setAccordionModes(initialAccordionModes);
      } catch (error) {
        console.error("Error fetching aliments:", error);
        toast({
          title: "Erreur",
          description: "Impossible de récupérer les aliments",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchAliments();
  }, [currentDishComponent, sousCategories, token, toast, wizardData.stepFour]);

  const handleModeChange = (sousCategorieId, value) => {
    setAccordionModes((prev) => ({
      ...prev,
      [sousCategorieId]: value,
    }));

    if (value === "remove") {
      // In 'Supprimer' mode, pre-select all aliments
      const allAliments =
        alimentsData[sousCategorieId]?.map((aliment) => ({
          value: aliment._id,
          label: aliment.name,
          level: "basic",
        })) || [];

      setSelectedAliments((prev) => ({
        ...prev,
        [sousCategorieId]: allAliments,
      }));
    } else if (value === "add") {
      // In 'Ajouter' mode, clear selected aliments
      setSelectedAliments((prev) => ({
        ...prev,
        [sousCategorieId]: [],
      }));
    }
  };

  const handleAlimentsChange = (sousCategorieId, selectedOptions) => {
    setSelectedAliments((prev) => {
      const previousAliments = prev[sousCategorieId] || [];
      const updatedAliments = selectedOptions.map((option) => {
        const existingAliment = previousAliments.find((a) => a.value === option.value);
        if (existingAliment) {
          return existingAliment;
        } else {
          return { ...option, level: "basic" };
        }
      });
      return {
        ...prev,
        [sousCategorieId]: updatedAliments,
      };
    });
  };

  const updateAlimentLevel = (sousCategorieId, alimentId, newLevel) => {
    setSelectedAliments((prev) => {
      const updatedAliments = prev[sousCategorieId]?.map((aliment) => {
        if (aliment.value === alimentId) {
          return { ...aliment, level: newLevel };
        }
        return aliment;
      });
      return {
        ...prev,
        [sousCategorieId]: updatedAliments,
      };
    });
  };

  const handleNext = () => {
    // Save selectedAliments and accordionModes for current dishComponent
    updateWizardData((prevData) => {
      const stepFourData = prevData.stepFour || {};
      return {
        ...prevData,
        stepFour: {
          ...stepFourData,
          [currentDishComponent.id]: {
            selectedAliments: selectedAliments,
            accordionModes: accordionModes,
            sousCategoriesOrder: sousCategoriesOrder,
          },
        },
      };
    });

    if (currentDishComponentIndex < dishComponents.length - 1) {
      setCurrentDishComponentIndex(currentDishComponentIndex + 1);
      setSousCategoriesOrder([]); // Reset order when moving to next component
    } else {
      goToNextStep();
    }
  };

  const handlePrevious = () => {
    // Save current state before moving back
    updateWizardData((prevData) => {
      const stepFourData = prevData.stepFour || {};
      return {
        ...prevData,
        stepFour: {
          ...stepFourData,
          [currentDishComponent.id]: {
            selectedAliments: selectedAliments,
            accordionModes: accordionModes,
            sousCategoriesOrder: sousCategoriesOrder,
          },
        },
      };
    });

    if (currentDishComponentIndex > 0) {
      setCurrentDishComponentIndex(currentDishComponentIndex - 1);
      setSousCategoriesOrder([]); // Reset order when moving to previous component
    } else {
      // If on the first dish component, navigate to the previous step if desired
      // Uncomment the following line if you have a goToPreviousStep function
      // goToPreviousStep();
    }
  };

  if (!currentDishComponent) {
    return <Text>Aucune composante sélectionnée.</Text>;
  }

  // Define menuPortalTarget only if document is available
  const menuPortalTarget = typeof document !== "undefined" ? document.body : null;

  // Function to handle drag and drop
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newOrder = Array.from(sousCategories);
    const [movedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedItem);

    setSousCategoriesOrder(newOrder);
  };

  // Custom MultiValue component for the Select component
  const CustomMultiValue = (props) => {
    const { data, selectProps, removeProps } = props;
    const { subcatId, updateAlimentLevel } = selectProps;
    const isAdvanced = data.level === "advanced";

    return (
      <Tooltip label={isAdvanced ? "Avancé" : "Débutant"} placement="top">
        <Tag
          m="2px"
          px={2}
          py={2}
          borderRadius="full"
          variant="solid"
          bg="gray.100"
          border="1px solid"
          borderColor="gray.500"
          color="gray.600"
          transition="all 0.1s ease"
          cursor="default"
        >
          <TagCloseButton
            mr={2}
            ml={0}
            pl={0}
            backgroundColor="gray.500"
            color="white"
            onClick={removeProps.onClick}
            _focus={{ boxShadow: "none" }}
            _hover={{ backgroundColor: "red.700", color: "white" }}
          />
          <Checkbox
            isChecked={isAdvanced}
            onChange={(e) => {
              e.stopPropagation();
              const newLevel = e.target.checked ? "advanced" : "basic";
              updateAlimentLevel(subcatId, data.value, newLevel);
            }}
            borderColor="gray.500"
            size="md"
            mr={3}
          />
          <TagLabel>{data.label}</TagLabel>
        </Tag>
      </Tooltip>
    );
  };

  // Generate defaultIndices for Accordion to have all accordions open by default
  const defaultIndices = sousCategories.map((_, index) => index);

  return (
    <Box p={4} maxW="900px" margin="auto">
      <Text fontSize="2xl" fontWeight="bold" mb={4}>
        Étape 4 : Sélectionnez les aliments pour {currentDishComponent.name}
      </Text>

      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="sousCategoriesDroppable">
          {(provided) => (
            <Accordion allowMultiple defaultIndex={defaultIndices} ref={provided.innerRef} {...provided.droppableProps}>
              {sousCategories.map((subcat, index) => {
                const mode = accordionModes[subcat.value] || "remove";
                const selectedCount = selectedAliments[subcat.value]?.length || 0;

                const hasSelection = selectedCount > 0;
                const tagColor = hasSelection ? "green" : "red";

                return (
                  <Draggable key={subcat.value} draggableId={subcat.value} index={index}>
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided.draggableProps}>
                        <AccordionItem key={subcat.value}>
                          <Box display="flex" alignItems="center">
                            <Box {...provided.dragHandleProps} p={2} cursor="grab">
                              <FaEllipsisV />
                            </Box>
                            <AccordionButton>
                              <HStack flex="1" textAlign="left">
                                <Tag colorScheme={tagColor}>{selectedCount}</Tag>
                                <Box>{subcat.label}</Box>
                              </HStack>
                              <AccordionIcon />
                            </AccordionButton>
                          </Box>
                          <AccordionPanel pb={4}>
                            {isLoading ? (
                              <Text>Chargement des aliments...</Text>
                            ) : (
                              <>
                                {/* Mode Selector for Each Accordion */}
                                <HStack spacing={4} mb={4}>
                                  <Text>Mode :</Text>
                                  <RadioGroup value={accordionModes[subcat.value]} onChange={(value) => handleModeChange(subcat.value, value)}>
                                    <HStack spacing={4}>
                                      <Radio value="add">Rechercher</Radio>
                                      <Radio value="remove">Retirer</Radio>
                                    </HStack>
                                  </RadioGroup>
                                </HStack>

                                {/* Use Select component for both Ajouter and Supprimer modes */}
                                <Select
                                  isMulti
                                  name={`aliments-${subcat.value}`}
                                  options={
                                    alimentsData[subcat.value]?.map((aliment) => {
                                      const existingAliment = selectedAliments[subcat.value]?.find((a) => a.value === aliment._id);
                                      return {
                                        value: aliment._id,
                                        label: aliment.name,
                                        level: existingAliment?.level || "basic",
                                      };
                                    }) || []
                                  }
                                  placeholder={mode === "add" ? "Sélectionnez des aliments..." : "Sélectionnez les aliments à supprimer..."}
                                  closeMenuOnSelect={false}
                                  onChange={(selectedOptions) => {
                                    handleAlimentsChange(subcat.value, selectedOptions);
                                  }}
                                  menuPortalTarget={menuPortalTarget}
                                  menuPosition="fixed"
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  value={selectedAliments[subcat.value]}
                                  hideSelectedOptions={true}
                                  components={{
                                    MultiValue: CustomMultiValue,
                                    ClearIndicator: null,
                                  }}
                                  subcatId={subcat.value} // Pass subcatId directly
                                  updateAlimentLevel={updateAlimentLevel} // Pass the function directly
                                />
                              </>
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      </Box>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Accordion>
          )}
        </StrictModeDroppable>
      </DragDropContext>

      {/* Navigation Buttons */}
      <HStack mt={4} justify="space-between">
        <Button onClick={handlePrevious} isDisabled={currentDishComponentIndex === 0}>
          Voir la composante précédente
        </Button>
        <Button colorScheme="primary" onClick={handleNext}>
          {currentDishComponentIndex < dishComponents.length - 1 ? "Voir la prochaine composante et sauvegarder celle-ci" : "Terminer"}
        </Button>
      </HStack>
    </Box>
  );
};

export default BlueprintStepFour;
