// client\src\components\5 - General\Utils\UtilsProfilePictures.js
import { useState, useCallback } from "react";

export const useProfilePictures = () => {
  const apiUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_ENDPOINT_PROD : process.env.REACT_APP_API_ENDPOINT_DEV;
  const [profilePictures, setProfilePictures] = useState({});

  const fetchProfilePicture = useCallback(
    async (userId) => {
      if (!profilePictures[userId]) {
        // Only fetch if not already fetched
        try {
          // Attempt to fetch the profile picture without needing to know the extension
          const response = await fetch(`${apiUrl}/uploads/users/${userId}/profilepicture.png?t=${new Date().getTime()}`);

          if (response.ok) {
            const profilePicUrl = response.url; // Get the fetched URL

            // Update state with the fetched profile picture URL
            setProfilePictures((prevState) => ({
              ...prevState,
              [userId]: profilePicUrl,
            }));
          } else if (response.status === 404) {
            // Handle the case where the profile picture is not found (404)
            setProfilePictures((prevState) => ({
              ...prevState,
              [userId]: "NOT_FOUND",
            }));
          } else {
            // Handle any other errors
            setProfilePictures((prevState) => ({
              ...prevState,
              [userId]: null,
            }));
          }
        } catch (error) {
          // Catch any network or fetch errors
          console.error("Error fetching profile picture:", error);
          setProfilePictures((prevState) => ({
            ...prevState,
            [userId]: null, // Set to null on error
          }));
        }
      }
    },
    [profilePictures, apiUrl]
  );

  return { profilePictures, fetchProfilePicture };
};
