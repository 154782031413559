import React from "react";
import GeneralGestionFormModal from "./GeneralGestionFormModal";

const CategoryFormModal = ({ isOpen, onClose, onSave, initialData }) => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const entityName = "Catégorie";
  const apiEndpoint = `${apiUrl}/api/v1/blueprint/category`;

  const formFields = [
    {
      name: "name",
      label: "Nom de la Catégorie",
      placeholder: "Nom de la Catégorie...",
      isRequired: true,
      type: "text",
    },
  ];

  const modalTitle = (data) => (data ? "Modifier la Catégorie" : "Ajouter une nouvelle Catégorie");

  const successMessage = (data) => (data ? "La Catégorie a été mise à jour avec succès." : "La Catégorie a été créée avec succès.");

  const failureMessage = "Une erreur est survenue. Veuillez réessayer plus tard.";

  return (
    <GeneralGestionFormModal
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSave}
      initialData={initialData}
      entityName={entityName}
      apiEndpoint={apiEndpoint}
      formFields={formFields}
      modalTitle={modalTitle}
      successMessage={successMessage}
      failureMessage={failureMessage}
    />
  );
};

export default CategoryFormModal;
