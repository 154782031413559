import React, { useEffect, useState, useMemo, useRef } from "react";
import { Box, FormControl, FormLabel, Text, Spinner, Tag, TagLabel, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useBlueprintCreate } from "../../3 - Modal/3 - Blueprints Modals/BlueprintCreateContext";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";

const BlueprintStepThree = () => {
  const { wizardData, updateWizardData } = useBlueprintCreate();
  const customToast = useCustomToast();

  const [selectedSubcategories, setSelectedSubcategories] = useState(wizardData.stepThree?.selectedSubcategories || {});
  const [subcategoryOptions, setSubcategoryOptions] = useState({});
  const [categoryOptions, setCategoryOptions] = useState({});
  const [selectedCategories, setSelectedCategories] = useState(wizardData.stepThree?.selectedCategories || {});
  const [loading, setLoading] = useState(false);
  const hasFetchedInitialData = useRef(false);

  const bg = useColorModeValue("gray.50", "gray.800");
  const textColor = useColorModeValue("black", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const tooltipBg = useColorModeValue("gray.100", "gray.700");
  const tagBg = useColorModeValue("gray.100", "primary.800");
  const tagHoverBg = useColorModeValue("red.500", "red.300");
  const tagHoverColor = useColorModeValue("white", "gray.800");
  const tagBorderColor = useColorModeValue("gray.400", "transparent");

  // Get the token from localStorage
  const token = localStorage.getItem("token");

  // Memoize selectedDishComponents to prevent unnecessary re-renders
  const selectedDishComponents = useMemo(() => wizardData.stepTwo?.dishComponents || [], [wizardData.stepTwo?.dishComponents]);

  useEffect(() => {
    const initializeData = async () => {
      if (hasFetchedInitialData.current || selectedDishComponents.length === 0) {
        return; // Prevent the function from running multiple times or if no components
      }
      hasFetchedInitialData.current = true; // Mark as fetched

      const categoryOpts = {};
      const categoryMap = { ...selectedCategories };

      try {
        // Fetch all categories
        const apiUrl = process.env.REACT_APP_API_ENDPOINT;
        const categoriesResponse = await fetch(`${apiUrl}/api/v1/blueprint/category`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!categoriesResponse.ok) {
          throw new Error(`Error fetching categories: ${categoriesResponse.statusText}`);
        }

        const categoriesData = await categoriesResponse.json();
        const allCategories = categoriesData.map((category) => ({
          value: category._id.toString(),
          label: category.name,
        }));

        // For each dish component, set category options
        selectedDishComponents.forEach((dishComponent) => {
          categoryOpts[dishComponent.id] = allCategories;
          if (!categoryMap[dishComponent.id]) {
            categoryMap[dishComponent.id] = [];
          }
        });

        setCategoryOptions(categoryOpts);
        setSelectedCategories(categoryMap);
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    };

    if (selectedDishComponents.length > 0) {
      initializeData();
    }
  }, [selectedDishComponents, token, selectedCategories]);

  // Update wizardData whenever selectedCategories or selectedSubcategories change
  useEffect(() => {
    updateWizardData((prevData) => ({
      ...prevData,
      stepThree: {
        selectedCategories,
        selectedSubcategories,
      },
    }));
  }, [selectedCategories, selectedSubcategories, updateWizardData]);

  const handleCategoryChange = async (dishComponentId, selectedOptions) => {
    const id = dishComponentId.toString(); // Ensure the ID is a string
    setSelectedCategories((prev) => ({
      ...prev,
      [id]: selectedOptions,
    }));

    // Fetch subcategories for selected categories
    if (selectedOptions && selectedOptions.length > 0) {
      try {
        setLoading(true);
        const categoryIds = selectedOptions.map((cat) => cat.value);
        const apiUrl = process.env.REACT_APP_API_ENDPOINT;
        const response = await fetch(`${apiUrl}/api/v1/blueprint/sub-category/by-categories`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ categoryIds }),
        });

        if (!response.ok) {
          throw new Error(`Error fetching subcategories: ${response.statusText}`);
        }

        const subcategoriesData = await response.json();
        const fetchedSubcategories = subcategoriesData.map((subcategory) => ({
          value: subcategory._id.toString(),
          label: subcategory.name,
        }));

        // Update subcategory options
        setSubcategoryOptions((prev) => ({
          ...prev,
          [id]: fetchedSubcategories,
        }));

        // Automatically select all fetched subcategories
        setSelectedSubcategories((prev) => ({
          ...prev,
          [id]: fetchedSubcategories,
        }));
      } catch (error) {
        console.error("Error fetching subcategories:", error);
        customToast({
          status: "error",
          title: "Erreur",
          description: "Erreur lors de la récupération des sous-catégories",
        });
      } finally {
        setLoading(false);
      }
    } else {
      // If no categories are selected, reset subcategories
      setSubcategoryOptions((prev) => ({
        ...prev,
        [id]: [],
      }));
      setSelectedSubcategories((prev) => ({
        ...prev,
        [id]: [],
      }));
    }
  };

  const handleSubcategoryChange = (dishComponentId, selectedOptions) => {
    const id = dishComponentId.toString(); // Ensure the ID is a string
    setSelectedSubcategories((prev) => ({
      ...prev,
      [id]: selectedOptions,
    }));
  };

  // Custom MultiValue component for the Select component
  const CustomMultiValue = (props) => {
    const { data, removeProps } = props;
    return (
      <Tooltip label="Désélectionner" placement="top" hasArrow>
        <Tag
          m="2px"
          px={3}
          py={1}
          borderRadius="full"
          variant="solid"
          bg={tagBg}
          border="1px solid"
          borderColor={tagBorderColor}
          color={textColor}
          transition="all 0.1s ease"
          cursor="pointer"
          _hover={{ borderColor: "red.500", color: { tagHoverColor }, bg: { tagHoverBg } }}
          onClick={removeProps.onClick}
        >
          <TagLabel>{data.label}</TagLabel>
        </Tag>
      </Tooltip>
    );
  };

  return (
    <Box p={4} maxW="900px" margin="auto" bg={bg} borderRadius="md" borderWidth="1px" borderColor={borderColor}>
      <Text fontSize="2xl" fontWeight="bold" mb={4}>
        Étape 3 : Sélectionnez les sous-catégories pour chaque composante
      </Text>

      {selectedDishComponents.map((dishComponent) => (
        <Box key={dishComponent.id} borderWidth="1px" borderRadius="md" p={4} mb={6} bg={bg} borderColor={borderColor}>
          <Text fontSize="xl" fontWeight="bold" mb={2}>
            {dishComponent.name}
          </Text>

          {/* Categories Multi-Select */}
          <FormControl mb={4}>
            <Tooltip maxW="220px" label="Sélectionnez une catégorie pour populer des sous-catégories d'aliments" placement="top-start" bg={tooltipBg}>
              <FormLabel w="100%">Catégories</FormLabel>
            </Tooltip>
            {loading ? (
              <Spinner />
            ) : (
              <Select
                name={`categories-${dishComponent.id}`}
                options={categoryOptions[dishComponent.id] || []}
                placeholder="Sélectionnez les catégories..."
                value={selectedCategories[dishComponent.id] || []}
                onChange={(selectedOptions) => handleCategoryChange(dishComponent.id, selectedOptions)}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions
                components={{
                  MultiValue: CustomMultiValue,
                  ClearIndicator: null,
                }}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            )}
          </FormControl>

          {/* Subcategories Multi-Select */}
          <FormControl mb={4}>
            <Tooltip maxW="220px" label="Sélectionnez des sous-catégories d'aliments" placement="top-start" bg={tooltipBg}>
              <FormLabel>Sous-catégories</FormLabel>
            </Tooltip>
            {loading ? (
              <Spinner />
            ) : (
              <Select
                name={`subcategories-${dishComponent.id}`}
                options={subcategoryOptions[dishComponent.id] || []}
                placeholder="Sélectionnez les sous-catégories..."
                value={selectedSubcategories[dishComponent.id] || []}
                onChange={(selectedOptions) => handleSubcategoryChange(dishComponent.id, selectedOptions)}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions
                components={{
                  MultiValue: CustomMultiValue,
                  ClearIndicator: null,
                }}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            )}
          </FormControl>
        </Box>
      ))}
    </Box>
  );
};

export default BlueprintStepThree;
