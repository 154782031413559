// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";

// Authentication Components
import LoginPage from "./components/1 - Authentification/loginPage";
import RegisterPage from "./components/1 - Authentification/RegisterPage";
import VerificationSuccessPage from "./components/1 - Authentification/VerificationSuccessPage";
import ForgotPasswordPage from "./components/1 - Authentification/ForgotPasswordPage";
import ResetPasswordPage from "./components/1 - Authentification/ResetPasswordPage";
import Logout from "./components/1 - Authentification/Logout";
import PasswordResetConfirmation from "./components/1 - Authentification/PasswordResetConfirmation";

// Layout Components
import Layout from "./components/5 - General/Layout/Layout";

//  Components
import Dashboard from "./components/2 - Components/Dashboard";
import Blueprints from "./components/2 - Components/Blueprints";
import ListeEpicerie from "./components/2 - Components/Liste";
import Recipe from "./components/2 - Components/Recipe";
import Gestion from "./components/2 - Components/Gestion";
import CommunityRecipe from "./components/2 - Components/CommunityRecipe";

//  Settings Components
import SettingsPage from "./components/2 - Components/SettingsPage";

// Protected Route Component
import ProtectedRoute from "./components/5 - General/Protected/ProtectedRoute";

function App() {
  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/verify-email-success" element={<VerificationSuccessPage />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/reset-confirmation" element={<PasswordResetConfirmation />} />

        {/* Protected Routes using the Layout */}
        <Route
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/blueprints" element={<Blueprints />} />
          <Route path="/liste-epicerie" element={<ListeEpicerie />} />
          <Route path="/community-recipes" element={<CommunityRecipe />} />
          <Route path="/gestion" element={<Gestion />} />
          <Route path="/recettes" element={<Recipe />} />
          <Route path="/dashboard/settings" element={<SettingsPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
