// pSBC function for color adjustment
export const pSBC = (p, c0, c1, l) => {
  // Helper function to parse color strings
  const parseColor = (color) => {
    let length = color.length;
    let result = {};
    let int = parseInt;
    let round = Math.round;

    if (length > 9) {
      // Parse rgba or rgb string
      color = color.split(",");
      length = color.length;
      if (length < 3 || length > 4) return null;
      result.r = int(color[0][3] === "a" ? color[0].slice(5) : color[0].slice(4));
      result.g = int(color[1]);
      result.b = int(color[2]);
      result.a = color[3] ? parseFloat(color[3]) : -1;
    } else {
      // Parse hex string
      if (length === 8 || length === 6 || length < 4) return null;
      if (length < 6) color = "#" + color[1] + color[1] + color[2] + color[2] + color[3] + color[3] + (length > 4 ? color[4] + color[4] : "");
      color = int(color.slice(1), 16);
      if (length === 9 || length === 5) {
        result.r = (color >> 24) & 255;
        result.g = (color >> 16) & 255;
        result.b = (color >> 8) & 255;
        result.a = round((color & 255) / 0.255) / 1000;
      } else {
        result.r = color >> 16;
        result.g = (color >> 8) & 255;
        result.b = color & 255;
        result.a = -1;
      }
    }
    return result;
  };

  // Validate input
  if (typeof p !== "number" || p < -1 || p > 1 || typeof c0 !== "string" || (c0[0] !== "r" && c0[0] !== "#") || (c1 && typeof c1 !== "string")) return null;

  // Parse colors
  let fromColor = parseColor(c0);
  let toColor = c1 ? parseColor(c1) : null;
  if (!fromColor || (c1 && !toColor)) return null;

  // Determine if blending towards black or white
  let blendToBlack = p < 0;
  let targetColor = toColor || (blendToBlack ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 });
  p = Math.abs(p);
  let inverseP = 1 - p;

  // Blend colors
  let r, g, b, a;
  if (l) {
    r = Math.round(inverseP * fromColor.r + p * targetColor.r);
    g = Math.round(inverseP * fromColor.g + p * targetColor.g);
    b = Math.round(inverseP * fromColor.b + p * targetColor.b);
  } else {
    r = Math.round(Math.sqrt(inverseP * fromColor.r ** 2 + p * targetColor.r ** 2));
    g = Math.round(Math.sqrt(inverseP * fromColor.g ** 2 + p * targetColor.g ** 2));
    b = Math.round(Math.sqrt(inverseP * fromColor.b ** 2 + p * targetColor.b ** 2));
  }
  a = fromColor.a >= 0 || targetColor.a >= 0 ? (fromColor.a < 0 ? targetColor.a : targetColor.a < 0 ? fromColor.a : fromColor.a * inverseP + targetColor.a * p) : 0;

  // Return blended color as rgba or hex string
  if (c0.length > 9 || (c1 && c1.length > 9)) {
    return `rgba(${r},${g},${b},${Math.round(a * 1000) / 1000})`;
  } else {
    return `#${(4294967296 + r * 16777216 + g * 65536 + b * 256 + (fromColor.a >= 0 || targetColor.a >= 0 ? Math.round(a * 255) : 0)).toString(16).slice(1, fromColor.a >= 0 || targetColor.a >= 0 ? undefined : -2)}`;
  }
};