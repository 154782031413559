import React, { useState } from 'react';
import {
    Button,
    useToast,
    Progress,
    HStack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';
import { FaChevronDown } from 'react-icons/fa';

const CsvDownloadModal = () => {
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const [csvLoading, setCsvLoading] = useState(false);
    const toast = useToast();

    // Categories from your updated model structure
    const categories = [
        { id: 'food', name: 'Aliments' },
        { id: 'sub-category', name: 'Sous-catégories' },
        { id: 'category', name: 'Catégories' },
        { id: 'department', name: 'Départements' },
        { id: 'food-group', name: 'Catégories' },
        { id: 'dish-type', name: 'Types de repas' },
    ];

    // Handle CSV download process based on the selected category
    const handleDownloadCsv = async (category) => {
        setCsvLoading(true);
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch(`${apiUrl}/api/v1/blueprint/download-csv`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Add token to headers
                },
                body: JSON.stringify({ categoryName: category }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${category}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            toast({
                title: 'Erreur',
                description: 'Impossible de télécharger les données en CSV.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setCsvLoading(false);
        }
    };

    return (
        <>
            <HStack spacing={4}>
                {/* CSV Download Menu */}
                <Menu>
                    <MenuButton as={Button} rightIcon={<FaChevronDown />} colorScheme="primary">
                        Télécharger CSV
                    </MenuButton>
                    <MenuList>
                        {categories.map((category) => (
                            <MenuItem
                                key={category.id}
                                onClick={() => handleDownloadCsv(category.id)}
                                fontWeight='400'
                                fontSize='md'
                            >
                                {category.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </HStack>

            {/* Progress bar when loading */}
            {csvLoading && <Progress size="xs" isIndeterminate colorScheme="primary" mt={4} />}
        </>
    );
};

export default CsvDownloadModal;
