import React, { useState, useEffect, useRef } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Input,
  Box,
  Text,
  VStack,
  useBreakpointValue,
  HStack,
  Image,
  Button,
} from "@chakra-ui/react";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import Placeholder from "../../../img/Placeholder.jpg";

const SearchDrawer = ({ isOpen, onClose, onGroceryListUpdated }) => {
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const inputRef = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const inputStyle = { fontSize: isMobile ? "16px" : "14px" };
  const customToast = useCustomToast();

  // Focus the search input when the drawer opens
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  // Fetch suggestions as the user types
  useEffect(() => {
    if (searchValue.trim() === "") {
      setSuggestions([]);
      return;
    }

    // Debounce the search input
    const timeoutId = setTimeout(() => {
      fetchSuggestions(searchValue);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  const fetchSuggestions = async (query) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/aliments/search?query=${encodeURIComponent(query)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch suggestions");
      }
      const data = await response.json();
      setSuggestions(data.aliments);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSearch = async () => {
    try {
      if (searchValue.trim() === "") {
        setSearchResults([]);
        return;
      }

      const token = localStorage.getItem("token");
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/aliments/search?query=${encodeURIComponent(searchValue)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch search results");
      }
      const data = await response.json();
      setSearchResults(data.aliments);
      setSuggestions([]);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      customToast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la récupération des suggestions.",
        status: "error",
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // Add this function to handle when the input loses focus
  const handleInputBlur = () => {
    setTimeout(() => {
      setSuggestions([]); // Hide suggestions after input loses focus
    }, 100); // Small delay to allow click on suggestion before closing
  };

  return (
    <>
      {isOpen && (
        <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} size="full">
          <DrawerOverlay />
          <DrawerContent maxH="90%">
            <DrawerHeader borderBottomWidth="1px">
              <DrawerCloseButton size="lg" />
              <Text fontSize="xl" fontWeight="bold">
                Rechercher un aliment
              </Text>
              <Input
                mt={4}
                ref={inputRef}
                placeholder="Rechercher..."
                style={inputStyle}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={handleKeyDown}
                onBlur={handleInputBlur} // Handle blur event
                bg="transparent"
                borderRadius="md"
                borderColor="gray.300"
                _hover={{ borderColor: "gray.400" }}
                _focus={{ borderColor: "primary.500" }}
              />
              {suggestions.length > 0 && (
                <Box mt={2} borderWidth="1px" borderRadius="md" maxHeight="200px" overflowY="auto">
                  {suggestions.map((suggestion) => (
                    <Box
                      key={suggestion._id}
                      p={2}
                      _hover={{ bg: "gray.100" }}
                      cursor="pointer"
                      onMouseDown={() => {
                        setSearchValue(suggestion.name);
                        setSuggestions([]); // Hide suggestions after selecting
                        handleSearch();
                      }} // Prevents blur event from firing
                    >
                      {suggestion.name}
                    </Box>
                  ))}
                </Box>
              )}
            </DrawerHeader>
            <DrawerBody>
              {searchResults.length > 0 ? (
                <VStack align="stretch" spacing={4} mt={4}>
                  {searchResults.map((aliment) => (
                    <SearchResultItem key={aliment._id} aliment={aliment} onGroceryListUpdated={onGroceryListUpdated} />
                  ))}
                </VStack>
              ) : (
                <Text textAlign="center" color="gray.500" mt={4}>
                  Aucun aliment trouvé.
                </Text>
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

const SearchResultItem = ({ aliment, onGroceryListUpdated }) => {
  const customToast = useCustomToast();

  const handleAddToGroceryList = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");
      const weight = 1; // Default weight'

      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/grocery-list/add-aliment/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ foodId: aliment._id, weight: weight ? parseFloat(weight) : undefined }),
      });

      if (!response.ok) {
        throw new Error("Failed to add aliment to grocery list");
      }

      // Optionally, refresh the grocery list
      if (onGroceryListUpdated) {
        await onGroceryListUpdated();
      }

      customToast({
        title: "Succès",
        description: `${aliment.name} a été ajouté à votre liste d'épicerie.`,
        status: "success",
      });
    } catch (error) {
      console.error("Error adding aliment to grocery list:", error);
      customToast({
        title: "Erreur",
        description: "Une erreur est survenue lors de l'ajout de l'aliment à la liste d'épicerie.",
        status: "error",
      });
    }
  };

  return (
    <Box w="100%" p={4} borderWidth="1px" borderRadius="md">
      <HStack spacing={4} alignItems="center">
        <Image src={Placeholder} alt={aliment.name} boxSize="80px" objectFit="cover" borderRadius="md" />
        <Box flex="1">
          <Text fontSize="xl" fontWeight="bold">
            {aliment.name}
          </Text>
          <Text fontSize="sm" noOfLines={3}>
            {aliment.foodGroup ? aliment.foodGroup.map((fg) => fg.name).join(", ") : "Aucun groupe alimentaire"}
          </Text>
        </Box>
        <Button colorScheme="primary" onClick={handleAddToGroceryList}>
          Ajouter
        </Button>
      </HStack>
    </Box>
  );
};

export default SearchDrawer;
