import React, { useState, useEffect, useMemo } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Checkbox,
  HStack,
  VStack,
  SimpleGrid,
  Textarea,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import useCustomToast from '../../5 - General/Utils/UtilsNotification';
import {
  seasonalityOptions,
  spicinessOptions,
  allergenOptions,
  vitaminOptions,
  dietaryPreferenceOptions,
  checkboxOptions,
  nutritionalFields
} from '../../5 - General/Utils/UtilsOptionsDefinitions';

const AlimentFormModal = ({
  isOpen,
  onClose,
  onSave,
  initialData,
  foodGroups = [],
  departments = [],
  subCategories = [],
}) => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const customToast = useCustomToast();
  const [isLoading, setIsLoading] = useState(false);

  // Options Memos
  const seasonOptions = useMemo(() => seasonalityOptions, []);

  // Default State
  const defaultState = {
    name: '',
    selectedFoodGroups: [],
    selectedDepartments: [],
    selectedSubCategories: [],
    seasonality: [],
    spiceLevel: [],
    allergens: [],
    origin: '',
    isOrganic: false,
    isProcessed: false,
    price: { currency: 'CAD', amount: '' },
    shelfLife: '',
    isPerishable: false,
    storageRecommendations: '',
    dietaryPreferences: {
      isVegan: false,
      isVegetarian: false,
      isGlutenFree: false,
      isLactoseFree: false,
    },
    nutritionalInfo: {
      calories: '',
      proteins: '',
      fats: '',
      carbohydrates: '',
      fibers: '',
      sodium: '',
    },
    vitamins: [],
    vitaminValues: {},
    imageUrl: '',
  };

  // State variables
  const [formState, setFormState] = useState(defaultState);

  // Helper Functions
  const initializeForm = (data) => {
    if (!data) {
      setFormState(defaultState);
      return;
    }

    const {
      name = '',
      foodGroup = [],
      department = [],
      subCategory = [],
      nutritionalInfo = {},
      preferredSeason = [],
      allergens = [],
      origin = '',
      isOrganic = false,
      isProcessed = false,
      price = { currency: 'CAD', amount: '' },
      shelfLife = '',
      isPerishable = false,
      storageRecommendations = '',
      dietaryPreferences = {},
      spiceLevel = [],
      imageUrl = '',
    } = data;

    const initialVitamins = nutritionalInfo.vitamins || [];
    const vitamins = initialVitamins.map((vitamin) => {
      const option = vitaminOptions.find((opt) => opt.value === vitamin.name) || {
        label: vitamin.name,
        value: vitamin.name,
        unit: vitamin.unit || '%',
      };
      return option;
    });
    const vitaminValues = {};
    initialVitamins.forEach((vitamin) => {
      vitaminValues[vitamin.name] = vitamin.quantity || '';
    });

    setFormState({
      name,
      selectedFoodGroups: foodGroup.map((group) => ({
        label: group.name,
        value: group._id,
      })),
      selectedDepartments: department.map((dept) => ({
        label: dept.name,
        value: dept._id,
      })),
      selectedSubCategories: subCategory.map((subCat) => ({
        label: subCat.name,
        value: subCat._id,
      })),
      nutritionalInfo: {
        calories: nutritionalInfo.calories || '',
        proteins: nutritionalInfo.proteins || '',
        fats: nutritionalInfo.fats || '',
        carbohydrates: nutritionalInfo.carbohydrates || '',
        fibers: nutritionalInfo.fibers || '',
        sodium: nutritionalInfo.sodium || '',
      },
      vitamins,
      vitaminValues,
      seasonality: preferredSeason.map((season) => ({
        label: seasonOptions.find((opt) => opt.value === season)?.label || season,
        value: season,
      })),
      allergens: allergens.map((allergen) => ({
        label: allergenOptions.find((opt) => opt.value === allergen)?.label || allergen,
        value: allergen,
      })),
      origin,
      isOrganic: !!isOrganic,
      isProcessed: !!isProcessed,
      price: {
        currency: price.currency || 'CAD',
        amount: price.amount || '',
      },
      shelfLife,
      isPerishable: !!isPerishable,
      storageRecommendations,
      dietaryPreferences: {
        isVegan: !!dietaryPreferences.isVegan,
        isVegetarian: !!dietaryPreferences.isVegetarian,
        isGlutenFree: !!dietaryPreferences.isGlutenFree,
        isLactoseFree: !!dietaryPreferences.isLactoseFree,
      },
      spiceLevel: spiceLevel.map((spice) => ({
        label: spicinessOptions.find((opt) => opt.value === spice)?.label || spice,
        value: spice,
      })),
      imageUrl,
    });
  };

  useEffect(() => {
    initializeForm(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  const handleVitaminValueChange = (vitaminName, value) => {
    setFormState((prev) => ({
      ...prev,
      vitaminValues: {
        ...prev.vitaminValues,
        [vitaminName]: value,
      },
    }));
  };

  const customMultiValueLabel = (props) => {
    const { data } = props;
    return (
      <HStack spacing={1} p="3px">
        <span>{data.label}:</span>
        <NumberInput
          size="xs"
          min={0}
          max={100}
          value={formState.vitaminValues[data.value] || ''}
          onChange={(valueString) => handleVitaminValueChange(data.value, valueString)}
          width="60px"
          p={0}
          bg="white"
          style={{ textAlign: 'right' }}
        >
          <NumberInputField />
        </NumberInput>
        <span>{data.unit}</span>
      </HStack>
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const vitaminData = formState.vitamins.map((vitamin) => ({
        name: vitamin.value,
        quantity: parseFloat(formState.vitaminValues[vitamin.value]) || 0,
        unit: vitamin.unit,
      }));

      const alimentData = {
        name: formState.name,
        foodGroup: formState.selectedFoodGroups.map((cat) => cat.value),
        department: formState.selectedDepartments.map((dept) => dept.value),
        subCategory: formState.selectedSubCategories.map((sc) => sc.value),
        nutritionalInfo: {
          calories: formState.nutritionalInfo.calories || undefined,
          proteins: formState.nutritionalInfo.proteins || undefined,
          fats: formState.nutritionalInfo.fats || undefined,
          carbohydrates: formState.nutritionalInfo.carbohydrates || undefined,
          fibers: formState.nutritionalInfo.fibers || undefined,
          sodium: formState.nutritionalInfo.sodium || undefined,
          vitamins: vitaminData.length > 0 ? vitaminData : undefined,
        },
        preferredSeason: formState.seasonality.map((season) => season.value),
        allergens: formState.allergens.map((allergen) => allergen.value),
        origin: formState.origin || undefined,
        isOrganic: formState.isOrganic,
        isProcessed: formState.isProcessed,
        price: {
          currency: formState.price.currency,
          amount: formState.price.amount || undefined,
        },
        shelfLife: formState.shelfLife || undefined,
        isPerishable: formState.isPerishable,
        storageRecommendations: formState.storageRecommendations || undefined,
        dietaryPreferences: {
          isVegan: formState.dietaryPreferences.isVegan,
          isVegetarian: formState.dietaryPreferences.isVegetarian,
          isGlutenFree: formState.dietaryPreferences.isGlutenFree,
          isLactoseFree: formState.dietaryPreferences.isLactoseFree,
        },
        spiceLevel: formState.spiceLevel.map((spice) => spice.value),
        imageUrl: formState.imageUrl || undefined,
      };

      // Remove undefined properties
      const cleanAlimentData = JSON.parse(JSON.stringify(alimentData));

      const token = localStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      let response;
      if (initialData) {
        response = await fetch(
          `${apiUrl}/api/v1/blueprint/food/${initialData._id}`,
          {
            method: 'PUT',
            headers,
            body: JSON.stringify(cleanAlimentData),
          }
        );
      } else {
        response = await fetch(`${apiUrl}/api/v1/blueprint/food`, {
          method: 'POST',
          headers,
          body: JSON.stringify(cleanAlimentData),
        });
      }

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to save aliment');
      }

      customToast({
        status: 'success',
        title: 'Succès',
        description: initialData
          ? 'Aliment mis à jour avec succès.'
          : 'Aliment créé avec succès.',
      });

      onSave();
      onClose();
    } catch (error) {
      console.error('Error saving aliment:', error);
      customToast({
        status: 'error',
        title: 'Erreur',
        description: 'Une erreur est survenue. Veuillez réessayer plus tard.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>
          {initialData ? "Modifier l'aliment" : 'Ajouter un nouvel aliment'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {/* Basic Information */}
            <FormControl id="aliment-name" isRequired>
              <FormLabel>Nom</FormLabel>
              <Input
                placeholder="Exemple : Pomme"
                value={formState.name}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </FormControl>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <FormControl id="aliment-categories" isRequired>
                <FormLabel>Groupe Alimentaire</FormLabel>
                <Select
                  isMulti
                  name="categories"
                  options={(foodGroups || []).map((category) => ({
                    label: category.name,
                    value: category._id,
                  }))}
                  value={formState.selectedFoodGroups}
                  onChange={(selected) =>
                    setFormState((prev) => ({
                      ...prev,
                      selectedFoodGroups: selected,
                    }))
                  }
                  placeholder="Sélectionnez les catégories"
                  closeMenuOnSelect={false}
                />
              </FormControl>

              <FormControl id="aliment-isles" isRequired>
                <FormLabel>Rayon d'épicerie</FormLabel>
                <Select
                  isMulti
                  name="isles"
                  options={(departments || []).map((isle) => ({
                    label: isle.name,
                    value: isle._id,
                  }))}
                  value={formState.selectedDepartments}
                  onChange={(selected) =>
                    setFormState((prev) => ({
                      ...prev,
                      selectedDepartments: selected,
                    }))
                  }
                  placeholder="Sélectionnez les rayons"
                  closeMenuOnSelect={false}
                />
              </FormControl>

              <FormControl id="aliment-sous-categories" isRequired>
                <FormLabel>Sous-catégorie d'aliment</FormLabel>
                <Select
                  isMulti
                  name="sousCategories"
                  options={(subCategories || []).map((sc) => ({
                    label: sc.name,
                    value: sc._id,
                  }))}
                  value={formState.selectedSubCategories}
                  onChange={(selected) =>
                    setFormState((prev) => ({
                      ...prev,
                      selectedSubCategories: selected,
                    }))
                  }
                  placeholder="Sélectionnez les sous-catégories"
                  closeMenuOnSelect={false}
                />
              </FormControl>

              <FormControl id="aliment-origin">
                <FormLabel>Pays ou région de provenance</FormLabel>
                <Input
                  placeholder="Exemple : France"
                  value={formState.origin}
                  onChange={(e) =>
                    setFormState((prev) => ({ ...prev, origin: e.target.value }))
                  }
                />
              </FormControl>
            </SimpleGrid>

            {/* Checkboxes */}
            <FormControl id="aliment-checkboxes">
              <HStack spacing={4}>
                {checkboxOptions.map((option) => (
                  <Checkbox
                    key={option.id}
                    isChecked={formState[option.id]}
                    onChange={(e) =>
                      setFormState((prev) => ({
                        ...prev,
                        [option.id]: e.target.checked,
                      }))
                    }
                  >
                    {option.label}
                  </Checkbox>
                ))}
              </HStack>
            </FormControl>

            {/* Dietary Preferences */}
            <FormLabel fontWeight="bold" mt={4}>
              Préférences alimentaires
            </FormLabel>
            <FormControl id="aliment-dietary-preferences">
              <FormLabel>Préférences de diètes</FormLabel>
              <HStack spacing={4}>
                {dietaryPreferenceOptions.map((pref) => (
                  <Checkbox
                    key={pref.id}
                    isChecked={formState.dietaryPreferences[pref.id]}
                    onChange={(e) =>
                      setFormState((prev) => ({
                        ...prev,
                        dietaryPreferences: {
                          ...prev.dietaryPreferences,
                          [pref.id]: e.target.checked,
                        },
                      }))
                    }
                  >
                    {pref.label}
                  </Checkbox>
                ))}
              </HStack>
            </FormControl>
            <FormControl id="aliment-allergens" isRequired>
              <FormLabel>Allergènes</FormLabel>
              <Select
                isMulti
                name="allergens"
                options={allergenOptions}
                value={formState.allergens}
                onChange={(selected) =>
                  setFormState((prev) => ({ ...prev, allergens: selected }))
                }
                placeholder="Sélectionnez les allergènes"
                closeMenuOnSelect={true}
              />
            </FormControl>

            <FormControl id="aliment-seasonality" isRequired>
              <FormLabel>Saison Préférée</FormLabel>
              <Select
                isMulti
                name="seasonality"
                options={seasonOptions}
                value={formState.seasonality}
                onChange={(selected) =>
                  setFormState((prev) => ({ ...prev, seasonality: selected }))
                }
                placeholder="Sélectionnez la saison"
              />
            </FormControl>

            <FormControl id="aliment-spiciness" isRequired>
              <FormLabel>Échelle d'aliments épicés</FormLabel>
              <Select
                isMulti
                name="spiciness"
                options={spicinessOptions}
                value={formState.spiceLevel}
                onChange={(selected) =>
                  setFormState((prev) => ({ ...prev, spiceLevel: selected }))
                }
                placeholder="Sélectionnez le niveau d'épice"
              />
            </FormControl>

            {/* Nutritional Information */}
            <FormLabel fontWeight="bold" mt={4}>
              Informations nutritionnelles moyennes (par 100g)
            </FormLabel>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              {nutritionalFields.map((field) => (
                <FormControl key={field.id} id={`aliment-${field.id}`}>
                  <FormLabel>{field.label}</FormLabel>
                  <NumberInput min={0} value={formState.nutritionalInfo[field.id]}>
                    <NumberInputField
                      placeholder={field.placeholder}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          nutritionalInfo: {
                            ...prev.nutritionalInfo,
                            [field.id]: e.target.value,
                          },
                        }))
                      }
                    />
                  </NumberInput>
                </FormControl>
              ))}
              <FormControl id="aliment-vitamins">
                <FormLabel>Vitamines ou minéraux principaux</FormLabel>
                <Select
                  isMulti
                  name="vitamins"
                  options={vitaminOptions}
                  value={formState.vitamins}
                  onChange={(selected) => {
                    setFormState((prev) => ({ ...prev, vitamins: selected }));
                    // Initialize vitamin values if not set
                    selected.forEach((vitamin) => {
                      if (!formState.vitaminValues[vitamin.value]) {
                        handleVitaminValueChange(vitamin.value, '');
                      }
                    });
                  }}
                  placeholder="Sélectionnez les vitamines/minéraux"
                  closeMenuOnSelect={false}
                  components={{ MultiValueLabel: customMultiValueLabel }}
                />
              </FormControl>
            </SimpleGrid>

            {/* Additional Information */}
            <FormLabel fontWeight="bold" mt={4}>
              Informations supplémentaires
            </FormLabel>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <FormControl id="aliment-price">
                <FormLabel>Prix moyen par 100g ($ CAD)</FormLabel>
                <NumberInput min={0} value={formState.price.amount}>
                  <NumberInputField
                    placeholder="Montant (Exemple : 5.99)"
                    onChange={(e) =>
                      setFormState((prev) => ({
                        ...prev,
                        price: { ...prev.price, amount: e.target.value },
                      }))
                    }
                  />
                </NumberInput>
              </FormControl>

              <FormControl id="aliment-shelf-life">
                <FormLabel>Durée de conservation (jours)</FormLabel>
                <NumberInput min={0} value={formState.shelfLife}>
                  <NumberInputField
                    placeholder="Exemple : 7"
                    onChange={(e) =>
                      setFormState((prev) => ({ ...prev, shelfLife: e.target.value }))
                    }
                  />
                </NumberInput>
              </FormControl>

              <FormControl id="aliment-storage-recommendations">
                <FormLabel>Recommandations de stockage</FormLabel>
                <Textarea
                  placeholder="Exemple : À conserver au réfrigérateur"
                  value={formState.storageRecommendations}
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      storageRecommendations: e.target.value,
                    }))
                  }
                />
              </FormControl>

              <FormControl id="aliment-image-url">
                <FormLabel>URL de l'image</FormLabel>
                <Input
                  placeholder="Exemple : http://exemple.com/image.jpg"
                  value={formState.imageUrl}
                  onChange={(e) =>
                    setFormState((prev) => ({ ...prev, imageUrl: e.target.value }))
                  }
                />
              </FormControl>
            </SimpleGrid>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Annuler
          </Button>
          <Button colorScheme="primary" onClick={handleSubmit} isLoading={isLoading}>
            {initialData ? 'Mettre à jour' : 'Créer'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AlimentFormModal;
